import React,{ useState, useEffect } from 'react'

import './fronthand_style.css';
import './fronthand_media-queries.css';

import { Copy_Add } from './Data_Structure';
import { FixPool_Con_Detail } from './Data_Structure';

import m_1 from './front_assets/m_1.png';

import h1 from './front_assets/h1.png';
import h11 from './front_assets/h11.png';
import h12 from './front_assets/h12.png';
import h10 from './front_assets/h10.png';
import h4 from './front_assets/h4.png';
import h9 from './front_assets/h9.png';
import Lock2 from './front_assets/Lock 2.png';

import l1 from './front_assets/l1.png';

import freedom from './front_assets/freedom.png';

import core_1 from './front_assets/core_1.png';

import faq_1 from './front_assets/faq_1.png';

import s_m_1 from './front_assets/s_m_1.png';

function Btn_Click(btn_id) {
    
    const btn = document.getElementById(btn_id);

    const ans = btn.nextElementSibling;
    const span2 = btn.children[1];
    const item1 = span2.children[0];
    
    //console.log(" ");
    //console.log(ans);
    //console.log(item1);
    //console.log(span2);
    //console.log(" ");

    ans.classList.toggle('show');
    item1.classList.toggle('rotate');
    //span2.classList.toggle("");
}

export default function CoreVenture_Before_Login(props) {

    const [SM_Add, SetSM_Add] = useState([]);
    const [SM_Add_Loading, SetSM_Add_Loading] = useState([]);

    useEffect(() => {
        FixPool_Con_Detail(SetSM_Add, SetSM_Add_Loading);
    }, []);

  return (
    <>
        <input type="checkbox" id="nav-toggle"/>
        <div className="sidebar">
            <div className="sidebar-brand">
                <h2>
                    <span><img className="hide-img" src={m_1} alt=""/></span>
                    <span></span>
                </h2>
            </div>
            <div className="sidebar-menu">
                <ul>
                    <li>
                        <a href="#Home" className="active">
                            <span><img className="menu-img" src={h1} alt=""/></span>                      
                            <span>Home</span>      
                        </a>
                    </li>
                    <li>
                        <a href="#Feature" className="active">
                            <span><img className="menu-img" src={h11} alt=""/></span>
                            <span>Feature</span>
                        </a>
                    </li>
                    <li>
                        <a href="#About_Us" className="active">
                            <span><img className="menu-img" src={h12} alt=""/></span>
                            <span className="menu-tags">About us</span>
                        </a>
                    </li>
                    <li>
                        <a href="#Advantages_Of_Core"className="active">
                            <span><img className="menu-img" src={h10} alt=""/></span>
                            <span>Advantages Of Core</span>
                        </a>
                    </li>
                    <li>
                        <a href="#Financial_Freedom" className="active">
                            <span><img className="menu-img" src={h4} alt=""/></span>
                            <span>Financial Freedom</span>
                        </a>
                    </li>
                    <li>
                        <a href="#Faq" className="active">
                            <span><img className="menu-img" src={h9} alt=""/></span>
                            <span>Faq</span>
                        </a>
                    </li>
                    <li>
                        <a className="active" onClick={() => {props.SignIn_Click();}}>
                            <span><img className="menu-img" src={Lock2} alt=""/></span>
                            <span>Log In</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        {/*<!-----------------Nav----------------------------->*/}

        {/*<!--------Main--Content-------------------------->*/}

        <div className="main-content">
            <header>
                <h3>
                    <label for="nav-toggle">
                        <span className="menu-toggle"><img src={m_1} alt=""/></span>
                    </label>
                </h3>
                <div className="user-wrapper">
                    <span className="logo"> <img src={l1} alt=""/></span>
                </div>
            </header>
            {/*<!--------Main----Cards----------------------->*/}
            <main>
                <div id='Home' className="back-grid">
                    <div className="dash-main">
                        <div className="dash-card-main">           
                            <div className="dash-main-left">
                                <span>WELCOME YOU TO</span>
                                <div>CORE VENTURE</div>
                                <p>A FUTURE REVOLUTION CONNECTING ALL DIGITAL PLATFORMS WORLDWIDE.</p>
                                <span></span>
                                <div className="btn-action">
                                    <span><a className="btn-link" onClick={() => {props.SignIn_Click();}}>Log In</a></span>
                                    <span><a className="btn-link" onClick={() => {props.SignUp_Click();}}>Sign Up</a></span>
                                </div>
                            </div>
                            
                            <div className="dash-main-right">
                                <div className="dash-img">
                                    <span><img src={freedom} alt=""/></span>
                                </div>
                            </div>            
                        </div>
                    </div>
                </div>

                {/*<!------Smart---Contract--------------------------------->*/}

                <div id='Feature' className="back-grid-short">                                 
                    <div className="heading">
                        <span>Smart Contract</span>
                        <div className="sub-heading">
                            <span>Features Of</span>
                        </div>
                    </div>
                </div>
                <div className="back-grid">
                    <div className="dash-triple">
                        <div className="card-triple">
                            <div className="card-logo">            
                                <span><img src={core_1} alt=""/></span>
                            </div>
                            <div className="sub-heading">
                                <span>Scalability</span>
                            </div>
                            <p>
                                Applications are given a wider variety of ways to be 
                                deployed in CORE because of its scalability and highly 
                                effective smart contract.
                            </p>
                        </div>
                        <div className="card-triple">
                            <div className="card-logo">            
                                <span><img src={core_1} alt=""/></span>
                            </div>
                            <div className="sub-heading">
                                <span>Availability</span>
                            </div>
                            <p>
                                More reliable network structure, user asset, intrinsic 
                                value, and a higher degree of decentralization consensus come...
                            </p>
                        </div>
                        <div className="card-triple">
                            <div className="card-logo">            
                                <span><img src={core_1} alt=""/></span>
                            </div>
                            <div className="sub-heading">
                                <span>Profit Generated</span>
                            </div>
                            <p>
                                Referral bonus that pays up to 10 levels.
                                Transparently programmed using a smart contract created 
                                on the CORE Blockchain.
                            </p>
                        </div>
                    </div>
                </div>

                {/*<!-----About----Section------------------------------->*/}
                <div id='About_Us' className="back-grid-short">                                 
                    <div className="heading">
                        <span>About</span>
                        <div className="sub-heading">
                            <span>Features Of</span>
                        </div>
                    </div>
                </div>

                <div className="back-grid">
                    <div className="dash-double">
                        <div className="card-double-blank">
                            <div className="card-double">
                                <div>
                                    <span><img src={freedom} alt=""/></span>
                                </div>
                            </div>
                            <div className="card-double">    
                                <div className="sub-heading">
                                    <span>About</span>
                                </div>
                                <p>
                                    Core Venture is the international community of the global decentralized ecosystem and the first ever 
                                    smart contract marketing matrix plan of the CORE Blockchain. This is a self-executing software 
                                    algorithm that performs the function of distributing partner rewards between community 
                                    members, subject to certain conditions (Level and matrix marketing plan). 
                                    Information about transactions in the 
                                    CORE Blockchain can always be viewed at the link https://scan.coredao.org/
                                </p>
                                <div className="sub-heading">
                                    <span>The Most Powerful CORE</span>
                                </div>
                                <p>
                                    It’s been more than 2 years since the most well-known cryptocurrency CORE was launched. 
                                    Now there is a multitude of digital tokens available for traders like you and me to choose from.
                                </p>
                            </div>
                        </div> 
                    </div>
                </div>

                {/*<!-----Core---Blockchain--------------------------------->*/}
                <div id='Advantages_Of_Core' className="back-grid-short">                                 
                    <div className="heading">
                        <span>CORE Blockchain</span>
                        <div className="sub-heading">
                            <span>Advantages Of</span>
                        </div>
                    </div>
                </div>
                <div className="back-grid">
                    <div className="dash-triple">
                        <div className="card-triple">
                            <div className="card-logo">            
                                <span><img src={core_1} alt=""/></span>
                        </div>    
                            <div className="sub-heading">
                                <span>CORE is Completely</span>
                            </div>
                            <p>
                                Core Venture is completely decentralized smart contract 
                                works on self-execution of technical 
                                functionalities with no admin interference.
                            </p>    
                        </div>
                        <div className="card-triple">
                            <div className="card-logo">            
                                <span><img src={core_1} alt=""/></span>
                        </div>
                            <div className="sub-heading">
                                <span>The Complete</span>
                            </div>
                            <p>
                                The complete marketing strategy is represented by a 
                                verified smart contract enlisted on the CORE Blockchain, 
                                which is immutable and can't be changed or altered
                            </p>
                        </div>
                        <div className="card-triple">
                            <div className="card-logo">            
                                <span><img src={core_1} alt=""/></span>
                        </div>
                            <div className="sub-heading">
                                <span>CORE has very low</span>
                            </div>
                            <p>
                                CORE has very low transactions. In fact, 
                                they are almost non-existent whenever you 
                                make a transaction, you have to 
                                pay minimum 0.00063 CORE and that’s basically it.
                            </p>
                        </div>
                    </div>
                </div>

                {/*<!-----Financial---Freedom-------------------------------->*/}
                <div id='Financial_Freedom' className="back-grid-short">                                 
                    <div className="heading">
                        <span>Financial Freedom</span>
                        <div className="sub-heading">
                            <span>Features Of</span>
                        </div>
                    </div>
                </div>
                <div className="back-grid">
                    <div className="dash-double">
                        <div className="card-double-blank">
                            <div className="card-double">
                                <div>
                                    <span>
                                        <img src={freedom} alt=""/>
                                    </span>
                                </div>
                            </div>
                            <div className="card-double">    
                                <div className="sub-heading">
                                    <span>Financial Freedom</span>
                                </div>
                                <p>
                                    An easy and fastest earning opportunity with the world’s first unique designed 
                                    Global, 10 Level & Pool income plan in a decentralized smart contract on the blockchain. 
                                </p>
                                <p>
                                    Core Venture is a self-executing smart contract on the blockchain, 
                                    it's a crowdfunding and network marketing system with a unique strategy. 
                                    Core Venture distributes 100% fund with an exciting affiliate programe, 
                                    Global, 10 Level & 6x5 Matrix Pool Plan.
                                </p>
                                <p>
                                    Sign Up and Become a part of Smart Contract. 
                                </p>
                            </div>
                        </div>    
                    </div>
                </div>

                {/*<!-----Faq---Section---------------------------------------------->*/}
                <div id='Faq' className="back-grid">
                    <section>
                        <h1 className="faq-title">Frequently Asked Questions</h1>
                        <div className="faq-heading">
                            <span>General</span>
                        </div>
                        <div className="quetions-box">
                            <div className="hide-space"></div>
                            <div className="question">
                                <button id='faq_cl_1' onClick={() => {Btn_Click("faq_cl_1");}}>
                                    <span>Who Manage The Platform ?</span>
                                    <span>
                                        <img className="click-img" src={faq_1} alt=""/>
                                    </span>
                                </button>
                                <p>Core Venture does not have a Manager. This means that the platform is fully decentralized (i.e. it has no leaders or admins).</p>
                            </div>
                            <div className="question">
                                <button id='faq_cl_2' onClick={() => {Btn_Click("faq_cl_2");}}>
                                    <span>What is Decentralization ?</span>
                                    <span>
                                        <img className="click-img" src={faq_1} alt=""/>
                                    </span>
                                </button>
                                <p>
                                    It is a system in which there are no admins, there is no single server or system monitoring, project management. The creators of the platform are the same project participants like you.
                                </p>
                            </div>
                            <div className="question">
                                <button id='faq_cl_3' onClick={() => {Btn_Click("faq_cl_3");}}>
                                    <span>What is Core ?</span>
                                    <span>
                                        <img className="click-img" src={faq_1} alt=""/>
                                    </span>
                                </button>
                                <p>Core is one of the leading cryptocurrency, which has existed since 2022 At the same time, it is a software framework for L1 (Fully Decetralized Blockchain). Since the blockchain of this crypto currency allows you not only to follow the history of transactions, but also to save any executable software products (smart contracts). A huge number of major crypto companies use this platform.</p>
                            </div>
                            <div className="question">
                                <button id='faq_cl_4' onClick={() => {Btn_Click("faq_cl_4");}}>
                                    <span>What is Smart Contract ?</span>
                                    <span>
                                        <img className="click-img" src={faq_1} alt=""/>
                                    </span>
                                </button>
                                <p>A Smart Contract is a computer software that manages the transfer of digital assets between a peer-to-peer network of involved parties directly and automatically under specific conditions.</p>                    </div>
                            <div className="question">
                                <button id='faq_cl_5' onClick={() => {Btn_Click("faq_cl_5");}}>
                                    <span>How Much Can You Earn ?</span>
                                    <span>
                                        <img className="click-img" src={faq_1} alt=""/>
                                    </span>
                                </button>
                                <p>Core Venture does not have a Manager. This means that the platform is fully decentralized. Which mean you can earn unlimited income under specific conditions.</p>
                            </div>
                        </div>
                    </section> 
                </div>

                {/*<!-----Smart----Contracts------------------------------>*/}
                <div className="back-grid-short">                                 
                    <div className="heading">
                        <span>Smart Contract Addresses</span>
                        <div className="sub-heading">
                            <span>Features Of</span>
                        </div>
                    </div>
                </div>
                <div className="back-grid-short">
                    <div className="smart-grid">
                        <div className="table scroll-bar">
                            <table>
                                <thead>
                                    <tr>
                                        <td>Sr</td>
                                        <td>Pool Name</td>
                                        <td>Contract Address</td>
                                        <td>Link</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        SM_Add_Loading === "" ? (
                                            SM_Add.map((data) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{data.sr}</td>
                                                            <td>{data.type}</td>
                                                            <td>{data.add}</td>
                                                            <td>
                                                                <div>
                                                                    <a className="check" onClick={(() => {Copy_Add(data.url, props.showAlert);})}>Check</a>
                                                                </div>  
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        ) : (
                                            <>{SM_Add_Loading}</>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/*<!-----Footer----Section-------------------------------->*/}
                <div className="back-grid-short">
                    <div className="dash-card-footer-main"> 
                        <div className="card-logo-footer">
                            <span><img src={core_1} alt=""/></span>
                        </div> 
                        <div className="link-column flex">
                            <span>Our Links</span>
                            <a href="#" className="hover-link">Home</a>
                            <a href="#" className="hover-link">Features</a>
                            <a href="#" className="hover-link">About Us</a>
                            <a href="#" className="hover-link">Advantage Of Core</a>
                            <a href="#" className="hover-link">Financial freedom</a>
                        </div>
                        <div className="link-column flex">
                            <span>Other Links</span>
                            <a href="#" className="hover-link">Overview</a>
                            <a href="#" className="hover-link">Prising</a>
                            <a href="#" className="hover-link">Usibility</a>
                            <a href="#" className="hover-link">Custmers</a>
                            <a href="#" className="hover-link">Status Page</a>
                        </div>  
                    </div>
                </div>

                {/*<!-----Sub-Footer----Section-------------------------------->*/}
                <div className="back-grid-short">
                    <div className="dash-sub-footer">
                        <div className="card-sub-footer">
                            <div className="copy-right">
                                <span>© 2023 Core Venture.</span>
                            </div>
                        </div>
                        <div className="card-sub-footer">
                            <div className="social-link">
                                <span><img src={s_m_1} alt=""/></span>
                                <span><img src={s_m_1} alt=""/></span>
                                <span><img src={s_m_1} alt=""/></span>
                                <span><img src={s_m_1} alt=""/></span>
                            </div>                         
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </>
  )
}
