import React, { useEffect, useState } from 'react'

import './backhand_style.css';
import './backhand_media-queries.css';

import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react';

import { Copy_Add } from './Data_Structure';

import { Client_Personal_Data } from './Data_Structure';

import { Search_Data, Address_Exist } from './Data_Structure';
import { Pool_Status_Card_Check, Click_FixPool_Current_Grid_Detail } from './Data_Structure';

import { Dashboard_Detail } from './Data_Structure';
import { Slots_Detail } from './Data_Structure';
import { Global_Detail } from './Data_Structure';
import { Level_Reward_Detail } from './Data_Structure';
import { Pool_Reward_Detail } from './Data_Structure';

import m_1 from './assets/m_1.png';

import h2 from './assets/h2.png';
import h3 from './assets/h3.png';
import h4 from './assets/h4.png';
import h5 from './assets/h5.png';
import h6 from './assets/h6.png';
import h7 from './assets/h7.png';

import u_1 from './assets/u_1.png';

import l1 from './assets/l1.png';

import core_1 from './assets/core_1.png';

import Tick from './assets/Tick.png';
import Tick_Blank from './assets/Tick_Blank.png';

import left from './assets/left.png';
import right from './assets/right.png';



export default function CoreVenture_After_Login(props) {
    //const address = "0xdce526ad71d4d2101caf7063f69ba2024b3643a4";

    const { address } = useAccount();
    const { open } = useWeb3Modal();

    let activate_now = "ACTIVATE NOW";

    const [Current_Address, SetCurrent_Address] = useState(address);

    const [Refferal_Link, SetRefferal_Link] = useState("");

    const [Upline_ID, SetUpline_ID] = useState("");
    const [Upline_Add, SetUpline_Add] = useState("");
    const [Upline_Add_Sub, SetUpline_Add_Sub] = useState("");

    const [ID, SetID] = useState("");
    const [Wallet_Add, SetWallet_Add] = useState("");
    const [Wallet_Add_Sub, SetWallet_Add_Sub] = useState("");
    
    const[Core, SetCore] = useState("");

    const[CurrentForm, SetCurrentForm] = useState('DASHBOARD');
    const[FontSize, SetFontSize] = useState('70%');

    const [FixPool_PackNo, SetFixPool_PackNo] = useState(Number(1));
    const [FixPool_Detail, SetFixPool_Detail] = useState([]);
    const [FixPool_Detail_Loading , SetFixPool_Detail_Loading] = useState("");

    const [Search_Should, SetSearch_Should] = useState(true);


    const Search_Address_Click = async() => {
        let search_input = document.getElementById('search_input').value;
        Search_Data(search_input, Search_Should, SetCurrent_Address, SetSearch_Should, props.showAlert);
    }

    const My_Address_Click = async() => {
        SetCurrent_Address(address);
        Address_Exist(address, "InValid Address", Search_Should, SetCurrent_Address, SetSearch_Should);
    }

    const Click_FixPool_Current_Detail = async(PackNo) => {
        SetFixPool_PackNo(Number(PackNo));
        Click_FixPool_Current_Grid_Detail(Current_Address, Number(PackNo), SetFixPool_Detail, SetFixPool_Detail_Loading);
    }

    useEffect(() => {
        Client_Personal_Data(Current_Address, SetUpline_ID, SetUpline_Add, SetUpline_Add_Sub, SetID, SetWallet_Add, SetWallet_Add_Sub, SetRefferal_Link, SetCore);
    }, [Search_Should]);


    /*return (
        <>
            <div>
                <header className='fixed-top bg-dark' style={{zIndex: '15'}}>
                    <nav className="navbar navbar-expand-lg text-white">
                        <div className="container-fluid">
                            <a className="navbar-brand fw-bold text-white" href="#">MatrixWorld</a>
                            <button style={{backgroundColor: 'white'}} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a className="nav-link active fw-bold text-white" aria-current="page" style={{cursor: 'pointer', textAlign: 'left'}} onClick={() => {SetCurrentForm("DASHBOARD")}}>DASHBOARD</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active fw-bold text-white" aria-current="page" style={{cursor: 'pointer', textAlign: 'left'}} onClick={() => {SetCurrentForm("MATRIX WORLD")}}>MATRIX WORLD</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active fw-bold text-white" aria-current="page" style={{cursor: 'pointer', textAlign: 'left'}} onClick={() => {SetCurrentForm("SLOTS")}}>SLOTS</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active fw-bold text-white" aria-current="page" style={{cursor: 'pointer', textAlign: 'left'}} onClick={() => {SetCurrentForm("GEN")}}>GEN</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active fw-bold text-white" aria-current="page" style={{cursor: 'pointer', textAlign: 'left'}} onClick={() => {SetCurrentForm("FIXPOOL")}}>FIXPOOL</a>
                                    </li>                             
                                </ul>
                                <div className='d-flex' style={{textAlign: 'left'}}>    
                                    <button className='btn btn-primary' style={{}} onClick={() => {open()}}>LogOut</button>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
                
                <div style={{backgroundColor: '#6610f2', zIndex: 10}}>

                    <br />

                    <div className="container">

                        <div className="card" style={{backgroundColor: '#4504af'}}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xs-4 col-md-3 col-sm-3 col-lg-4"></div>
                                    <div className="col-xs-4 col-md-6 col-sm-6 col-lg-4">
                                        <div className="card text-white" style={{backgroundColor: 'green'}}>
                                            <div className='card-wrapper'>
                                                <div className="row">
                                                    <div className="col my-2 mx-1 fw-bold" style={{textAlign: 'center', fontSize: '120%'}}>{CurrentForm}</div>                                                                           
                                                </div>              
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-4 col-md-3 col-sm-3 col-lg-4"></div>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div className="card" style={{backgroundColor: '#4504af'}}>
                            <div className="card-body">
                                <div className="row my-2">
                                    <div className="col-xs-4 col-md-4 col-sm-4">
                                        <div className="card mb-1 text-white" style={{backgroundColor: 'green'}}>
                                            <div className="card-wrapper my-1">
                                                <table style={{width: '100%'}}>
                                                    <tbody>
                                                        <tr>
                                                            <td className='fw-bold' style={{width: '47%', textAlign: 'right'}}>Upline_ID</td>
                                                            <td className='fw-bold' style={{width: '6%', textAlign: 'center'}}> : </td>
                                                            <td className='fw-bold' style={{width: '47%', textAlign: 'left'}}>
                                                                {Upline_ID}
                                                            </td>
                                                            
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        
                                        <div className="card mb-1 text-white" style={{backgroundColor: 'green'}}>
                                            <div className="card-wrapper my-1">
                                                <table style={{width: '100%'}}>
                                                    <tbody>
                                                        <tr>
                                                            <td className='fw-bold' style={{width: '47%', textAlign: 'right'}}>Upline Address</td>
                                                            <td className='fw-bold' style={{width: '6%', textAlign: 'center'}}> : </td>
                                                            <td className='fw-bold' style={{width: '47%', textAlign: 'left'}}></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card mb-1" style={{backgroundColor: 'white'}}>
                                            <div className="card-wrapper my-1">
                                                <table style={{width: '100%'}}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{width: '100%', textAlign: 'center', fontSize: '60%'}}>
                                                                {Upline_Add}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xs-4 col-md-4 col-sm-4">
                                        
                                        <div className="card mb-1 text-white" style={{backgroundColor: 'green'}}>
                                            <div className="card-wrapper my-1">
                                                <table style={{width: '100%'}}>
                                                    <tbody>
                                                        <tr>
                                                            <td className='fw-bold' style={{width: '47%', textAlign: 'right'}}>ID</td>
                                                            <td className='fw-bold' style={{width: '6%', textAlign: 'center'}}> : </td>
                                                            <td className='fw-bold' style={{width: '47%', textAlign: 'left'}}>
                                                                {ID}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        
                                        <div className="card mb-1 text-white" style={{backgroundColor: 'green'}}>
                                            <div className="card-wrapper my-1">
                                                <table style={{width: '100%'}}>
                                                    <tbody>
                                                        <tr>
                                                            <td className='fw-bold' style={{width: '47%', textAlign: 'right'}}>Wallet Address</td>
                                                            <td className='fw-bold' style={{width: '6%', textAlign: 'center'}}> : </td>
                                                            <td className='fw-bold' style={{width: '47%', textAlign: 'left'}}></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card mb-1" style={{backgroundColor: 'white'}}>
                                            <div className="card-wrapper my-1">
                                                <table style={{width: '100%'}}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{width: '100%', textAlign: 'center', fontSize: '60%'}}>
                                                                {Wallet_Add}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-xs-4 col-md-4 col-sm-4">                    
                                        
                                        <div className="card mb-1 text-white" style={{backgroundColor: 'green'}}>
                                            <div className="card-wrapper my-1">
                                                <table style={{width: '100%'}}>
                                                    <tbody>
                                                        <tr>
                                                            <td className='fw-bold' style={{width: '34%', textAlign: 'right'}}>Ref Link</td>
                                                            <td className='fw-bold' style={{width: '6%', textAlign: 'center'}}> : </td>
                                                            <td className='fw-bold' style={{width: '50%', textAlign: 'left', fontSize: '65%'}}>
                                                                {Refferal_Link}
                                                            </td>
                                                            <td className='fw-bold' style={{textAlign: 'left', alignItems: 'center', width: '10%', cursor: 'pointer'}} onClick={() => {navigator.clipboard.writeText(Refferal_Link); props.showAlert("Ref Link : " + Refferal_Link + " Copied To ClipBoard!...", 'success', true);}}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                                                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                                                                </svg>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="card mb-1 text-white" style={{backgroundColor: 'green'}}>
                                            <div className="card-wrapper my-1">
                                                <table style={{width: '100%'}}>
                                                    <tbody>
                                                        <tr>
                                                            <td className='fw-bold' style={{width: '100%', textAlign: 'center'}}>
                                                                <table style={{width: '100%'}}>
                                                                    <tbody style={{width: '100%'}}>
                                                                        <tr style={{width: '100%'}}>
                                                                            <td className='fw-bold' style={{width: '47%', textAlign: 'right'}}>Core</td>
                                                                            <td className='fw-bold' style={{width: '6%', textAlign: 'center'}}> : </td>
                                                                            <td className='fw-bold' style={{width: '47%', textAlign: 'left'}}>
                                                                                
                                                                                {Core}
                                                                                    
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        
                                    </div>                    
                                </div>
                            </div>
                        </div>

                        <br />

                        
                        <div className="card" style={{backgroundColor: '#4504af'}}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xs-4 col-md-3 col-sm-3 col-lg-4"></div>
                                    <div className="col-xs-4 col-md-6 col-sm-6 col-lg-4">
                                        <div className="card text-white" style={{backgroundColor: 'rgb(198, 198, 198)'}}>
                                            <div className='card-wrapper my-1 mx-1'>
                                                <table style={{width: '100%'}}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{textAlign: 'center', width: '20%'}}></td>
                                                            <td style={{textAlign: 'center', width: '60%'}}>
                                                                <button className='btn btn-primary' style={{width: '70%', textAlign: 'center', fontSize: FontSize}} onClick={() => {My_Address_Click();
                                                                }}>My</button>
                                                            </td>
                                                            <td style={{textAlign: 'center', width: '20%'}}></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{textAlign: 'center', width: '20%'}}></td>
                                                            <td style={{textAlign: 'center', width: '60%'}}>
                                                                <input className='my-1' id="search_input" type="text" placeholder="Search ID/Address"/>
                                                            </td>
                                                            <td style={{textAlign: 'center', width: '20%'}}></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table style={{width: '100%'}}>
                                                    <tbody>
                                                        <tr className='my-1'>
                                                            <td style={{textAlign: 'center', width: '33%'}}></td>
                                                            <td style={{textAlign: 'center', width: '33%'}}>
                                                                <button className='btn btn-primary' style={{width: '70%', textAlign: 'center', fontSize: FontSize}} onClick={() => {Search_Address_Click()}}>Search</button>
                                                            </td>
                                                            <td style={{textAlign: 'center', width: '33%'}}>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>             
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-4 col-md-3 col-sm-3 col-lg-4"></div>
                                </div>
                            </div>
                        </div>

                        <br />
                        {
                        CurrentForm === "DASHBOARD" ? (
                            <>
                                <Dashboard_Form Current_Address={Current_Address} Search_Should={Search_Should} showAlert={props.showAlert} SetLoadingModal={props.SetLoadingModal}/>
                            </>
                        ) : CurrentForm === "MATRIX WORLD" ? (
                            <>
                                <MatrixWorld_Form Current_Address={Current_Address} Search_Should={Search_Should} showAlert={props.showAlert} SetLoadingModal={props.SetLoadingModal}/>
                            </>
                        ) : CurrentForm === "SLOTS" ? (
                            <>
                                <Slots_Form Current_Address={Current_Address} Search_Should={Search_Should} showAlert={props.showAlert}/>
                            </>
                        ) : CurrentForm === "GEN" ? (
                            <>  
                                <Gen_Form Current_Address={Current_Address} Search_Should={Search_Should} showAlert={props.showAlert}/>
                            </>
                        ) : CurrentForm === "FIXPOOL" ? (
                            <>
                                <FixPool_Form Current_Address={Current_Address} Search_Should={Search_Should} showAlert={props.showAlert}/>
                            </>
                        ) : (
                            <></>
                        )
                        }
                    </div>

                    <br />
                </div>
            </div>
        </>
    )*/
    
    return (
        <>
            <input type="checkbox" id="nav-toggle"/>
            <div className="sidebar">
                <div className="sidebar-brand">
                    <h2>
                        <span><img className="hide-img" src={m_1} alt=""/></span>
                        <span></span>
                    </h2>
                </div>
                <div className="sidebar-menu">
                    <ul>
                        <li>
                            <a className="active" style={{cursor: "pointer"}} onClick={() => {SetCurrentForm("DASHBOARD")}}>
                                <span><img className="menu-img" src={h2} alt=""/></span>
                                <span>Dashboard</span>
                            </a>
                        </li>
                        <li>
                            <a style={{cursor: "pointer"}} onClick={() => {SetCurrentForm("SLOTS")}}>
                                <span><img className="menu-img" src={h3} alt=""/></span>
                                <span>Slots</span>
                            </a>
                        </li>
                        <li>
                            <a style={{cursor: "pointer"}} onClick={() => {SetCurrentForm("GLOBAL REWARDS")}}>
                                <span><img className="menu-img" src={h4} alt=""/></span>
                                <span className="menu-tags">Global Rewards</span>
                            </a>
                        </li>
                        <li>
                            <a style={{cursor: "pointer"}} onClick={() => {SetCurrentForm("LEVEL REWARDS")}}>
                                <span><img className="menu-img" src={h5} alt=""/></span>
                                <span>Level Rewards</span>
                            </a>
                        </li>
                        <li>
                            <a style={{cursor: "pointer"}} onClick={() => {SetCurrentForm("POOL REWARDS")}}>
                                <span><img className="menu-img" src={h6} alt=""/></span>
                                <span>Pool Rewards</span>
                            </a>
                        </li>
                        <li>
                            <a style={{cursor: "pointer"}} className="active" onClick={() => {open();}}>
                                <span><img className="menu-img" src={h7} alt=""/></span>
                                <span>Log Out</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {/*<!--------Main--Content-------------------------->*/} 
            <div className="main-content">
                <header>
                    <h3>
                        <label for="nav-toggle">
                            <span className=""><img src={m_1} alt=""/></span>
                        </label>
                        <span>{CurrentForm}</span>
                    </h3>
                    <div className="user-wrapper">
                        {/*<img src={u_1} width="40px" height="40px" alt=""/>*/}
                        <div className="logo">
                            <img src={l1} alt=""/>
                        </div>
                    </div>
                </header>
                {/*<!--------Main----Cards----------------------->*/} 
                <main>
                    <div className="cards">
                        <div className="card-single">
                            <div>
                                <h3>Refferal link</h3>
                                <div className="r-link">
                                    <span>{Refferal_Link}</span>
                                </div>
                                <div className="copy-link">
                                    <button className="btn" onClick={() => {Copy_Add(Refferal_Link, props.showAlert)}}>
                                        <div>Copy</div>
                                    </button> 
                                </div>
                            </div>
                        </div>
                        <div className="card-single">
                            <div>
                                <div className="card-single-header">
                                    <div>SPONSOR_ID</div>                      
                                    <div className="sp-id">
                                        <span>{Upline_ID}</span>
                                    </div> 
                                </div>
                                <div className="card-single-body">
                                    <div>WALLET</div>                      
                                    <div className="sp-w" onClick={() => {Copy_Add(Upline_Add, props.showAlert)}}>
                                        <span>{Upline_Add_Sub}</span>
                                    </div> 
                                </div>                         
                            </div>
                        </div>
                        <div className="card-single">
                            <div>
                                <div className="card-single-header">
                                    <div>MY_ID</div>                      
                                    <div className="my-id">
                                        <span>{ID}</span>
                                    </div> 
                                </div>
                                <div className="card-single-body">
                                    <div>WALLET</div>                      
                                    <div className="my-w" onClick={() => {Copy_Add(Wallet_Add, props.showAlert)}}>
                                        <span>{Wallet_Add_Sub}</span>
                                    </div> 
                                </div>                         
                            </div>
                        </div>
                        <div className="card-single">
                            <div>
                                <div className="card-single-header">                      
                                    <div className="c-logo">
                                        <span><img src={core_1} alt=""/></span>
                                    </div> 
                                </div>
                                <div className="card-single-body">
                                    <div className="c-name">CORE</div>                  
                                    <div className="c-value">
                                        <span>{Core}</span>
                                    </div> 
                                </div>                         
                            </div>
                        </div>
                        <div className="card-search">
                            <div className="search-id">
                                <button className="myid" onClick={() => {My_Address_Click()}}>
                                    <div>My ID</div>
                                </button> 
                            </div>
                            <div className="search">
                                <input type="text" name="search" id="search_input" placeholder="Search here"/>
                                <label for="search">
                                </label>
                            </div> 
                            <div className="search-id">
                                <button className="team-id-search" onClick={() => {Search_Address_Click()}}>
                                    <div>Search</div>
                            </button> 
                            </div>
                        </div>
                        {
                           CurrentForm === "DASHBOARD" ? (
                                <>
                                    {/*<!-------Pool---Status---Card----------------------->*/}                  
                                    <Pool_Status_Card_Check Current_Address={Current_Address} Click_FixPool_Current_Detail={Click_FixPool_Current_Detail}/>
                                </>
                            ) : (
                                <></>
                            )
                        }
                    </div>
                    {
                        CurrentForm === "DASHBOARD" ? (
                            <>
                                <div className="hide-space"></div>
                                <div className="back-grid-short">                                 
                                    <div className="heading">
                                        <span>Pool Status</span>
                                    </div>
                                </div>
                                <div className="back-grid-middle">
                                    <div className="sub-heading">
                                        <span>{
                                            Number(FixPool_PackNo) === Number(1) ? (
                                                <>Bronze</>
                                            ) : Number(FixPool_PackNo) === Number(2) ? (
                                                <>Sliver</>
                                            ) : Number(FixPool_PackNo) === Number(3) ? (
                                                <>Gold</>
                                            ) : Number(FixPool_PackNo) === Number(4) ? (
                                                <>Diamond</>
                                            ) : Number(FixPool_PackNo) === Number(5) ? (
                                                <>Platinum</>
                                            ) : (
                                                <></>
                                            )
                                        }</span>
                                    </div>
                                    <div className="scroll-bar">
                                        <table className="table-pool img-item">
                                            <thead>
                                                <th>Sr</th>
                                                <th>ID</th>
                                                <th>Pool 1</th>
                                                <th>Pool 2</th>
                                                <th>Pool 3</th>
                                                <th>Pool 4</th>
                                                <th>Pool 5</th>
                                                <th>Pool 6</th>
                                                <th>Pool 7</th>
                                                <th>Pool 8</th>
                                                <th>Pool 9</th>
                                                <th>Pool 10</th>
                                                <th>Pool 11</th>
                                                <th>Pool 12</th>
                                            </thead>
                                            <tbody>
                                                {   
                                                    FixPool_Detail_Loading === "" ? (
                                                        FixPool_Detail.map((data) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td data-label="Sr">{data.sr}</td>
                                                                        <td data-label="ID">{data.id}</td>
                                                                        <td data-label="Pool 1">{
                                                                            data.pool1_is_src === activate_now ? (
                                                                                <>
                                                                                    <img src="" alt=""/>{activate_now}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src={data.pool1_is_src} alt=""/>
                                                                                </>
                                                                            )
                                                                        }</td>
                                                                        <td data-label="Pool 2">{
                                                                            data.pool2_is_src === activate_now ? (
                                                                                <>
                                                                                    {activate_now}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src={data.pool2_is_src} alt=""/>
                                                                                </>
                                                                            )
                                                                        }</td>
                                                                        <td data-label="Pool 3">{
                                                                            data.pool3_is_src === activate_now ? (
                                                                                <>
                                                                                    {activate_now}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src={data.pool3_is_src} alt=""/>
                                                                                </>
                                                                            )
                                                                        }</td>
                                                                        <td data-label="Pool 4">{
                                                                            data.pool4_is_src === activate_now ? (
                                                                                <>
                                                                                    <img src="" alt=""/>{activate_now}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src={data.pool4_is_src} alt=""/>
                                                                                </>
                                                                            )
                                                                        }</td>
                                                                        <td data-label="Pool 5">{
                                                                            data.pool5_is_src === activate_now ? (
                                                                                <>
                                                                                    <img src="" alt=""/>{activate_now}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src={data.pool5_is_src} alt=""/>
                                                                                </>
                                                                            )
                                                                        }</td>
                                                                        <td data-label="Pool 6">{
                                                                            data.pool6_is_src === activate_now ? (
                                                                                <>
                                                                                    <img src="" alt=""/>{activate_now}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src={data.pool6_is_src} alt=""/>
                                                                                </>
                                                                            )
                                                                        }</td>
                                                                        <td data-label="Pool 7">{
                                                                            data.pool7_is_src === activate_now ? (
                                                                                <>
                                                                                    <img src="" alt=""/>{activate_now}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src={data.pool7_is_src} alt=""/>
                                                                                </>
                                                                            )
                                                                        }</td>
                                                                        <td data-label="Pool 8">{
                                                                            data.pool8_is_src === activate_now ? (
                                                                                <>
                                                                                    <img src="" alt=""/>{activate_now}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src={data.pool8_is_src} alt=""/>
                                                                                </>
                                                                            )
                                                                        }</td>
                                                                        <td data-label="Pool 9">{
                                                                            data.pool9_is_src === activate_now ? (
                                                                                <>
                                                                                    <img src="" alt=""/>{activate_now}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src={data.pool9_is_src} alt=""/>
                                                                                </>
                                                                            )
                                                                        }</td>
                                                                        <td data-label="Pool 10">{
                                                                            data.pool10_is_src === activate_now ? (
                                                                                <>
                                                                                    <img src="" alt=""/>{activate_now}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src={data.pool10_is_src} alt=""/>
                                                                                </>
                                                                            )
                                                                        }</td>
                                                                        <td data-label="Pool 11">{
                                                                            data.pool11_is_src === activate_now ? (
                                                                                <>
                                                                                    <img src="" alt=""/>{activate_now}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src={data.pool11_is_src} alt=""/>
                                                                                </>
                                                                            )
                                                                        }</td>
                                                                        <td data-label="Pool 12">{
                                                                            data.pool12_is_src === activate_now ? (
                                                                                <>
                                                                                    <img src="" alt=""/>{activate_now}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src={data.pool12_is_src} alt=""/>
                                                                                </>
                                                                            )
                                                                        }</td>


                                                                        {/*
                                                                            
                                                                        <td data-label="Pool 1"><img src={Tick} alt=""/></td>
                                                                        <td data-label="Pool 2"><img src={Tick} alt=""/></td>
                                                                        <td data-label="Pool 3"><img src={Tick} alt=""/></td>
                                                                        <td data-label="Pool 4"><img src={Tick} alt=""/></td>
                                                                        <td data-label="Pool 5"><img src={Tick} alt=""/></td>
                                                                        <td data-label="Pool 6"><img src={Tick} alt=""/></td>
                                                                        <td data-label="Pool 7"><img src="" alt=""/>Activate Now</td>
                                                                        <td data-label="Pool 8"><img src="" alt=""/>Activate Now</td>
                                                                        <td data-label="Pool 9"><img src={Tick_Blank} alt=""/></td>
                                                                        <td data-label="Pool 10"><img src={Tick_Blank} alt=""/></td>
                                                                        <td data-label="Pool 11"><img src={Tick_Blank} alt=""/></td>
                                                                        <td data-label="Pool 12"><img src={Tick_Blank} alt=""/></td>*/
                                                                        }
                                                                    </tr>
                                                                </>
                                                            );
                                                        })
                                                    ) : (
                                                        <>
                                                            <tr>
                                                                <td data-label="Sr"></td>
                                                                <td data-label="ID"></td>
                                                                <td data-label="Pool 1"></td>
                                                                <td data-label="Pool 2"></td>
                                                                <td data-label="Pool 3"></td>
                                                                <td data-label="Pool 4"></td>
                                                                <td data-label="Pool 5">{FixPool_Detail_Loading}</td>
                                                                <td data-label="Pool 6"></td>
                                                                <td data-label="Pool 7"></td>
                                                                <td data-label="Pool 8"></td>
                                                                <td data-label="Pool 9"></td>
                                                                <td data-label="Pool 10"></td>
                                                                <td data-label="Pool 11"></td>
                                                                <td data-label="Pool 12"></td>
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div> 
                                </div>
                            </>
                        ) : (
                            <></>
                        )
                    }

                    {
                        CurrentForm === "DASHBOARD" ? (
                            <>
                                <Dashboard_Detail Current_Address={Current_Address} Search_Should={Search_Should} showAlert={props.showAlert}/>
                            </>
                        ) : CurrentForm === "SLOTS" ? (
                            <>
                                <Slots_Detail Current_Address={Current_Address} Search_Should={Search_Should} showAlert={props.showAlert} SetLoadingModal={props.SetLoadingModal}/>
                            </>
                        ) : CurrentForm === "GLOBAL REWARDS" ? (
                            <>
                                <Global_Detail Current_Address={Current_Address} Search_Should={Search_Should} showAlert={props.showAlert}/>
                            </>
                        ) : CurrentForm === "LEVEL REWARDS" ? (
                            <>
                                <Level_Reward_Detail Current_Address={Current_Address} Search_Should={Search_Should} showAlert={props.showAlert} SetLoadingModal={props.SetLoadingModal}/>
                            </>
                        ) : CurrentForm === "POOL REWARDS" ? (
                            <>
                                <Pool_Reward_Detail Current_Address={Current_Address} Search_Should={Search_Should} showAlert={props.showAlert} SetLoadingModal={props.SetLoadingModal}/>
                            </>
                        ) : (
                            <></>
                        )
                    }
                          
                </main>
            </div>
        </>
    );
}
