import React, { useState, useEffect } from 'react';
import { createPublicClient, http, formatEther, formatUnits, createWalletClient, custom } from 'viem';
import { coreDao } from 'viem/chains';
import { privateKeyToAccount } from 'viem/accounts';

import './backhand_style.css';
import './backhand_media-queries.css';

import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react';

import { Test_CoreDao } from './Core_Test_Network';

import loading_gif from './images/loading_gif.gif';

import DateTime_Con from './contracts/MatrixWorld_TestUsdt_And_DateTime/DateTimeContract.json';
import USDT_Con from './contracts/MatrixWorld_TestUsdt_And_DateTime/TestUSDT_Token.json';

import MWMain_Con from './contracts/SL1/MWMain.json';

//---SL1--------

import Single_Leg1_Transaction_Con from './contracts/SL1/Single_Leg1_Transaction.json';
import Fixed_Pool1_Transaction_Con from './contracts/SL1/Fixed_Pool1_Transaction.json';
import Single_Leg1_Detail_Con from './contracts/SL1/Single_Leg1_Detail.json';

import Fixed_Pool1_Con from './contracts/SL1/Fixed_Pool1.json';
import SL1_SGL1_Data_Con from './contracts/SL1/SL1_SGL1_Data.json';
import SL1_Gen1_Data_Con from './contracts/SL1/SL1_Gen1_Data.json';
import Single_Leg1_Con from './contracts/SL1/Single_Leg1.json';

//---SL2--------

import Single_Leg2_Transaction_Con from './contracts/SL2/Single_Leg2_Transaction.json';
import Fixed_Pool2_Transaction_Con from './contracts/SL2/Fixed_Pool2_Transaction.json';
import Single_Leg2_Detail_Con from './contracts/SL2/Single_Leg2_Detail.json';

import Fixed_Pool2_Con from './contracts/SL2/Fixed_Pool2.json';
import SL2_SGL2_Data_Con from './contracts/SL2/SL2_SGL2_Data.json';
import SL2_Gen2_Data_Con from './contracts/SL2/SL2_Gen2_Data.json';
import Single_Leg2_Con from './contracts/SL2/Single_Leg2.json';

//---SL3--------

import Single_Leg3_Transaction_Con from './contracts/SL3/Single_Leg3_Transaction.json';
import Fixed_Pool3_Transaction_Con from './contracts/SL3/Fixed_Pool3_Transaction.json';
import Single_Leg3_Detail_Con from './contracts/SL3/Single_Leg3_Detail.json';

import Fixed_Pool3_Con from './contracts/SL3/Fixed_Pool3.json';
import SL3_SGL3_Data_Con from './contracts/SL3/SL3_SGL3_Data.json';
import SL3_Gen3_Data_Con from './contracts/SL3/SL3_Gen3_Data.json';
import Single_Leg3_Con from './contracts/SL3/Single_Leg3.json';

//---SL4--------

import Single_Leg4_Transaction_Con from './contracts/SL4/Single_Leg4_Transaction.json';
import Fixed_Pool4_Transaction_Con from './contracts/SL4/Fixed_Pool4_Transaction.json';
import Single_Leg4_Detail_Con from './contracts/SL4/Single_Leg4_Detail.json';

import Fixed_Pool4_Con from './contracts/SL4/Fixed_Pool4.json';
import SL4_SGL4_Data_Con from './contracts/SL4/SL4_SGL4_Data.json';
import SL4_Gen4_Data_Con from './contracts/SL4/SL4_Gen4_Data.json';
import Single_Leg4_Con from './contracts/SL4/Single_Leg4.json';

//---SL5--------

import Single_Leg5_Transaction_Con from './contracts/SL5/Single_Leg5_Transaction.json';
import Fixed_Pool5_Transaction_Con from './contracts/SL5/Fixed_Pool5_Transaction.json';
import Single_Leg5_Detail_Con from './contracts/SL5/Single_Leg5_Detail.json';

import Fixed_Pool5_Con from './contracts/SL5/Fixed_Pool5.json';
import SL5_SGL5_Data_Con from './contracts/SL5/SL5_SGL5_Data.json';
import SL5_Gen5_Data_Con from './contracts/SL5/SL5_Gen5_Data.json';
import Single_Leg5_Con from './contracts/SL5/Single_Leg5.json';

import m_1 from './assets/m_1.png';

import h2 from './assets/h2.png';
import h3 from './assets/h3.png';
import h4 from './assets/h4.png';
import h5 from './assets/h5.png';
import h6 from './assets/h6.png';
import h7 from './assets/h7.png';

import u_1 from './assets/u_1.png';

import l1 from './assets/l1.png';

import core_1 from './assets/core_1.png';

import Tick from './assets/Tick.png';
import Tick_Blank from './assets/Tick_Blank.png';

import left from './assets/left.png';
import right from './assets/right.png';


const Total_Package = Number(2);
const Total_Package_Arr = [];
for(let tp = 1; tp <= Total_Package; tp++) {
    Total_Package_Arr.push({
        packno: tp
    });
}
console.log(Total_Package_Arr);

const DateTime = {
    //address: "0x8cC1928DE66eCe4668c37dc093e91bB1b5D2370F",
    address: "0x0220602ed1f6e20eE230D3f909931c39e243B45E",
    abi: DateTime_Con.abi
}
/*const USDT = {
    address: "0xd7454466794700Ed75ca06CA83eEaF25B85a2A1B",
    abi: USDT_Con.abi
}*/

const MWMain = {
    address: "0xd3ff7fA8854216760FFc0C800b1e51dc62709E57",
    abi: MWMain_Con.abi
}

const Single_Leg_Transaction = [];
const Fixed_Pool_Transaction = [];
const Single_Leg_Detail = [];

const FixPool = [];

const Single_Leg_Data = [];
const Gen_Data = [];

const Single_Leg = [];

//-----------Package1------------

Single_Leg_Transaction[1] = {
    address: "0xC2a6592cD3a024EbAb15569B6840d13DE74E1235",
    abi: Single_Leg1_Transaction_Con.abi
}
Fixed_Pool_Transaction[1] = {
    address: "0x976fa5056774Fe374392d4a53a3045879B058D37",
    abi: Fixed_Pool1_Transaction_Con.abi
}
Single_Leg_Detail[1] = {
    address: "0xD80015aFB265BDe6B71cF522a6C035e06786Dfb6",
    abi: Single_Leg1_Detail_Con.abi
}

FixPool[1] = {
    address: "0xED082a153aCc74A322a8203fc70bBBD304604552",
    abi: Fixed_Pool1_Con.abi
}

Single_Leg_Data[1] = {
    address: "0xfD6850631263135286e58C00b9528b60443ee9e7",
    abi: SL1_SGL1_Data_Con.abi
}
Gen_Data[1] = {
    address: "0x268f919FD79eeD672842bAA8e34F4fe1fc03e873",
    abi: SL1_Gen1_Data_Con.abi
}

Single_Leg[1] = {
    address: "0x4b983d6E764E84BC4B088E146631d6A889eCCe7D",
    abi: Single_Leg1_Con.abi
}

//-----------Package2------------

Single_Leg_Transaction[2] = {
    address: "0x2BF28BcF5f258D48A6CcB36815f3edc2e4b0ED38",
    abi: Single_Leg2_Transaction_Con.abi
}
Fixed_Pool_Transaction[2] = {
    address: "0x7e1D57E5D4E2a77Baa44Ba2986461eAD68C63C90",
    abi: Fixed_Pool2_Transaction_Con.abi
}
Single_Leg_Detail[2] = {
    address: "0x9B1A34A836449a203c70c8b68a1C6B4777d370AC",
    abi: Single_Leg2_Detail_Con.abi
}

FixPool[2] = {
    address: "0xCCD9DDcCA78036B79E0B358014aE38E97d44B786",
    abi: Fixed_Pool2_Con.abi
}

Single_Leg_Data[2] = {
    address: "0x4F10091423EbB59cD663Ad38E36724f8dF4082d7",
    abi: SL2_SGL2_Data_Con.abi
}
Gen_Data[2] = {
    address: "0x2F53489497D053180696e9A9AE2Ee6352F595e34",
    abi: SL2_Gen2_Data_Con.abi
}

Single_Leg[2] = {
    address: "0x5F16384Ef3d55e9b6948AcDCAAdF2fb90C4B6e51",
    abi: Single_Leg2_Con.abi
}

//-----------Package3------------
/*
Single_Leg_Transaction[3] = {
    address: "0xD5C02C63376b7D366fDe583C802ed263BbCB1fB4",//"0x5014fA1c99df8b23B1346BFE21fCF7E2775e2E9f",
    abi: Single_Leg3_Transaction_Con.abi
}
Fixed_Pool_Transaction[3] = {
    address: "0x1a033536a82B38FCB7c47b52203E440689e697D8",//"0xb2d5ed50E7E9d12d421aa0089370EE7c7d0F0Fa9",
    abi: Fixed_Pool3_Transaction_Con.abi
}
Single_Leg_Detail[3] = {
    address: "0x598b35fc38A77B1e00Cd91cCabEA433294b3bF6D",//"0xCc65730e9EA9Dd213E383BA4005240193e5691B7",
    abi: Single_Leg3_Detail_Con.abi
}

FixPool[3] = {
    address: "0x7bDDe78D7c195210491fa2468C5bDa360c70d11f",//"0xfdBe577225A1D24B5A53794A01D99745bef06fdb",
    abi: Fixed_Pool3_Con.abi
}

Single_Leg_Data[3] = {
    address: "0x01F5f41De4B95f8a95fecC6eD5F1785f59F717e0",//"0x67Dd272399d0fB99e811A060Aa98Ff8f616dAE17",
    abi: SL3_SGL3_Data_Con.abi
}
Gen_Data[3] = {
    address: "0x57e131cC179c7129c7F258bDe9043510F6782344",//"0x8010CE2353E1772CDd77B2E6c8B72649a2F27B33",
    abi: SL3_Gen3_Data_Con.abi
}

Single_Leg[3] = {
    address: "0xAf5F9a612b39f4d89dD6d7ad9c0F43BD5F07c23D",//"0x81AE615F3b98A0ebB16D2c78Fba3a47c9d5953A1",
    abi: Single_Leg3_Con.abi
}

//-----------Package4------------

Single_Leg_Transaction[4] = {
    address: "0xDcC4E0F57D91E1c535f3df62429E940a6Ad1D8Ee",
    abi: Single_Leg4_Transaction_Con.abi
}
Fixed_Pool_Transaction[4] = {
    address: "0x8D129451CD9E150E1c325dC0c1cd97391740e846",
    abi: Fixed_Pool4_Transaction_Con.abi
}
Single_Leg_Detail[4] = {
    address: "0x16BF83D70d2302B959c74B6874a5238FFdcc9FE5",
    abi: Single_Leg4_Detail_Con.abi
}

FixPool[4] = {
    address: "0xa0c4011E5eF34F6De1d536a4e2c1Ca3bFE8A2f26",
    abi: Fixed_Pool4_Con.abi
}

Single_Leg_Data[4] = {
    address: "0x200Ef56A65059A9a065060E61499d75E9b7b2687",
    abi: SL4_SGL4_Data_Con.abi
}
Gen_Data[4] = {
    address: "0x513772b6E66fC6475AB715D3244B2BF96A28dd2b",
    abi: SL4_Gen4_Data_Con.abi
}

Single_Leg[4] = {
    address: "0x53e2D7CE2851a0B89eB28BBa4fbC568745ee979D",
    abi: Single_Leg4_Con.abi
}

//-----------Package5------------

Single_Leg_Transaction[5] = {
    address: "0xAeBB43C57978C3fA845f856A0De2da5E093aA45f",
    abi: Single_Leg5_Transaction_Con.abi
}
Fixed_Pool_Transaction[5] = {
    address: "0xDf15B1Eaf533D58c6653ca93337d5b6d2290DF8F",
    abi: Fixed_Pool5_Transaction_Con.abi
}
Single_Leg_Detail[5] = {
    address: "0xcb0ebD0931881b8dd4047B5f00da9c2E967c5b67",
    abi: Single_Leg5_Detail_Con.abi
}

FixPool[5] = {
    address: "0x28D5F96D86220096bC87379378f24394D81fe509",
    abi: Fixed_Pool5_Con.abi
}

Single_Leg_Data[5] = {
    address: "0xEd2A658cFe44B9e6326640b3c916C0a92B555826",
    abi: SL5_SGL5_Data_Con.abi
}
Gen_Data[5] = {
    address: "0x5C16C431D804079879BA0a61510eDCa7856Bc3CF",
    abi: SL5_Gen5_Data_Con.abi
}

Single_Leg[5] = {
    address: "0x978C764cB077Db884C1EA85C1491AE8f5e6e8be6",
    abi: Single_Leg5_Con.abi
}
*/
//-------------------------------

console.log(MWMain);

console.log(Single_Leg_Transaction);
console.log(Fixed_Pool_Transaction);
console.log(Single_Leg_Detail);

console.log(FixPool);

console.log(Single_Leg_Data);
console.log(Gen_Data);

console.log(Single_Leg);

const p_chain = coreDao;
//const p_chain = Test_CoreDao;

const p_transport = "https://rpc.coredao.org";
//const p_transport = "https://rpc.test.btcs.network";

const client = createPublicClient({
  chain: p_chain,
  transport: http(p_transport)
});

 
let USDT_unit = Number(6);
 
let USDT_deceimals = Number(2);
let Core_deceimals = Number(4);//2);

//let Symbol = "$";
let Symbol = "CORE";

let url = "https://coreventure.space/";
//let url = "http://localhost:3000/";

//let type_url = "https://scan.test.btcs.network/address/";
let type_url = "https://scan.coredao.org/address/";

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//-------------------------------------------For_Nutral-------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function Loading_Component(props) {

    return (
        <>
            <img style={{width: '30px', height: '30px'}} src={loading_gif} alt="loading" />
        </>
    );
}
function Check_ID_Detail_Loading_Component(props) {

    return (
        <>
            <img style={{width: '20px', height: '20px'}} src={loading_gif} alt="loading" />
        </>
    );
}
function Tree_Loading_Component(props) {

    return (
        <>
            <img style={{width: '20px', height: '20px'}} src={loading_gif} alt="loading" />
        </>
    );
}
export function Grid_Loading_Component(props) {
    return (
        <>
        <img style={{width: '20px', height: '20px'}} src={loading_gif} alt="loading" />
        </>
    );
}
function Client_Loading_Component(props) {
    return (
        <>
        <img style={{width: '20px', height: '20px'}} src={loading_gif} alt="loading" />
        </>
    );
}

//-----Search_Function_Area------------------

export async function Address_Exist(add, error_text, Search_Should, SetCurrent_Address, SetSearch_Should, showAlert) {

    try {
        let User_Address_Exist = await client.readContract({
            address: MWMain.address,
            abi: MWMain.abi,
            functionName: "GetUser_Address_Exist",
            args: [add]
        });
        
        if (User_Address_Exist === true) {
            SetCurrent_Address(add);
  
            if (Search_Should === true) {
              SetSearch_Should(false);
            }
            else if (Search_Should === false) {
               SetSearch_Should(true);
            }
        }
        else {
            //showAlert(error_text, 'danger', true);
        }
    }
    catch(error) {
        console.log(error);
    }
  
}
export async function Search_Data(search_input, Search_Should, SetCurrent_Address, SetSearch_Should, showAlert) {
    //console.log('search input is : ' + search_input);
          
    if (search_input.startsWith("0x")) {
      //console.log(search_input + ' Is Address');
      if (search_input === "0x0000000000000000000000000000000000000000") {
        //showAlert("InValid Address", 'danger', true);
      }
      else {
        Address_Exist(search_input, "InValid Address", Search_Should, SetCurrent_Address, SetSearch_Should, showAlert);
      }
    }
    else {
      if (search_input === "" || search_input.startsWith(" ")) {} 
      else {
        //console.log(search_input + ' Is ID');
        try {
            let User_ID_Address = await client.readContract({
                address: MWMain.address,
                abi: MWMain.abi,
                functionName: "GetUser_ID_Address",
                args: [search_input]
            });
            if (User_ID_Address === "0x0000000000000000000000000000000000000000") {
                //showAlert("InValid ID", 'danger', true);
            }
            else {
                Address_Exist(User_ID_Address, "InValid ID", Search_Should, SetCurrent_Address, SetSearch_Should, showAlert);
            }
        }
        catch(error) {
            console.log(error);
        }
      }
    }
}
export async function Copy_Add(Add, showAlert) {
    if (Add === '') {}
    else {
        navigator.clipboard.writeText(Add);
        showAlert(Add + " Copied To ClipBoard!...", 'success', true);
        //showAlert("Address : " + Add + " Copied To ClipBoard!...", 'success', true);
    }
}

export function Sub_Variable(data) {
    //console.log("_____________________");
    //console.log(data);
    let var_data = data.substring(0, 10) + "......" + data.substring(31, 41);
    //console.log(var_data);
    return var_data;
}
function Format_Usdt(data) {
    return Number(formatUnits(data, USDT_unit)).toFixed(USDT_deceimals);
}
function Format_Core(data) {
    return Number(formatEther(BigInt(data))).toFixed(Core_deceimals);
}
function IsSymbol() {
    return (
        <>
            {
                Symbol === "$" ? (
                    <>{Symbol}</>
                ) : (
                    <> {Symbol}</>
                )
            }
        </>
    )
}

//---------Slot1_Tree_And_Grid_Detail----------------

export function Slot1_Tree_And_Grid_Detail(props) {
    
    const [Slot1_Type, SetSlot1_Type] = useState("");
    const [Select_Level, SetSelect_Level] = useState(Number(1));
   
    let Blue = '#095ede';//'#0d6efd';
    let Green = 'green';//'rgb(31, 226, 31)';

    const [Slot1_SingleLeg_Button_Color, SetSlot1_SingleLeg_Button_Color] = useState(Green);
    const [Slot1_Gen_Button_Color, SetSlot1_Gen_Button_Color] = useState(Blue);

    const Slot1_Type_Click = async(Slot_Type) => {
        if (Slot_Type === "Single Leg") {
            SetSlot1_SingleLeg_Button_Color(Green);
            SetSlot1_Gen_Button_Color(Blue);
        }
        else if (Slot_Type === "Gen") {
            SetSlot1_SingleLeg_Button_Color(Blue);
            SetSlot1_Gen_Button_Color(Green);

            SetSelect_Level(Number(1));
        }
        SetSlot1_Type(Slot_Type);
    } 

    useEffect(() => {
        Slot1_Type_Click("Single Leg");
    }, [props.Search_Should]);

    //'#4504af'


    return (
        <>
            <div className="card" style={{backgroundColor: props.Card_BackGroundColor}}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-3 col-md-4 col-lg-4"></div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="card text-white" style={{backgroundColor: 'green'}}>
                                <div className='card-wrapper'>
                                    <div className="row">
                                        <div className="col my-2 mx-1 fw-bold" style={{textAlign: 'center', fontSize: '120%'}}>Slots</div>                                                                           
                                    </div>              
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-4 col-lg-4"></div>
                    </div>

                    {/*<br />*/}

                    <div className="row">
                        <div className="col-sm-3 col-md-4 col-lg-4"></div>
                        
                        <div className="col-sm-6 col-md-4 col-lg-4 my-3">
                            <div className="card text-white" style={{backgroundColor: 'yellow'}}>
                                <div className='card-wrapper'>
                                    <div className="row">
                                        <div className="col-6" style={{textAlign: 'center'}}>
                                            <button className='btn text-white fw-bold my-2' style={{width: '70%', fontSize: '70%', backgroundColor: Slot1_SingleLeg_Button_Color}} onClick={() => {Slot1_Type_Click("Single Leg")}}>Single Leg</button>
                                        </div>                                                                           
                                        <div className="col-6" style={{textAlign: 'center'}}>
                                            <button className='btn text-white fw-bold my-2' style={{width: '70%', fontSize: '70%', backgroundColor: Slot1_Gen_Button_Color}} onClick={() => {Slot1_Type_Click("Gen")}}>Gen</button>
                                        </div>                                                          
                                    </div>              
                                </div>
                            </div>
                        </div>
                                
                        
                        {
                            Slot1_Type === "Gen" ? (
                                <>
                                    <div className="col-sm-3 col-md-4 col-lg-4 my-3">
                                        <div className="card text-white" style={{backgroundColor: 'yellow'}}>
                                            <div className='card-wrapper'>
                                                <div className="row">
                                                    <div className="col-2" style={{textAlign: 'center'}}></div>
                                                    <div className="col-8" style={{textAlign: 'center'}}>
                                                        <div className="btn-group my-2">
                                                            <button className='fw-bold' type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style={{width: '70%', fontSize: '70%', backgroundColor: Slot1_Gen_Button_Color, color: 'white'}}>
                                                                Select Level
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li><a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => {SetSelect_Level(Number(1))}}>Level 1</a></li>
                                                                <li><a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => {SetSelect_Level(Number(2))}}>Level 2</a></li>
                                                                <li><a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => {SetSelect_Level(Number(3))}}>level 3</a></li>
                                                                <li><a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => {SetSelect_Level(Number(4))}}>level 4</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>                                                                           
                                                    <div className="col-2" style={{textAlign: 'center'}}></div>                                                          
                                                </div>              
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-sm-3 col-md-4 col-lg-4">
                                    </div>
                                </>
                            )
                        }
                    </div>

                    {/*<br />*/}

                    {
                        Slot1_Type === "Single Leg" ? (
                            <>
                                <Slot1_SingleLeg_Detail Current_Address={props.Current_Address} Search_Should={props.Search_Should} showAlert={props.showAlert}/>
                            </>
                        ) : Slot1_Type === "Gen" ? (
                            <>
                                <Slot1_Gen_Detail Current_Address={props.Current_Address} Select_Level={Select_Level} Search_Should={props.Search_Should} showAlert={props.showAlert}/>
                            </>
                        ) : (
                            <></>
                        )
                    }
                    
                </div>
            </div>
        </>
    );
}

export function Slot1_SingleLeg_Detail(props) {
    
    const [SlotLoading, SetSlotLoading] = useState();
    const [Slot1_Detail_Loading, SetSlot1_Detail_Loading] = useState();
    const [Slot1_Detail_Found, SetSlot1_Detail_Found] = useState();
    
    const [MID_Color, SetMID_Color] = useState('white');
    const [MID_Title, SetMID_Title] = useState('');

    const [ID1_Color, SetID1_Color] = useState('white');
    const [ID1_Title, SetID1_Title] = useState('');
    
    const [ID2_Color, SetID2_Color] = useState('white');
    const [ID2_Title, SetID2_Title] = useState('');
    
    const [Slot1_SingleLeg_Detail, SetSlot1_SingleLeg_Detail] = useState([]);

    useEffect(() => {
        //Package1_Single_Leg_Tree(props.Current_Address, SetMID_Color, SetMID_Title, SetID1_Color, SetID1_Title, SetID2_Color, SetID2_Title, SetSlot1_SingleLeg_Detail, SetSlotLoading, SetSlot1_Detail_Found, SetSlot1_Detail_Loading);
    }, [props.Search_Should]);

    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col" style={{textAlign: 'center'}}>                                  
                            <div className="row my-1" style={{textAlign: 'center'}}>  
                                <div className="col-xs-4 col-md-3 col-sm-4"></div>
                                <div className="col-xs-4 col-md-6 col-sm-4">
                                    <div className="card text-success mb-4" style={{backgroundColor: 'rgb(198, 198, 198)', width: '100%'}}>
                                        <div className='card-body'>
                                            <table style={{ height: '168px', width: '100%'}}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{textAlign: 'center', width: '5%', fontSize: '70%'}}></td>
                                                        <td className='fs-8 fw-semibold' style={{textAlign: 'center',  width: '90%'}}>Package : 10 Core</td>
                                                        <td style={{textAlign: 'left', width: '5%', fontSize: '70%'}}></td>
                                                    </tr>

                                                    <tr>
                                                        <td style={{width: '5%'}}></td>
                                                        <td style={{width: '90%'}}>
                                                            <div className="card" style={{backgroundColor: 'rgb(161, 160, 160)', height: '100%', width: '100%'}}>
                                                                <div className="card-body">
                                                                    <table style={{width: '100%'}}>
                                                                        <tbody>
                                                                            <tr>       
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>

                                                                                <td style={{textAlign: 'center', width: '11.11%'}}><div className="card mx-2" style={{backgroundColor: MID_Color, border: 'green', height: '25px', width: '22px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={MID_Title} onClick={() => {Copy_Add(MID_Title, props.showAlert);}}></div></td>

                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                            </tr>
                                                                            
                                                                            <tr style={{height: '6px', width: '100%'}}></tr>

                                                                            <tr>       
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>

                                                                                <td style={{textAlign: 'center', width: '11.11%'}}><div className="card mx-2" style={{backgroundColor: ID1_Color, border: 'green', height: '25px', width: '22px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID1_Title} onClick={() => {Copy_Add(ID1_Title, props.showAlert);}}></div></td>

                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                            </tr>
                                                                            
                                                                            <tr style={{height: '6px', width: '100%'}}></tr>
                                                                            
                                                                            <tr>       
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>

                                                                                <td style={{textAlign: 'center', width: '11.11%'}}><div className="card mx-2" style={{backgroundColor: ID2_Color, border: 'green', height: '25px', width: '22px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID2_Title} onClick={() => {Copy_Add(ID2_Title, props.showAlert);}}></div></td>

                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                                <td style={{textAlign: 'center', width: '11.11%'}}></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{width: '5%'}}></td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td style={{width: '5%'}}></td>
                                                        <td style={{width: '90%'}}>
                                                            <table style={{width: '100%'}}>
                                                                <tbody>
                                                                    <tr style={{height: '15px'}}>
                                                                        <td className='fs-8 fw-bold' style={{textAlign: 'left', width: '30%', fontSize: '90%'}}></td>
                                                                        <td className='fs-8 fw-bold' style={{textAlign: 'center',  width: '15%', fontSize: '90%'}}></td>
                                                                        <td style={{textAlign: 'center',  width: '10%', fontSize: '70%'}}>
                                                                            {SlotLoading}
                                                                        </td>
                                                                        <td className='fs-8 fw-bold' style={{textAlign: 'center',  width: '15%', fontSize: '90%'}}></td>
                                                                        <td className='fs-8 fw-bold' style={{textAlign: 'right', width: '30%', fontSize: '90%'}}></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <table style={{width: '100%'}}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{width: '50%'}}>
                                                                            <button className='btn'></button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td style={{width: '5%'}}></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-4 col-md-3 col-sm-4"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col" style={{textAlign: 'center'}}>
                            <div className="card" style={{backgroundColor: 'green'}}>
                                <div className="card-body">
                                    
                                    <div className="row my-1">
                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"></div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" style={{textAlign: 'center', fontSize: '90%'}}>
                                            <h5 className='text-white'>Slot1 Single Leg Detail</h5>
                                        </div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"></div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            
                                            <div className="table-responsive" style={{width: '100%', position: 'relative', height: '260px', overflow: 'auto'}}>
                                                                
                                                <table className="table table-striped table-hover border" style={{width: '100%'}}>
                                                    <thead className='sticky-top bg-white' style={{zIndex: '10'}}>
                                                        <tr> 
                                                            <th style={{textAlign: 'center', fontSize: '90%'}}>Sr</th>
                                                            <th style={{textAlign: 'center', fontSize: '90%'}}>ID</th>
                                                            <th style={{textAlign: 'center', fontSize: '90%'}}>Reward</th>
                                                        </tr>
                                                    </thead> 

                                                    <tbody>
                                                        {
                                                            Slot1_Detail_Loading === false ? ( 
                                                                <>
                                                                    {
                                                                        Slot1_Detail_Found === true ? (
                                                                            <>
                                                                                {
                                                                                    Slot1_SingleLeg_Detail.map((data) => {
                                                                                        //console.log(data.sr);
                                                                                        return (
                                                                                            <tr key={(data.id + data.sr + data.reward)}>
                                                                                                <td style={{textAlign: 'center', fontSize: '70%'}}>{data.sr}</td>
                                                                                                <td style={{textAlign: 'center', fontSize: '70%'}}>{data.id}</td>
                                                                                                <td style={{textAlign: 'center', fontSize: '70%'}}>{data.reward}</td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </>
                                                                        ) : Slot1_Detail_Found === false ? (
                                                                            <>
                                                                                <tr>
                                                                                    <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                                                    <td style={{textAlign: 'center', fontSize: '70%'}}>{"Record Not Found!..."}</td>
                                                                                    <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                                                </tr>
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </>
                                                            ) : Slot1_Detail_Loading === true ? (
                                                                <>
                                                                    <tr>
                                                                        <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                                        <td style={{textAlign: 'center', fontSize: '70%'}}><Grid_Loading_Component/></td>
                                                                        <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                                    </tr>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>
                                    
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export function Slot1_Gen_Detail(props) {

    //const Gen_Tree_Size = '20%';
    //const Gen_Tree_Size = '40px';
    const Gen_Tree_Size = '7.69%';

    const [SlotLoading, SetSlotLoading] = useState();
    const [Slot1_Detail_Loading, SetSlot1_Detail_Loading] = useState();
    const [Slot1_Detail_Found, SetSlot1_Detail_Found] = useState();
    
    const [MID_Color, SetMID_Color] = useState('white');
    const [MID_Title, SetMID_Title] = useState('');

    const [ID1_Color, SetID1_Color] = useState('white');
    const [ID1_Title, SetID1_Title] = useState('');
    
    const [ID2_Color, SetID2_Color] = useState('white');
    const [ID2_Title, SetID2_Title] = useState('');
    
    const [ID3_Color, SetID3_Color] = useState('white');
    const [ID3_Title, SetID3_Title] = useState('');
    
    const [ID4_Color, SetID4_Color] = useState('white');
    const [ID4_Title, SetID4_Title] = useState('');

    const [ID5_Color, SetID5_Color] = useState('white');
    const [ID5_Title, SetID5_Title] = useState('');
    
    const [ID6_Color, SetID6_Color] = useState('white');
    const [ID6_Title, SetID6_Title] = useState('');

    const [Tree_ID, SetTree_ID] = useState([]);
    
    const [Slot1_Gen_Detail, SetSlot1_Gen_Detail] = useState([]);

    useEffect(() => {
        SetTree_ID([]);
        //Package1_Gen_Tree(props.Current_Address, props.Select_Level, SetMID_Color, SetMID_Title, SetID1_Color, SetID1_Title, SetID2_Color, SetID2_Title, SetID3_Color, SetID3_Title, SetID4_Color, SetID4_Title, SetID5_Color, SetID5_Title, SetID6_Color, SetID6_Title, SetTree_ID, SetSlotLoading, SetSlot1_Detail_Loading, SetSlot1_Detail_Found, SetSlot1_Gen_Detail);
    }, [props.Search_Should, props.Select_Level]);

    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col" style={{textAlign: 'center'}}>                                  
                            <div className="row my-1" style={{textAlign: 'center'}}>  
                                <div className="col-xs-4 col-md-3 col-sm-4"></div>
                                <div className="col-xs-4 col-md-6 col-sm-4">
                                    <div className="card text-success mb-4" style={{backgroundColor: 'rgb(198, 198, 198)', width: '100%'}}>
                                        <div className='card-body'>
                                            <div style={{height: '168px'}}>
                                                <div className="row">
                                                    <div className="col-1"></div>
                                                    <div className="col-10 fs-8 fw-semibold" style={{textAlign: 'center',  width: '90%'}}>Package : 10 Core</div>
                                                    <div className="col-1"></div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-1"></div>
                                                    <div className="col-10">
                                                        {
                                                            //----------------
                                                            <div className="card" style={{backgroundColor: 'rgb(161, 160, 160)', height: '100%', width: '100%'}}>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <table style={{width: '100%'}}>
                                                                            <tbody>
                                                                                <tr style={{height: '18px', width: '100%'}}>
                                                                                    <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                    <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                    <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                    <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                    <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                    <td style={{textAlign: 'center', width: '7.69%'}}></td>

                                                                                    <td style={{textAlign: 'center', width: '7.69%'}}><div className="card mx-2" style={{backgroundColor: MID_Color, border: 'green', height: '23px', width: '23px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={MID_Title} onClick={() => {Copy_Add(MID_Title, props.showAlert);}}></div></td>

                                                                                    <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                    <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                    <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                    <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                    <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                    <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                </tr>
                                                                                <tr style={{height: '18px', width: '100%'}}></tr>                    
                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="table-responsive" style={{width: '100%', position: 'relative', height: '50px', overflow: 'auto', overflowY: 'auto'}}>
                                                                            <table style={{width: '100%'}}>
                                                                                <tbody>
                                                                                    {/*<tr>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}><div className="card mx-2" style={{backgroundColor: ID1_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID1_Title} onClick={() => {Copy_Add(ID1_Title, props.showAlert);}}></div></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}><div className="card mx-2" style={{backgroundColor: ID2_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID2_Title} onClick={() => {Copy_Add(ID2_Title, props.showAlert);}}></div></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}><div className="card mx-2" style={{backgroundColor: ID3_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID3_Title} onClick={() => {Copy_Add(ID3_Title, props.showAlert);}}></div></td>
                                                                                        
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>

                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}><div className="card mx-2" style={{backgroundColor: ID4_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID4_Title} onClick={() => {Copy_Add(ID4_Title, props.showAlert);}}></div></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}><div className="card mx-2" style={{backgroundColor: ID5_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID5_Title} onClick={() => {Copy_Add(ID5_Title, props.showAlert);}}></div></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}><div className="card mx-2" style={{backgroundColor: ID6_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID6_Title} onClick={() => {Copy_Add(ID6_Title, props.showAlert);}}></div></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>

                                                                                        {
                                                                                            Tree_ID.map((data) => {
                                                                                                return(
                                                                                                    <>
                                                                                                        {
                                                                                                            
                                                                                                            Number(data.node) > Number(6) ? (
                                                                                                                <>  
                                                                                                                    <td style={{textAlign: 'center', width: '7.69%'}}><div className="card mx-2" style={{backgroundColor: 'green', height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={data.address} onClick={() => {Copy_Add(data.address, props.showAlert);}}></div></td>
                                                                                                                    <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <></>
                                                                                                            )
                                                                                                        }
                                                                                                    </>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </tr>*/}  
                                                                                    <tr>
                                                                                        <td style={{textAlign: 'center', width: Gen_Tree_Size}}></td>
                                                                                        <td style={{textAlign: 'center', width: Gen_Tree_Size}}><div className="card mx-2" style={{backgroundColor: ID1_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID1_Title} onClick={() => {Copy_Add(ID1_Title, props.showAlert);}}></div></td>
                                                                                        <td style={{textAlign: 'center', width: Gen_Tree_Size}}></td>
                                                                                        <td style={{textAlign: 'center', width: Gen_Tree_Size}}><div className="card mx-2" style={{backgroundColor: ID2_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID2_Title} onClick={() => {Copy_Add(ID2_Title, props.showAlert);}}></div></td>
                                                                                        <td style={{textAlign: 'center', width: Gen_Tree_Size}}></td>
                                                                                        <td style={{textAlign: 'center', width: Gen_Tree_Size}}><div className="card mx-2" style={{backgroundColor: ID3_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID3_Title} onClick={() => {Copy_Add(ID3_Title, props.showAlert);}}></div></td>
                                                                                        
                                                                                        <td style={{textAlign: 'center', width: Gen_Tree_Size}}></td>

                                                                                        <td style={{textAlign: 'center', width: Gen_Tree_Size}}><div className="card mx-2" style={{backgroundColor: ID4_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID4_Title} onClick={() => {Copy_Add(ID4_Title, props.showAlert);}}></div></td>
                                                                                        <td style={{textAlign: 'center', width: Gen_Tree_Size}}></td>
                                                                                        <td style={{textAlign: 'center', width: Gen_Tree_Size}}><div className="card mx-2" style={{backgroundColor: ID5_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID5_Title} onClick={() => {Copy_Add(ID5_Title, props.showAlert);}}></div></td>
                                                                                        <td style={{textAlign: 'center', width: Gen_Tree_Size}}></td>
                                                                                        <td style={{textAlign: 'center', width: Gen_Tree_Size}}><div className="card mx-2" style={{backgroundColor: ID6_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID6_Title} onClick={() => {Copy_Add(ID6_Title, props.showAlert);}}></div></td>
                                                                                        <td style={{textAlign: 'center', width: Gen_Tree_Size}}></td>

                                                                                        {
                                                                                            Tree_ID.map((data) => {
                                                                                                return(
                                                                                                    <>
                                                                                                        {
                                                                                                            
                                                                                                            Number(data.node) > Number(6) ? (
                                                                                                                <>  
                                                                                                                    <td style={{textAlign: 'center', width: Gen_Tree_Size}}><div className="card mx-2" style={{backgroundColor: 'green', height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={data.address} onClick={() => {Copy_Add(data.address, props.showAlert);}}></div></td>
                                                                                                                    <td style={{textAlign: 'center', width: Gen_Tree_Size}}></td>
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <></>
                                                                                                            )
                                                                                                        }
                                                                                                    </>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </tr>                                                                                  
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            //----------------
                                                        }

                                                    </div>
                                                    <div className="col-1"></div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-1"></div>
                                                    <div className="col-10">
                                                        
                                                        <table style={{width: '100%'}}>
                                                            <tbody>
                                                                <tr style={{height: '15px'//'25px'
                                                                    }}>
                                                                    <td className='fs-8 fw-bold' style={{textAlign: 'left', width: '30%', fontSize: '90%'}}></td>
                                                                    <td className='fs-8 fw-bold' style={{textAlign: 'center',  width: '15%', fontSize: '90%'}}></td>
                                                                    <td style={{textAlign: 'center',  width: '10%', fontSize: '70%'}}>
                                                                        {SlotLoading}
                                                                    </td>
                                                                    <td className='fs-8 fw-bold' style={{textAlign: 'center',  width: '15%', fontSize: '90%'}}></td>
                                                                    <td className='fs-8 fw-bold' style={{textAlign: 'right', width: '30%', fontSize: '90%'}}></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table style={{width: '100%'}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{width: '50%'}}>
                                                                        <button className='btn'></button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                    <div className="col-1"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-4 col-md-3 col-sm-4"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col" style={{textAlign: 'center'}}>
                            <div className="card" style={{backgroundColor: 'green'}}>
                                <div className="card-body">
                                    
                                    <div className="row my-1">
                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"></div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" style={{textAlign: 'center', fontSize: '90%'}}>
                                            <h5 className='text-white'>Slot1 Gen Level {props.Select_Level} Detail</h5>
                                        </div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"></div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            
                                            <div className="table-responsive" style={{width: '100%', position: 'relative', height: '260px', overflow: 'auto'}}>
                                                                
                                                <table className="table table-striped table-hover border" style={{width: '100%'}}>
                                                    <thead className='sticky-top bg-white' style={{zIndex: '10'}}>
                                                        <tr> 
                                                            <th style={{textAlign: 'center', fontSize: '90%'}}>Sr</th>
                                                            <th style={{textAlign: 'center', fontSize: '90%'}}>ID</th>
                                                            <th style={{textAlign: 'center', fontSize: '90%'}}>Reward</th>
                                                        </tr>
                                                    </thead> 

                                                    <tbody>
                                                        {
                                                            Slot1_Detail_Loading === false ? ( 
                                                                <>
                                                                    {
                                                                        Slot1_Detail_Found === true ? (
                                                                            <>
                                                                                {
                                                                                    Slot1_Gen_Detail.map((data) => {
                                                                                        //console.log(data.sr);
                                                                                        return (
                                                                                            <tr key={(data.id + data.sr + data.reward)}>
                                                                                                <td style={{textAlign: 'center', fontSize: '70%'}}>{data.sr}</td>
                                                                                                <td style={{textAlign: 'center', fontSize: '70%'}}>{data.id}</td>
                                                                                                <td style={{textAlign: 'center', fontSize: '70%'}}>{data.reward}</td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </>
                                                                        ) : Slot1_Detail_Found === false ? (
                                                                            <>
                                                                                <tr>
                                                                                    <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                                                    <td style={{textAlign: 'center', fontSize: '70%'}}>{"Record Not Found!..."}</td>
                                                                                    <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                                                </tr>
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </>
                                                            ) : Slot1_Detail_Loading === true ? (
                                                                <>
                                                                    <tr>
                                                                        <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                                        <td style={{textAlign: 'center', fontSize: '70%'}}><Grid_Loading_Component/></td>
                                                                        <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                                    </tr>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>
                                    
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

//---------Slots_Detail------------

export function Pool_Status_Card_Check(props) {

    return (
        <>
            <div className="card-check">
                {
                    Total_Package_Arr.map((data) => {
                        console.log(data.packno);
                        return(
                            <>
                                <Pool_Status_Card PackNo={Number(data.packno)} Current_Address={props.Current_Address} Click_FixPool_Current_Detail={props.Click_FixPool_Current_Detail}/>
                            </>
                        )
                    }) 
                }
            </div>
        </>
    );
}
function Pool_Status_Card(props) {
    
    const [Is_Img, SetIs_Img] = useState("");
    const [Img_Src, SetImg_Src] = useState("");

    const Check_If = async() => {
        SetImg_Src("");
        SetIs_Img("");
        try {
            let add_exist = await client.readContract({
                address: FixPool[props.PackNo].address,
                abi: FixPool[props.PackNo].abi,
                functionName: "Get_Address_Exist",
                args: [props.Current_Address]
            });

            if (add_exist === true) {
                SetImg_Src(Tick);
                SetIs_Img(true);
            }
            else if (add_exist === false) {
                SetImg_Src(Tick_Blank);
                SetIs_Img(false);
            }
        }
        catch(error) {
            console.log(error);
            SetImg_Src(""); 
            SetIs_Img("");
        }
    }

    useEffect(() => {
        Check_If();
    }, [props.PackNo, props.Current_Address]);
    return (
        <>
            <div className="card-check-gap">
                <span className="slot-n">
                    {
                        Number(props.PackNo) === Number(1) ? (
                            <>Bronze</>
                        ) : Number(props.PackNo) === Number(2) ? (
                            <>Silver</>
                        ) : Number(props.PackNo) === Number(3) ? (
                            <>Gold</>
                        ) : Number(props.PackNo) === Number(4) ? (
                            <>Diamond</>
                        ) : Number(props.PackNo) === Number(5) ? (
                            <>Platinum</>
                        ) : (
                            <></>
                        )
                    }
                </span>
                <span className="slot-type"><img src={Img_Src} alt=""/></span>
                <span className="pool-n">Pool Status</span>
                <span className="pool-check"><button onClick={() => {if (Is_Img === true) {props.Click_FixPool_Current_Detail(props.PackNo)}}}>Check</button></span>                       
            </div>
        </>
    )
}

export async function Click_FixPool_Current_Grid_Detail(Current_Address, PackNo, SetFixPool_Detail, SetFixPool_Detail_Loading) {
    SetFixPool_Detail_Loading(Client_Loading_Component());
    SetFixPool_Detail([]);
    try {

        const Arr = [];

        let User_Address_ID = await client.readContract({
            address: MWMain.address,
            abi: MWMain.abi,
            functionName: "GetUser_Address_ID",
            args: [Current_Address]
        });

        let Img_Arr = [];

        let Upid_Con = [];
        Upid_Con[2] = Number(2);
        Upid_Con[3] = Number(4);
        Upid_Con[4] = Number(6);
        Upid_Con[5] = Number(8);
        Upid_Con[6] = Number(10);
        Upid_Con[7] = Number(12);
        Upid_Con[8] = Number(14);
        Upid_Con[9] = Number(16);
        Upid_Con[10] = Number(18);
        Upid_Con[11] = Number(20);
        Upid_Con[12] = Number(22);
        //Upid_Con[12] = Number(24);

        let activate_now = "ACTIVATE NOW";

        for(let i = 1; i <= Number(12); i++) {
            console.log(Current_Address);
            let Address_Cycle_Exist = await client.readContract({
                address: FixPool[PackNo].address,
                abi: FixPool[PackNo].abi,
                functionName: "Get_Address_Cycle_Exist",
                args: [Current_Address, Number(i)]
            });

            console.log("Cycle No is : " + Number(i) + " : " + Address_Cycle_Exist);

            if (Address_Cycle_Exist === true) {
                Img_Arr[i] = Tick;
            }
            else if (Address_Cycle_Exist === false) {
                let spo_condition = await client.readContract({
                    address: Gen_Data[PackNo].address,
                    abi: Gen_Data[PackNo].abi,
                    functionName: "GetDS_G_UpidNode",
                    args: [Current_Address, Number(1)]
                });
                console.log("i : " + Number(i) + " spo_condition : " + Number(spo_condition) + " Upid_Con : " + Number(Upid_Con[i]));
                if (Number(spo_condition) >= Number(Upid_Con[i])) {
                    Img_Arr[Number(i)] = activate_now;
                    //console.log("Can be Activated!.");
                    //console.log(" i : " + Number(i) + " : " + Img_Arr[i]);
                }
                else if (Number(spo_condition) < Number(Upid_Con[i])) {
                    Img_Arr[Number(i)] = Tick_Blank;
                    //console.log("Can't be Activated!.");
                    //console.log(" i : " + Number(i) + " : " + Img_Arr[i]);
                }
            }
        }

        Arr.push({
            sr: Number(1),
            id: User_Address_ID,

            pool1_is_src: Img_Arr[1],
            pool2_is_src: Img_Arr[2],
            pool3_is_src: Img_Arr[3],
            pool4_is_src: Img_Arr[4],
            pool5_is_src: Img_Arr[5],
            pool6_is_src: Img_Arr[6],
            pool7_is_src: Img_Arr[7],
            pool8_is_src: Img_Arr[8],
            pool9_is_src: Img_Arr[9],
            pool10_is_src: Img_Arr[10],
            pool11_is_src: Img_Arr[11],
            pool12_is_src: Img_Arr[12]
        });

        console.log(Arr);

        SetFixPool_Detail(Arr);
        SetFixPool_Detail_Loading("");
    }
    catch(error) {
        console.log(error);
        SetFixPool_Detail_Loading("");
        SetFixPool_Detail([]);
    }
} 

//-----------------------------------------

export function Dashboard_Detail(props) {

    //const {address} = useAccount();

    //------------Personal Referral :---------------
    const [Personal_Refferal_Detail, SetPersonal_Refferal_Detail] = useState([]);
    const [Personal_Refferal_DetailLoading, SetPersonal_Refferal_DetailLoading] = useState();

    //------------Reward Portfolio :----------------
    const [Total_Reward, SetTotal_Reward] = useState('0.000');
    
    const Dashboard_Personal_Refferal_Detail = async() => {
        SetPersonal_Refferal_DetailLoading(Grid_Loading_Component());
        try {
            let Total_SpoNode = await client.readContract({
                address: MWMain.address,
                abi: MWMain.abi,
                functionName: "GetTotal_SpoNode",
                args: [props.Current_Address],
            });
            console.log("Total_SpoNode is : " + Number(Total_SpoNode));
            
            const Arr = [];
        
            for(let i = 1; i <= Number(Total_SpoNode); i++) {
                let Spo_Detail = await client.readContract({
                    address: MWMain.address,
                    abi: MWMain.abi,
                    functionName: "Get_Spo_Detail",
                    args: [props.Current_Address, i],
                });
            
                let User_Current_Package = await client.readContract({
                    address: MWMain.address,
                    abi: MWMain.abi,
                    functionName: "GetActivated_User_Current_Package",
                    args: [Spo_Detail[1]]
                });
        
                let isactivated;
                if (Spo_Detail[6] === true) {
                    isactivated = 'Activated';
                }
                else if (Spo_Detail[6] === false) {
                    isactivated = 'DeActivated';
                }
        
                Arr.push({
                    sr: Number(i),
                    id: Spo_Detail[2],
                    add: Spo_Detail[1],
                    status: isactivated,
                    package_no: Number(User_Current_Package),
                });  
            }
                
            SetPersonal_Refferal_Detail(Arr);
            SetPersonal_Refferal_DetailLoading("");
        }
        catch (error) {
            SetPersonal_Refferal_DetailLoading("");
        }
    }
    const Total_Reward_Detail = async() => {
        SetTotal_Reward("0.00");
        try {

            let reward = Number(0);

            let SL = Number(0);
            let GS = Number(0);
            let FX = Number(0);

            for(let i = 1; i <= Number(Total_Package); i++) {
                let SL_S_Add_Level_Income = await client.readContract({
                    address: Single_Leg_Transaction[i].address,
                    abi: Single_Leg_Transaction[i].abi,
                    functionName: "GetSL_S_Add_Level_Income",
                    args: [props.Current_Address]
                });
                console.log(SL_S_Add_Level_Income);
                SL += Number(SL_S_Add_Level_Income);
            }
                
            for(let i = 1; i <= Number(Total_Package); i++) {
                let G_S_Add_Income = await client.readContract({
                    address: Single_Leg_Transaction[i].address,
                    abi: Single_Leg_Transaction[i].abi,
                    functionName: "GetG_S_Add_Income",
                    args: [props.Current_Address]
                });
                console.log(G_S_Add_Income);
                GS += Number(G_S_Add_Income);
            }
                
            for(let i = 1; i <= Number(Total_Package); i++) {
                let FixPool_Add_Received_Reward = await client.readContract({
                    address: Fixed_Pool_Transaction[i].address,
                    abi: Fixed_Pool_Transaction[i].abi,
                    functionName: "GetFixPool_Add_Received_Reward",
                    args: [props.Current_Address]
                });
                console.log(FixPool_Add_Received_Reward);
                FX += Number(FixPool_Add_Received_Reward);
            }
            
            reward = BigInt(BigInt(SL) + BigInt(GS) + BigInt(FX));

            if (Symbol === "$") {
                SetTotal_Reward(Format_Usdt(reward));
            }
            else if(Symbol === "CORE") {
                SetTotal_Reward(Format_Core(reward));
            }

        }   
        catch(error) {
            console.log(error);
            SetTotal_Reward("0.00");
        }
    }

    useEffect(() => {
        Dashboard_Personal_Refferal_Detail();
        Total_Reward_Detail();
    }, [props.Search_Should]);


    return (
        <>
            {/*<!--------Dashboard----Section----------------------->*/}               
            <div className="hide-space"></div>
            <div className="back-grid-short">                                 
                <div className="heading">
                    <span>Direct Refferals</span>
                </div>
            </div>
            <div className="back-grid">
                <div className="table scroll-bar">
                    <table>
                        <thead>
                            <td>Sr</td>
                            <td>ID</td>
                            <td>Address</td>
                            <td>Status</td>
                            <td>Slot</td>
                        </thead>
                        <tbody>
                            {
                                Personal_Refferal_DetailLoading === "" ? (
                                    <>
                                        {
                                            Personal_Refferal_Detail.map((data) => {
                                                return (
                                                    <tr>
                                                        <td>{data.sr}</td>
                                                        <td>{data.id}</td>
                                                        <td>{Sub_Variable(data.add)}</td>
                                                        <td>{data.status}</td>
                                                        <td>{data.package_no}</td>
                                                    </tr>   
                                                );
                                            })   
                                        }
                                    </>
                                ) : (
                                    <>  
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>{Personal_Refferal_DetailLoading}</td>
                                            <td></td>
                                            <td></td>
                                        </tr> 
                                    </>
                                )
                            }
                        </tbody>
                    </table>
                </div> 
            </div>
            <div className="back-grid-short">                                 
                <div className="heading">
                    <span>Total Portfolio</span>
                    <div className="value-total">
                        <h3>{Total_Reward}{IsSymbol()}</h3>
                    </div>
                </div>
            </div>
            <div className="back-grid">
                <div className="dash-cards">
                    <div className="card-abc">
                        <div className="total-heading">
                            <span>Globally Rewards</span>
                        </div>
                        {
                            Total_Package_Arr.map((data) => {
                                console.log(data.packno);
                                return(
                                    <>
                                        <Global_Reward_Col Current_Address={props.Current_Address} Search_Should={props.Search_Should} showAlert={props.showAlert} PackNo={Number(data.packno)}/>
                                    </>
                                )
                            }) 
                        }  
                    </div>
                    <div className="card-abc">
                        <div className="total-heading">
                            <span>Level Rewards</span>
                        </div>
                        {
                            Total_Package_Arr.map((data) => {
                                console.log(data.packno);
                                return(
                                    <>
                                        <Level_Reward_Col Current_Address={props.Current_Address} Search_Should={props.Search_Should} showAlert={props.showAlert} PackNo={Number(data.packno)}/>
                                    </>
                                )
                            }) 
                        }  
                    </div>
                    <div className="card-abc">
                        <div className="total-heading">
                            <span>Fix Pool Rewards</span>
                        </div>
                        {
                            Total_Package_Arr.map((data) => {
                                console.log(data.packno);
                                return(
                                    <>
                                        <Pool_Rewards_Col Current_Address={props.Current_Address} Search_Should={props.Search_Should} showAlert={props.showAlert} PackNo={Number(data.packno)}/>
                                    </>
                                )
                            }) 
                        }  
                    </div>
                </div>
            </div>
        </>
    );
}

function Global_Reward_Col(props) {
    const [Global_Reward, SetGlobal_Reward] = useState(0);

    const Global_Reward_Detail = async() => {
        SetGlobal_Reward(Grid_Loading_Component());
        try {
            let SL_S_Add_Level_Income = await client.readContract({
                address: Single_Leg_Transaction[props.PackNo].address,
                abi: Single_Leg_Transaction[props.PackNo].abi,
                functionName: "GetSL_S_Add_Level_Income",
                args: [props.Current_Address]
            });
            console.log(SL_S_Add_Level_Income);

            if (Symbol === "$") {
                SetGlobal_Reward(Format_Usdt(SL_S_Add_Level_Income));
            }
            else if(Symbol === "CORE") {
                SetGlobal_Reward(Format_Core(SL_S_Add_Level_Income));
            }
        }
        catch(error) {
            console.log(error);
            SetGlobal_Reward("0.00");
        }
    }
    useEffect(() => {Global_Reward_Detail()}, [props.Current_Address, props.Search_Should]);

    return (
        <>
            <div className="sub-heading">
                <span>
                    {
                        Number(props.PackNo) === Number(1) ? (
                            <>Bronze</>
                        ) : Number(props.PackNo) === Number(2) ? (
                            <>Silver</>
                        ) : Number(props.PackNo) === Number(3) ? (
                            <>Gold</>
                        ) : Number(props.PackNo) === Number(4) ? (
                            <>Diamond</>
                        ) : Number(props.PackNo) === Number(5) ? (
                            <>Platinum</>
                        ) : (
                            <></>
                        )
                    }
                </span>
            </div>
            <div className="value">
                <span>{Global_Reward}</span>
                <span>{IsSymbol()}</span>
            </div>
        </>
    );
}
function Level_Reward_Col(props) {
    const [Level_Reward, SetLevel_Reward] = useState(0);

    const Level_Reward_Detail = async() => {
        SetLevel_Reward(Client_Loading_Component());
        try {
            let G_S_Add_Income = await client.readContract({
                address: Single_Leg_Transaction[props.PackNo].address,
                abi: Single_Leg_Transaction[props.PackNo].abi,
                functionName: "GetG_S_Add_Income",
                args: [props.Current_Address]
            });
            console.log(G_S_Add_Income);

            if (Symbol === "$") {
                SetLevel_Reward(Format_Usdt(G_S_Add_Income));
            }
            else if(Symbol === "CORE") {
                SetLevel_Reward(Format_Core(G_S_Add_Income));
            }
        }
        catch(error) {
            console.log(error);
            SetLevel_Reward("0.00");
        }
    }
    useEffect(() => {Level_Reward_Detail()}, [props.Current_Address, props.Search_Should]);

    return (
        <>
            <div className="sub-heading">
                <span>
                    {
                        Number(props.PackNo) === Number(1) ? (
                            <>Bronze</>
                        ) : Number(props.PackNo) === Number(2) ? (
                            <>Silver</>
                        ) : Number(props.PackNo) === Number(3) ? (
                            <>Gold</>
                        ) : Number(props.PackNo) === Number(4) ? (
                            <>Diamond</>
                        ) : Number(props.PackNo) === Number(5) ? (
                            <>Platinum</>
                        ) : (
                            <></>
                        )
                    }
            </span>
            </div>
            <div className="value">
                <span>{Level_Reward}</span>
                <span>{IsSymbol()}</span>
            </div>  
        </>
    );
}
function Pool_Rewards_Col(props) {
    const [Pool_Reward, SetPool_Reward] = useState(0);

    const Pool_Reward_Detail = async() => {
        SetPool_Reward(Client_Loading_Component());
        try {
            let FixPool_Add_Received_Reward = await client.readContract({
                address: Fixed_Pool_Transaction[props.PackNo].address,
                abi: Fixed_Pool_Transaction[props.PackNo].abi,
                functionName: "GetFixPool_Add_Received_Reward",
                args: [props.Current_Address]
            });

            console.log(FixPool_Add_Received_Reward);

            if (Symbol === "$") {
                SetPool_Reward(Format_Usdt(FixPool_Add_Received_Reward));
            }
            else if(Symbol === "CORE") {
                SetPool_Reward(Format_Core(FixPool_Add_Received_Reward));
            }
        }
        catch(error) {
            console.log(error);
            SetPool_Reward('0.00');
        }
    }
    useEffect(() => {Pool_Reward_Detail()}, [props.Current_Address, props.Search_Should]);

    return (
        <>
        <div className="sub-heading">
            <span>
                {
                    Number(props.PackNo) === Number(1) ? (
                        <>Bronze</>
                    ) : Number(props.PackNo) === Number(2) ? (
                        <>Silver</>
                    ) : Number(props.PackNo) === Number(3) ? (
                        <>Gold</>
                    ) : Number(props.PackNo) === Number(4) ? (
                        <>Diamond</>
                    ) : Number(props.PackNo) === Number(5) ? (
                        <>Platinum</>
                    ) : (
                        <></>
                    )
                }
            </span>
        </div>
        <div className="value">
            <span>{Pool_Reward}</span>
            <span>{IsSymbol()}</span>
        </div> 
        </>
    );
}

//---------Slots_Detail------------

export function Slots_Detail(props) {

    //const address = "0xd166dF4aA7bCEd83D26455CA0F4C11Ee42370C08";
    
    const {address} = useAccount();

    const [TopUp_Button_Disable, SetTopUp_Button_Disable] = useState();

    const [Pack1_Loading_Complete, SetPack1_Loading_Complete] = useState(false);
    
    const [Should_Slot_Load, SetShould_Slot_Load] = useState(true);

    useEffect(() => {

        if (props.Current_Address === address) {
            SetTopUp_Button_Disable(false);
        }
        else if (props.Current_Address !== address) {
            SetTopUp_Button_Disable(true);
        }

    }, [props.Search_Should, props.Current_Address]);

    return (
        <>
            {/*<!--------slots---Activation---cards----------------- -->*/}
            <div className="back-grid-short">                                 
                <div className="heading">
                    <span>Activation</span>
                </div>
            </div>
            <div className="back-grid">
                <div className="slots-cards">
                    {
                        Total_Package_Arr.map((data) => {
                            console.log(data.packno);
                            return(
                                <>
                                    <Slots Current_Address={props.Current_Address} showAlert={props.showAlert} SetLoadingModal={props.SetLoadingModal} PackNo={Number(data.packno)} TopUp_Button_Disable={TopUp_Button_Disable} Should_Slot_Load={Should_Slot_Load} SetShould_Slot_Load={SetShould_Slot_Load}/>
                                </>
                            )
                        })   
                    }                    
                </div>
            </div>
        </>
    );
}

function Slots(props) {

    const [ShowPack, SetShowPack] = useState(false);
    
    const [MID_Color, SetMID_Color] = useState('white');
    const [MID_Title, SetMID_Title] = useState('');

    const [ID1_Color, SetID1_Color] = useState('white');
    const [ID1_Title, SetID1_Title] = useState('');
    
    const [ID2_Color, SetID2_Color] = useState('white');
    const [ID2_Title, SetID2_Title] = useState('');
    
    const [ID3_Color, SetID3_Color] = useState('white');
    const [ID3_Title, SetID3_Title] = useState('');
    
    const [Tree_ID, SetTree_ID] = useState([]);

    const [Is_SlotActivated, SetIs_SlotActivated] = useState(false);

    const [Remaining_FixPool_Count, SetRemaining_FixPool_Count] = useState(Number(0));
    const [Current_Total_FixPool_Count, SetCurrent_Total_FixPool_Count] = useState(Number(0));
    const [IsFixPool_Remaining, SetIsFixPool_Remaining] = useState(false);

    
    const Check_If = async() => {
        try {
            let User_Current_Package = await client.readContract({
                address: MWMain.address,
                abi: MWMain.abi,
                functionName: "GetActivated_User_Current_Package",
                args: [props.Current_Address]
            });
            if (Number(User_Current_Package) >= Number(Number(props.PackNo))) {
                SetShowPack(true);
                Check_Tree();
                Check_Pool();
            }
            else {
                if (Number(Number(User_Current_Package) + Number(1)) === Number(props.PackNo)) {
                    SetShowPack(true);
                    Check_Tree();
                    Check_Pool();
                }
                else {
                    SetShowPack(false);
                }
            }
            
        }
        catch(error) {
            console.log(error);
        }
    }

    const Check_Tree = async() => {
        SetTree_ID([]);
        try {
            let Address_Exist = await client.readContract({
                address: Single_Leg[props.PackNo].address,
                abi: Single_Leg[props.PackNo].abi,
                functionName: "GetAddress_Exist",
                args: [props.Current_Address]
            });
            SetIs_SlotActivated(Address_Exist);

            if (Address_Exist === true) {
                SetMID_Color('green');
                SetMID_Title(props.Current_Address);

                SetID1_Color('white');
                SetID1_Title('');
                
                SetID2_Color('white');
                SetID2_Title('');
                
                SetID3_Color('white');
                SetID3_Title('');

                let total_upidnode = await client.readContract({
                    address: Gen_Data[props.PackNo].address,
                    abi: Gen_Data[props.PackNo].abi,
                    functionName: "GetDS_G_UpidNode",
                    args: [props.Current_Address, Number(1)]
                });

                console.log("total_upidnode is : " + Number(total_upidnode));
                
                let arr = [];
                for(let i = 1; i <= Number(total_upidnode); i++) {
                    let upidnode_add = await client.readContract({
                        address: Gen_Data[props.PackNo].address,
                        abi: Gen_Data[props.PackNo].abi,
                        functionName: "GetDS_G_Add_Through_UpidNode",
                        args: [props.Current_Address, Number(1), i]
                    });

                    console.log("upidnode_add Lvl : 1 And " + i + " is : " + upidnode_add);

                    if (Number(i) == Number(1)) {
                        SetID1_Color('green');
                        SetID1_Title(upidnode_add);
                    }
                    else if (Number(i) == Number(2)) {
                        SetID2_Color('green');
                        SetID2_Title(upidnode_add);
                    }
                    else if (Number(i) == Number(3)) {
                        SetID3_Color('green');
                        SetID3_Title(upidnode_add);
                    }

                    arr.push({
                        address: upidnode_add,
                        node: Number(i)
                    });
                }

                console.log(arr);

                SetTree_ID(arr);

            }
            else if (Address_Exist === false) {
                SetMID_Color('white');
                SetMID_Title("");
                
                SetID1_Color('white');
                SetID1_Title('');
                
                SetID2_Color('white');
                SetID2_Title('');
                
                SetID3_Color('white');
                SetID3_Title('');
                
                SetTree_ID([]);
            }
        }
        catch(error) {
            console.log(error);
            SetTree_ID([]);
        }
    }
    const Check_Pool = async() => {
        try {
            let functionName_;
            if (Number(props.PackNo) === Number(1)) {
                functionName_ = "GetFixPool1_Add_Count";
            }
            else if (Number(props.PackNo) === Number(2)) {
                functionName_ = "GetFixPool2_Add_Count";
            }
            else if (Number(props.PackNo) === Number(3)) {
                functionName_ = "GetFixPool3_Add_Count";
            }
            else if (Number(props.PackNo) === Number(4)) {
                functionName_ = "GetFixPool4_Add_Count";
            }
            else if (Number(props.PackNo) === Number(5)) {
                functionName_ = "GetFixPool5_Add_Count";
            }

            let FixPool_Add_Count = await client.readContract({
                address: FixPool[props.PackNo].address,
                abi: FixPool[props.PackNo].abi,
                functionName: functionName_,
                args: [props.Current_Address]
            });
            let Total_Address_Count = await client.readContract({
                address: FixPool[props.PackNo].address,
                abi: FixPool[props.PackNo].abi,
                functionName: "Get_Total_Address_Count",
                args: [props.Current_Address]
            })
            console.log(Number(FixPool_Add_Count));
            console.log(Number(Total_Address_Count));

            SetRemaining_FixPool_Count(Number(FixPool_Add_Count));
            SetCurrent_Total_FixPool_Count(Number(Total_Address_Count));

            if (Number(FixPool_Add_Count) > Number(0)) {
                SetIsFixPool_Remaining(true);
            }
            else if (Number(FixPool_Add_Count) === Number(0)) {
                SetIsFixPool_Remaining(false);
            }
        }
        catch(error) {
            console.log(error);
        }
    }

    const Slot_Activate = async() => {
        try {
            //console.log(parseEther(Core_amount.toString()));

            props.SetLoadingModal(true, true);
            if (window.ethereum) {
                // Delcalre a Wallet Client
                const walletClient = createWalletClient({
                    account: props.Current_Address,
                    chain: p_chain,
                    transport: custom(window.ethereum)
                });

                //console.log("WalletClient");
                //console.log(walletClient);

                let request_writeContract;

                /*if(Symbol === "$") {
                    
                    let Usdt_Amount;
                    if (Number(props.PackNo) === Number(1)) {
                        Usdt_Amount = 10000000;
                    }
                    else if (Number(props.PackNo) === Number(2)) {
                        Usdt_Amount = 20000000;
                    }
                    else if (Number(props.PackNo) === Number(3)) {
                        Usdt_Amount = 40000000;
                    }
                    else if (Number(props.PackNo) === Number(4)) {
                        Usdt_Amount = 80000000;
                    }
                    else if (Number(props.PackNo) === Number(5)) {
                        Usdt_Amount = 120000000;
                    }
                    
                    props.SetLoadingModal(true, false);
                    let request_approve_writeContract = await walletClient.writeContract({
                        address: USDT.address,
                        abi: USDT.abi,
                        functionName: "approve",
                        args: [Single_Leg[props.PackNo].address, Usdt_Amount],
                    });
                    props.SetLoadingModal(true, true);

                    console.log(request_approve_writeContract);
                    const approve_transaction = await client.waitForTransactionReceipt({confirmations: 5, hash: request_approve_writeContract});

                    props.SetLoadingModal(true, false);
                    request_writeContract = await walletClient.writeContract({
                        address: Single_Leg[props.PackNo].address,
                        abi: Single_Leg[props.PackNo].abi,
                        functionName: "New_ID",
                        args: [props.Current_Address],
                    });
                    props.SetLoadingModal(true, true);
                }
                else if (Symbol === "CORE") {*/
                
                    let Core_Amount; //----10 CORE   //---18-Decimals---- Means 1 CORE

                    if (Number(props.PackNo) === Number(1)) {
                        //Core_Amount = 100000000000000000;   //--0.10---
                        Core_Amount = 10000000000000000000; //--10---
                    }
                    else if (Number(props.PackNo) === Number(2)) {
                        //Core_Amount = 200000000000000000;   //--0.20---
                        Core_Amount = 20000000000000000000; //--20---
                    }
                    else if (Number(props.PackNo) === Number(3)) {
                        //Core_Amount = 400000000000000000;   //--0.40---
                        Core_Amount = 40000000000000000000; //--40---
                    }
                    else if (Number(props.PackNo) === Number(4)) {
                        //Core_Amount = 800000000000000000;   //--0.80---
                        Core_Amount = 80000000000000000000; //--80---
                    }
                    else if (Number(props.PackNo) === Number(5)) {
                        //Core_Amount = 1600000000000000000;   //--1.60---
                        Core_Amount = 160000000000000000000; //--160---
                    }


                    props.SetLoadingModal(true, false);
                    request_writeContract = await walletClient.writeContract({
                        address: Single_Leg[props.PackNo].address,
                        abi: Single_Leg[props.PackNo].abi,
                        functionName: "New_ID",
                        args: [props.Current_Address],
                        value: BigInt(Core_Amount)
                    });
                    props.SetLoadingModal(true, true);
                //}
 
                console.log(request_writeContract);
                
                const transaction = await client.waitForTransactionReceipt({confirmations: 5, hash: request_writeContract});
                console.log("Transaction is : ");
                console.log(transaction);
                if (transaction.status === "success") {
                    props.SetLoadingModal(false, false);
                    props.showAlert("Package " + props.PackNo + " SuccessFully Activated !...", "success", true);
                    setTimeout(() => {
                        Check_Tree();
                        Check_Pool();
                        if (props.Should_Slot_Load === true) {
                            props.SetShould_Slot_Load(false);
                        }
                        else if (props.Should_Slot_Load === false) {
                            props.SetShould_Slot_Load(true);
                        }
                    }, [4000]);
                }
                else {
                    props.SetLoadingModal(false, false);
                    props.showAlert("Your Transaction Is Reverted", "danger", true);
                }
            }
            else {
                props.SetLoadingModal(false, false);
                props.showAlert("Please SignUp Through DAPPS!...", "warning", false);
            }
        }
        catch(error) {
            console.log(error);
            props.SetLoadingModal(false, false);
        }
    }
    const FixPool_Activate = async() => {
        if (Number(Remaining_FixPool_Count) > Number(0)) {

            let functionName_;
            let Pack_Title_;
            if (Number(props.PackNo) === Number(1)) {
                functionName_ = "FixPool1_Activate";
                Pack_Title_ = "Bronze";
            }
            else if (Number(props.PackNo) === Number(2)) {
                functionName_ = "FixPool2_Activate";
                Pack_Title_ = "Sliver";
            }
            else if (Number(props.PackNo) === Number(3)) {
                functionName_ = "FixPool3_Activate";
                Pack_Title_ = "Gold";
            }
            else if (Number(props.PackNo) === Number(4)) {
                functionName_ = "FixPool4_Activate";
                Pack_Title_ = "Diamond";
            }
            else if (Number(props.PackNo) === Number(5)) {
                functionName_ = "FixPool5_Activate";
                Pack_Title_ = "Platinum";
            }


            
            try {
                props.SetLoadingModal(true, true);
                if (window.ethereum) {
                    // Delcalre a Wallet Client
                    const walletClient = createWalletClient({
                        account: props.Current_Address,
                        chain: p_chain,
                        transport: custom(window.ethereum)
                    });

                    //console.log("WalletClient");
                    //console.log(walletClient);

                    props.SetLoadingModal(true, false);
                    let request_writeContract = await walletClient.writeContract({
                      address: FixPool[props.PackNo].address,
                      abi: FixPool[props.PackNo].abi,
                      functionName: functionName_,
                      args: [props.Current_Address]
                    });
                    props.SetLoadingModal(true, true);
                    //console.log(request_writeContract);
                    
                    const transaction = await client.waitForTransactionReceipt({confirmations: 5, hash: request_writeContract});
                    //console.log("Transaction is : ");
                    //console.log(transaction);
                    if (transaction.status === "success") {
                        props.SetLoadingModal(false, false);
                        props.showAlert("Pack_Title_ FixPool SuccessFully Activated!...", "success", true);
                        setTimeout(() => {
                            Check_Tree();
                            Check_Pool();
                        }, [4000]);
                    }
                    else {
                        props.SetLoadingModal(false, false);
                        props.showAlert("Your Transaction Is Reverted", "danger", true);
                    }
                
                }
                else {
                    props.SetLoadingModal(false, false);
                    props.showAlert("Please SignUp Through DAPPS!...", "warning", false);
                }
            }
            catch(error) {
                //console.log("Error is : ");
                console.log(error);
                props.SetLoadingModal(false, false);
            }

            //Check_Pool();
            
        }
        else if (Number(Remaining_FixPool_Count) === Number(0)) {

        }
    }

    useEffect(() => {
        Check_If();
    }, [props.Current_Address, props.Search_Should, props.Should_Slot_Load]);

    return (
        <>
            <div className="card-slots">
                <div className="activation-tree-outer-box">
                    <div className="title-tree-head-box">
                        <h4>
                            {
                                Number(props.PackNo) === Number(1) ? (
                                    <>Bronze</>
                                ) : Number(props.PackNo) === Number(2) ? (
                                    <>Silver</>
                                ) : Number(props.PackNo) === Number(3) ? (
                                    <>Gold</>
                                ) : Number(props.PackNo) === Number(4) ? (
                                    <>Diamond</>
                                ) : Number(props.PackNo) === Number(5) ? (
                                    <>Platinum</>
                                ) : (
                                    <></>
                                )
                            }
                        </h4>
                    </div>
                    <div className="tree-head-box">
                        <h3>{
                                Number(props.PackNo) === Number(1) ? (
                                    <>10</>
                                ) : Number(props.PackNo) === Number(2) ? (
                                    <>20</>
                                ) : Number(props.PackNo) === Number(3) ? (
                                    <>40</>
                                ) : Number(props.PackNo) === Number(4) ? (
                                    <>80</>
                                ) : Number(props.PackNo) === Number(5) ? (
                                    <>160</>
                                ) : (
                                    <></>
                                )
                            }{IsSymbol()}</h3>
                    </div>
                    <section>
                        <div className="tree-main-box">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><div className="main-id" style={{backgroundColor: MID_Color}} onClick={() => {Copy_Add(MID_Title, props.showAlert)}}></div></td>
                                    </tr>              
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <section>
                        <div className="tree-team-box scroll-bar">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><div className="team-id" style={{backgroundColor: ID1_Color}} onClick={() => {Copy_Add(ID1_Title, props.showAlert)}}></div></td>
                                        <td><div className="team-id" style={{backgroundColor: ID2_Color}} onClick={() => {Copy_Add(ID2_Title, props.showAlert)}}></div></td>
                                        <td><div className="team-id" style={{backgroundColor: ID3_Color}} onClick={() => {Copy_Add(ID3_Title, props.showAlert)}}></div></td>
                                        {
                                            Tree_ID.map((data) => {
                                                return(
                                                    <>
                                                        {
                                                            
                                                            Number(data.node) > Number(3) ? (
                                                                <> 
                                                                    <td><div className="team-id" style={{backgroundColor: 'green'}} onClick={() => {Copy_Add(data.address, props.showAlert)}}></div></td>        
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                    </>
                                                );
                                            })
                                        }            
                                    </tr>           
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <div className="activation-box">
                        {
                            Is_SlotActivated === false ? (
                                <>
                                    {
                                        props.TopUp_Button_Disable === false ? (
                                            <>
                                                {
                                                    ShowPack === true ? (
                                                        <button className="activation" onClick={() => {Slot_Activate()}}>
                                                            <div>Activate</div>
                                                        </button>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </>
                            ) : Is_SlotActivated === true ? (
                                <></>
                            ) : (
                                <></>
                            )
                        }
                    </div>
                    <div className="pool-head-box">
                        <h4>Pool Activation</h4>
                    </div> 
                    <div className="pool-left-box">
                        <h4>Left {Remaining_FixPool_Count} | {Current_Total_FixPool_Count} / 12</h4>
                    </div> 
                    <div className="activation-box">
                        {
                            IsFixPool_Remaining === true ? (
                                <>
                                    <button className="activation" onClick={() => {FixPool_Activate()}}>
                                        <div>Activate</div>
                                    </button>
                                </>
                            ) : IsFixPool_Remaining === false ? (
                                <></>
                            ) : (
                                <></>
                            )
                        }
                    </div>
                </div>
            </div> 
        </>
    );
}

//---------Global_Detail------------

export function Global_Detail(props) {
    
    const [Select_PackNo, SetSelect_PackNo] = useState(Number(1));
    const [Global_Grid_Reward_Loading, SetGlobal_Grid_Reward_Loading] = useState("");
    const [Global_Grid_Reward, SetGlobal_Grid_Reward] = useState([]);
    const [Does_Exist, SetDoes_Exist] = useState(false);

    const Global_Grid_Reward_Detail = async() => {
        SetGlobal_Grid_Reward_Loading(Client_Loading_Component());
        try {
            if(Number(Select_PackNo) === Number(0)) {
                SetGlobal_Grid_Reward_Loading("");
            }
            else if (Number(Select_PackNo) > Number(0)) {

                let Address_Exist = await client.readContract({
                    address: Single_Leg[Number(Select_PackNo)].address,
                    abi: Single_Leg[Number(Select_PackNo)].abi,
                    functionName: 'GetAddress_Exist',
                    args: [props.Current_Address]
                });

                if (Address_Exist === true) {
                   const Arr = [];
                    for(let i = 1; i <= Number(5); i++) {
                        let SL_S_UpidLvl = await client.readContract({
                            address: Single_Leg_Transaction[Number(Select_PackNo)].address,
                            abi: Single_Leg_Transaction[Number(Select_PackNo)].abi,
                            functionName: "GetSL_S_UpidLvl",
                            args: [props.Current_Address, Number(i)]
                        });

                        console.log(SL_S_UpidLvl);

                        let id_ = await client.readContract({
                            address: MWMain.address,
                            abi: MWMain.abi,
                            functionName: "GetUser_Address_ID",
                            args: [props.Current_Address]
                        });

                        let received_;
                        if(Symbol === "$") {
                            received_= Format_Usdt(SL_S_UpidLvl[4]);
                        }
                        else if (Symbol === "CORE") {
                            received_ = Format_Core(SL_S_UpidLvl[4]);
                        }

                        Arr.push({
                            sr: Number(i),
                            id: id_,
                            lvl: Number(SL_S_UpidLvl[2]),
                            add: SL_S_UpidLvl[3],
                            received: received_
                        });
                        
                    }

                    console.log(Arr);

                    SetDoes_Exist(true);
                    SetGlobal_Grid_Reward(Arr);
                    SetGlobal_Grid_Reward_Loading("");
                }
                else if (Address_Exist === false) {
                    SetDoes_Exist(false);
                    SetGlobal_Grid_Reward([]);
                    SetGlobal_Grid_Reward_Loading("");
                }
            }   
        }   
        catch(error) {
            console.log(error);
            SetGlobal_Grid_Reward([]);
            SetGlobal_Grid_Reward_Loading("");
        }
    }
    
    useEffect(() => {
        Global_Grid_Reward_Detail();
    }, [props.Current_Address, props.Search_Should, Select_PackNo]);

    /*return (
        <>
            <div className="card" style={{backgroundColor: "#4504af"}}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-3 col-md-4 col-lg-4"></div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="card text-white" style={{backgroundColor: 'green'}}>
                                <div className='card-wrapper'>
                                    <div className="row">
                                        <div className="col my-2 mx-1 fw-bold" style={{textAlign: 'center', fontSize: '120%'}}>Slots</div>                                                                           
                                    </div>              
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-4 col-lg-4"></div>
                    </div>

                    <br />

                    <Slot1_SingleLeg_Detail Current_Address={props.Current_Address} Search_Should={props.Search_Should} showAlert={props.showAlert}/>
                    
                </div>
            </div>
        </>
    );*/
    return (
        <>
            {/*<!--------Global---Rewards---Section-------------------->*/}

            <div className="back-grid-short">                                 
                <div className="heading">
                    <span>Globally Portfolio</span>
                </div>
            </div>
            <div className="back-grid">
                <div className="heading">
                    <span>Globally Rewards</span>
                </div>
                <div className="select-box">
                    <select id="global_select_pack" onChange={() => {
                            let global_select_pack = document.getElementById("global_select_pack").value;
                            SetSelect_PackNo(Number(global_select_pack));
                        }}>
                        <option value="0">All Slots</option>
                        {
                            Number(Total_Package) >= Number(1) ? (
                                <>
                                    <option value="1">From Bronze Slot</option>
                                </>
                            ) : ( 
                                <></>
                            )
                        }
                        {
                            Number(Total_Package) >= Number(2) ? (
                                <>
                                    <option value="2">From Silver Slot</option>
                                </>
                            ) : ( 
                                <></>
                            )
                        }
                        {
                            Number(Total_Package) >= Number(3) ? (
                                <>
                                    <option value="3">From Gold slot</option>
                                </>
                            ) : ( 
                                <></>
                            )
                        }
                        {
                            Number(Total_Package) >= Number(4) ? (
                                <>
                                    <option value="4">From Diamond Slot</option>
                                </>
                            ) : ( 
                                <></>
                            )
                        }
                        {
                            Number(Total_Package) >= Number(5) ? (
                                <>
                                    <option value="5">From Platinum Slot</option>
                                </>
                            ) : ( 
                                <></>
                            )
                        }
                    </select>                          
                </div>
                <div className="hide-space"></div>
                <div className="table scroll-bar">
                    <table>
                        <thead>
                            <td>Sr</td>
                            <td>ID</td>
                            <td>Level</td>
                            <td>Address</td>
                            <td>Received</td>
                        </thead>
                        <tbody>
                            {
                                Global_Grid_Reward_Loading === "" ? (
                                    <> 
                                        {   
                                            Does_Exist === true ? (
                                                Global_Grid_Reward.map((data) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{data.sr}</td>
                                                                <td>{data.id}</td>
                                                                <td>{data.lvl}</td>
                                                                <td>{data.add}</td>
                                                                <td>{data.received}{IsSymbol()}</td>
                                                            </tr>
                                                        </>
                                                    );
                                                })
                                            ) : (
                                                <>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td style={{color: 'red'}}>Isn't Activated</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </>
                                            )
                                        }
                                    </>
                                ) : (
                                    <>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>{Global_Grid_Reward_Loading}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

//---------Level_Grid_Detail------------

export function Level_Reward_Detail(props) {

    const [Select_PackNo, SetSelect_PackNo] = useState(Number(1));
    const [Select_Level, SetSelect_Level] = useState(Number(0));

    const [Total_Detail_Grid_Loading, SetTotal_Detail_Grid_Loading] = useState("");
    const [Total_Level_Detail_Grid_Loading, SetTotal_Level_Detail_Grid_Loading] = useState("");
    const [Single_Level_Detail_Grid_Loading, SetSingle_Level_Detail_Grid_Loading] = useState("");

    const [Total_Detail, SetTotal_Detail] = useState([]);
    const [Total_Level_Detail, SetTotal_Level_Detail] = useState([]);
    const [Single_Level_Detail, SetSingle_Level_Detail] = useState([]);

    const Select_Total_Detail = async() => {
        SetTotal_Detail_Grid_Loading(Client_Loading_Component());
        try {
            const Arr = [];

            let direct_id = await client.readContract({
                address: Single_Leg_Transaction[Select_PackNo].address,
                abi: Single_Leg_Transaction[Select_PackNo].abi,
                functionName: "GetG_S_Upid_Lvl_No",
                args: [props.Current_Address, Number(1)]
            });

            let G_S_Add_Income = await client.readContract({
                address: Single_Leg_Transaction[Select_PackNo].address,
                abi: Single_Leg_Transaction[Select_PackNo].abi,
                functionName: "GetG_S_Add_Income",
                args: [props.Current_Address]
            });

            let Add_G_Total_Lvl_Wallet_Claimed = await client.readContract({
                address: Single_Leg[Select_PackNo].address,
                abi: Single_Leg[Select_PackNo].abi,
                functionName: "GetAdd_G_Total_Lvl_Wallet_Claimed",
                args: [props.Current_Address]
            });

            let Activated_User_Current_Package = await client.readContract({
                address: MWMain.address,
                abi: MWMain.abi,
                functionName: "GetActivated_User_Current_Package",
                args: [props.Current_Address]
            })

            let total_pending___ = Number(BigInt(G_S_Add_Income) - BigInt(Add_G_Total_Lvl_Wallet_Claimed));

            let total_received_;
            let total_claimed_;
            let total_pending_;

            if(Symbol === "$") {
                total_received_ = Format_Usdt(G_S_Add_Income);
                total_claimed_ = Format_Usdt(Add_G_Total_Lvl_Wallet_Claimed);
                total_pending_ = Format_Usdt(total_pending___);
            }
            else if (Symbol === "CORE") {
                total_received_ = Format_Core(G_S_Add_Income);
                total_claimed_ = Format_Core(Add_G_Total_Lvl_Wallet_Claimed);
                total_pending_ = Format_Core(total_pending___);
            }



            let isActivated;
            let isAct_Color;
            if (Number(Activated_User_Current_Package) >= Number(Select_PackNo)) {
                isActivated = "ACTIVATED";
                isAct_Color = "green";
            }
            else {
                isActivated = "DEACTIVATED";
                isAct_Color = "red";
            }

            Arr.push({
                sr: Number(1),
                direct_id: Number(direct_id),
                total_received: Number(total_received_),
                total_pending: Number(total_pending_),
                total_claimed: Number(total_claimed_),
                status: isActivated,
                status_color: isAct_Color
            });

            SetTotal_Detail(Arr);
            SetTotal_Detail_Grid_Loading("");
        }
        catch(error) {
            console.log(error);
            SetTotal_Detail([]);
            SetTotal_Detail_Grid_Loading("");
        }
    }

    const Select_Total_Level_Detail = async() => {
        SetTotal_Level_Detail_Grid_Loading(Client_Loading_Component());
        try {
            const Arr_Detail = [];
            for(let i = 1; i <= Number(10); i++) {
                let G_S_Upid_Lvl_No = await client.readContract({
                    address: Single_Leg_Transaction[Select_PackNo].address,
                    abi: Single_Leg_Transaction[Select_PackNo].abi,
                    functionName: "GetG_S_Upid_Lvl_No",
                    args: [props.Current_Address, Number(i)]
                });

                let G_S_Upid_Lvl_Income = await client.readContract({
                    address: Single_Leg_Transaction[Select_PackNo].address,
                    abi: Single_Leg_Transaction[Select_PackNo].abi,
                    functionName: "GetG_S_Upid_Lvl_Income",
                    args: [props.Current_Address, Number(i)]
                });

                let Add_G_Lvl_Wallet_And_Claimed = await client.readContract({
                    address: Single_Leg[Select_PackNo].address,
                    abi: Single_Leg[Select_PackNo].abi,
                    functionName: "GetAdd_G_Lvl_Wallet_And_Claimed",
                    args: [props.Current_Address, Number(i)]
                });

                console.log("args: [" +props.Current_Address + ", " + Number(i) + "]");
                console.log("Add_G_Lvl_Wallet_And_Claimed");
                console.log(Add_G_Lvl_Wallet_And_Claimed);


                let pending__ = BigInt(Add_G_Lvl_Wallet_And_Claimed[0]);//
                //let pending__ = Number(BigInt(G_S_Upid_Lvl_Income) - BigInt(Add_G_Lvl_Wallet_And_Claimed[1]));
                
                let received_;
                let claimed_;
                let pending_;

                let claim_btn_show_;

                //---also check if the current address is his personal address

                if (Number(pending__) === Number(0)) {
                    claim_btn_show_ = false;
                }
                else if (Number(pending__) > Number(0)) {
                    if (Number(i) === Number(1)) {
                        claim_btn_show_ = false;
                    }
                    else if (Number(i) > Number(1)) {
                        claim_btn_show_ = true;
                    }
                }

                if(Symbol === "$") {
                    received_ = Format_Usdt(G_S_Upid_Lvl_Income);
                    claimed_ = Format_Usdt(Add_G_Lvl_Wallet_And_Claimed[1]);
                    pending_ = Format_Usdt(pending__);
                }
                else if (Symbol === "CORE") {
                    received_ = Format_Core(G_S_Upid_Lvl_Income);
                    claimed_ = Format_Core(Add_G_Lvl_Wallet_And_Claimed[1]);
                    pending_ = Format_Core(pending__);
                }
                Arr_Detail.push({
                    sr: Number(i),
                    total_id: Number(G_S_Upid_Lvl_No),
                    received: received_,
                    pending: pending_,
                    claimed: claimed_,
                    lvl: Number(i),
                    claim_btn_show: claim_btn_show_
                });
            }
            SetTotal_Level_Detail(Arr_Detail);
            SetTotal_Level_Detail_Grid_Loading("");
        }
        catch(error) {
            console.log(error);
            SetTotal_Level_Detail([]);
            SetTotal_Level_Detail_Grid_Loading("");
        }
    }
    const Select_Single_Level_Detail = async() => {
        SetSingle_Level_Detail_Grid_Loading(Client_Loading_Component());
        try {
            let Upid_Lvl_No = await client.readContract({
                address: Single_Leg_Transaction[Select_PackNo].address, 
                abi: Single_Leg_Transaction[Select_PackNo].abi,
                functionName: "GetG_S_Upid_Lvl_No",
                args: [props.Current_Address, Select_Level]
            });
            console.log("Upid_Lvl_No is : " + Number(Upid_Lvl_No));

            const Arr_Detail = [];

            for(let i = 1; i <= Number(Upid_Lvl_No); i++) {
                const GetUpidNode = await client.readContract({
                    address: Single_Leg_Transaction[Select_PackNo].address,
                    abi: Single_Leg_Transaction[Select_PackNo].abi,
                    functionName: "GetG_S_UpidNode",
                    args: [props.Current_Address, Select_Level, i]
                });

                let id_ = await client.readContract({
                    address:  MWMain.address,
                    abi: MWMain.abi,
                    functionName: "GetUser_Address_ID",
                    args: [GetUpidNode[4]],
                });

                let reward_;
                if (Symbol === "$") {
                    reward_ = Format_Usdt(GetUpidNode[5]);
                }
                else if (Symbol === "CORE") {
                    reward_ = Format_Core(GetUpidNode[5])
                }

                Arr_Detail.push({
                    sr: Number(i),
                    id: id_,
                    reward: reward_
                })
            }

            console.log(Arr_Detail);
            SetSingle_Level_Detail(Arr_Detail);
            SetSingle_Level_Detail_Grid_Loading("");
        }
        catch(error) {
            console.log(error);
            SetSingle_Level_Detail([]);
            SetSingle_Level_Detail_Grid_Loading("");
        }
    }

    const Claim_Lvl = async(lvl) => {
        try {
            props.SetLoadingModal(true, true);
            if (window.ethereum) {
                // Delcalre a Wallet Client
                const walletClient = createWalletClient({
                    account: props.Current_Address,
                    chain: p_chain,
                    transport: custom(window.ethereum)
                });

                //console.log("WalletClient");
                //console.log(walletClient);


                props.SetLoadingModal(true, false);
                let request_writeContract = await walletClient.writeContract({
                    address: Single_Leg[Select_PackNo].address,
                    abi: Single_Leg[Select_PackNo].abi,
                    functionName: "Add_G_Lvl_Wallet_Claim",
                    args: [props.Current_Address, lvl]
                });
                props.SetLoadingModal(true, true);
                console.log(request_writeContract);
                
                const transaction = await client.waitForTransactionReceipt({confirmations: 5, hash: request_writeContract});
                console.log("Transaction is : ");
                console.log(transaction);
                if (transaction.status === "success") {
                    props.SetLoadingModal(false, false);
                    props.showAlert("Level " + lvl + " SuccessFully Claimed !...", "success", true);
                    setTimeout(() => {
                        Select_Total_Detail();
                        if(Select_Level === Number(0)) {
                            Select_Total_Level_Detail();
                        }
                        else if (Select_Level > Number(0)) {
                            Select_Single_Level_Detail();
                        }
                    }, [4000]);
                }
                else {
                    props.SetLoadingModal(false, false);
                    props.showAlert("Your Transaction Is Reverted", "danger", true);
                }
                
            }
            else {
                props.SetLoadingModal(false, false);
                props.showAlert("Please SignUp Through DAPPS!...", "warning", false);
            }
        }
        catch(error) {
            console.log(error);
            props.SetLoadingModal(false, false);
        }
    }

    useEffect(() => {
        Select_Total_Detail();
        if(Select_Level === Number(0)) {
            Select_Total_Level_Detail();
        }
        else if (Select_Level > Number(0)) {
            Select_Single_Level_Detail();
        }
    }, [props.Current_Address, props.Search_Should, Select_PackNo]);

    useEffect(() => {
        if(Select_Level === Number(0)) {
            Select_Total_Level_Detail();
        }
        else if (Select_Level > Number(0)) {
            Select_Single_Level_Detail();
        }
    }, [props.Current_Address, props.Search_Should, Select_PackNo, Select_Level]);

    /*return (
        <>
            <div className="card" style={{backgroundColor: "#4504af"}}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-3 col-md-4 col-lg-4"></div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="card text-white" style={{backgroundColor: 'green'}}>
                                <div className='card-wrapper'>
                                    <div className="row">
                                        <div className="col my-2 mx-1 fw-bold" style={{textAlign: 'center', fontSize: '120%'}}>Gen</div>                                                                           
                                    </div>              
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-4 col-lg-4">
                            <div className="card text-white" style={{backgroundColor: 'yellow'}}>
                                <div className='card-wrapper'>
                                    <div className="row">
                                        <div className="col-2" style={{textAlign: 'center'}}></div>
                                        <div className="col-8" style={{textAlign: 'center'}}>
                                            <div className="btn-group my-2">
                                                <button className='fw-bold' type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style={{width: '70%', fontSize: '70%', backgroundColor: 'green', color: 'white'}}>
                                                    Select Level
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => {SetSelect_Level(Number(1))}}>Level 1</a></li>
                                                    <li><a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => {SetSelect_Level(Number(2))}}>Level 2</a></li>
                                                    <li><a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => {SetSelect_Level(Number(3))}}>level 3</a></li>
                                                    <li><a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => {SetSelect_Level(Number(4))}}>level 4</a></li>
                                                </ul>
                                            </div>
                                        </div>                                                                           
                                        <div className="col-2" style={{textAlign: 'center'}}></div>                                                          
                                    </div>              
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />
                   
                    <Slot1_Gen_Detail Current_Address={props.Current_Address} Select_Level={Select_Level} Search_Should={props.Search_Should} showAlert={props.showAlert}/>
                    
                    
                </div>
            </div>
        </>
    );*/
    
    return (
        <>
            {/*<!-------Level---Rewards---Section---------------->*/}
            <div className="back-grid-short">                                 
                <div className="heading">
                    <span>Level Portfolio</span>
                </div>
            </div>
            <div className="back-grid">
                <div className="heading">
                    <span>Claim Level Rewards</span>
                </div>

                {/*<!---Slot--select -->*/}
                <div className="select-box">
                    <select id="level_select_pack" onChange={() => {
                            let level_select_pack = document.getElementById("level_select_pack").value;
                            SetSelect_PackNo(Number(level_select_pack));
                        }}>
                        <option value="0">All Slots</option>
                        {
                            Number(Total_Package) >= Number(1) ? (
                                <>
                                    <option value="1">From Bronze Slot</option>
                                </>
                            ) : ( 
                                <></>
                            )
                        }
                        {
                            Number(Total_Package) >= Number(2) ? (
                                <>
                                    <option value="2">From Silver Slot</option>
                                </>
                            ) : ( 
                                <></>
                            )
                        }
                        {
                            Number(Total_Package) >= Number(3) ? (
                                <>
                                    <option value="3">From Gold slot</option>
                                </>
                            ) : ( 
                                <></>
                            )
                        }
                        {
                            Number(Total_Package) >= Number(4) ? (
                                <>
                                    <option value="4">From Diamond Slot</option>
                                </>
                            ) : ( 
                                <></>
                            )
                        }
                        {
                            Number(Total_Package) >= Number(5) ? (
                                <>
                                    <option value="5">From Platinum Slot</option>
                                </>
                            ) : ( 
                                <></>
                            )
                        }
                    </select>                          
                </div>

                {/*<!---Level--select -->*/}
                <div className="select-box">
                    <select id="level_select" onChange={() => {
                            let level_select = document.getElementById("level_select").value;
                            SetSelect_Level(Number(level_select));
                        }}>
                            <option value="0">Total Level</option>
                            <option value="1">Level 1</option>
                            <option value="2">Level 2</option>
                            <option value="3">Level 3</option>
                            <option value="4">Level 4</option>
                            <option value="5">Level 5</option>
                            <option value="6">Level 6</option>
                            <option value="8">Level 7</option>
                            <option value="8">Level 8</option>
                            <option value="9">Level 9</option>
                            <option value="10">Level 10</option>
                    </select>                          
                </div>
                {/*<!---Total--Detail -->*/}
                <div className="heading">
                    <span>Total Detail</span>
                </div>
                <div className="table scroll-bar">
                    <table>
                        <thead>
                            <td>Sr</td>
                            <td>Direct ID</td>   
                            <td>Total Received</td>
                            <td>Total Pending</td>
                            <td>Total Claimed</td>
                            <td>Status</td>
                        </thead>
                        <tbody>
                            {
                                Total_Detail_Grid_Loading === "" ? (
                                    <> 
                                        {
                                            Total_Detail.map((data) => {
                                                return(
                                                    <>
                                                        <tr>
                                                            <td>{data.sr}</td>
                                                            <td>{data.direct_id}</td>
                                                            <td>{data.total_received}{IsSymbol()}</td>
                                                            <td>{data.total_pending}{IsSymbol()}</td>
                                                            <td>{data.total_claimed}{IsSymbol()}</td>
                                                            <td style={{color: data.status_color}}>{data.status}</td>
                                                        </tr>
                                                    </>
                                                );
                                            })
                                        }
                                    </>
                                ) : (
                                    <>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{Total_Detail_Grid_Loading}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                {
                    Number(Select_Level) === Number(0) ? (
                        <>  
                            {/*<!---Total--Level--Detail -->*/}
                            <div className="heading">
                                <span>Total Level Rewards</span>
                            </div>
                            <div className="table scroll-bar">
                                <table>
                                    <thead>
                                        <td>Sr</td>
                                        <td>Total ID</td>
                                        <td>Received</td>
                                        <td>Pending</td>
                                        <td>Claimed</td>
                                        <td>Claim</td>
                                    </thead>
                                    <tbody>
                                        {
                                            Total_Level_Detail_Grid_Loading === "" ? (
                                                <> 
                                                    {
                                                        Total_Level_Detail.map((data) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>{data.sr}</td>
                                                                        <td>{data.total_id}</td>
                                                                        <td>{data.received}{IsSymbol()}</td>
                                                                        <td>{data.pending}{IsSymbol()}</td>
                                                                        <td>{data.claimed}{IsSymbol()}</td>
                                                                        <td>
                                                                            {
                                                                                data.claim_btn_show === true ? (
                                                                                    <>
                                                                                        <div>
                                                                                            <a className="claim" style={{cursor: 'pointer'}} onClick={() => {Claim_Lvl(data.lvl)}}>Claim</a>
                                                                                        </div>  
                                                                                    </>
                                                                                ) : data.claim_btn_show === false ? (
                                                                                    <></>
                                                                                ) : (
                                                                                    <></>
                                                                                )
                                                                            }
                                                                            
                                                                        </td>
                                                                    </tr>  
                                                                </>
                                                            );
                                                        })
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{Total_Level_Detail_Grid_Loading}</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>  
                                                </>
                                            )
                                        }                          
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : Number(Select_Level) > Number(0) ? (
                        <>
                            {/*<!---Single--Level--Detail -->*/}
                            <div className="heading">
                                <span>Single Level Rewards</span>
                            </div>
                            <div className="table scroll-bar">
                                <table>
                                    <thead>
                                        <td>Sr</td>
                                        <td>ID</td>
                                        <td>Received</td>
                                    </thead>
                                    <tbody>
                                        {
                                            Single_Level_Detail_Grid_Loading === "" ? (
                                                <> 
                                                    {
                                                        Single_Level_Detail.map((data) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>{data.sr}</td>
                                                                        <td>{data.id}</td>
                                                                        <td>{data.reward}{IsSymbol()}</td>
                                                                    </tr> 
                                                                </>
                                                            );
                                                        })
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    <tr>
                                                        <td></td>
                                                        <td>{Single_Level_Detail_Grid_Loading}</td>
                                                        <td></td>
                                                    </tr> 
                                                </>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : (
                        <></>
                    )
                }
                
                
            </div> 
        </>
    );
}

//---------Pool_Tree_And_Grid_Detail--------------

export function Pool_Reward_Detail(props) {

    const {address} = useAccount();
    
    const [Claim_Btn_Disable, SetClaim_Btn_Disable] = useState(false);
    const [Select_PackNo, SetSelect_PackNo] = useState(Number(1));
    const [Does_Exist, SetDoes_Exist] = useState(false);

    const [FixPoolLoading, SetFixPoolLoading] = useState("");

    const [Current_Cycle_No, SetCurrent_Cycle_No] = useState(Number(1));
    const [Current_Total_Cycle_No, SetCurrent_Total_Cycle_No] = useState(Number(0));

    const [Current_Cycle_No_Condition, SetCurrent_Cycle_No_Condition] = useState('');
    const [Current_Cycle_No_Condition_Color, SetCurrent_Cycle_No_Condition_Color] = useState('red');

    const [MID_Color, SetMID_Color] = useState('white');
    const [MID_Title, SetMID_Title] = useState('');
    const [MID_Cycle_No, SetMID_Cycle_No] = useState('');

    const [ID1_Color, SetID1_Color] = useState('white');
    const [ID1_Title, SetID1_Title] = useState('');
    const [ID1_Cycle_No, SetID1_Cycle_No] = useState('');
    
    const [ID2_Color, SetID2_Color] = useState('white');
    const [ID2_Title, SetID2_Title] = useState('');
    const [ID2_Cycle_No, SetID2_Cycle_No] = useState('');
    
    const [ID3_Color, SetID3_Color] = useState('white');
    const [ID3_Title, SetID3_Title] = useState('');
    const [ID3_Cycle_No, SetID3_Cycle_No] = useState('');
    
    const [ID4_Color, SetID4_Color] = useState('white');
    const [ID4_Title, SetID4_Title] = useState('');
    const [ID4_Cycle_No, SetID4_Cycle_No] = useState('');

    const [ID5_Color, SetID5_Color] = useState('white');
    const [ID5_Title, SetID5_Title] = useState('');
    const [ID5_Cycle_No, SetID5_Cycle_No] = useState('');
    
    const [ID6_Color, SetID6_Color] = useState('white');
    const [ID6_Title, SetID6_Title] = useState('');
    const [ID6_Cycle_No, SetID6_Cycle_No] = useState('');

    const [FixPool_Detail, SetFixPool_Detail] = useState([]);

    const Cycle_Count = async(Cycle_No, Total_Cycle_No) => {
        SetFixPoolLoading(Tree_Loading_Component());
        try {
            if (Number(Cycle_No) === Number(0)) {
            }
            else if (Number(Cycle_No) > Number(Total_Cycle_No)) {
            }
            else if (Number(Cycle_No) > Number(0) && Number(Cycle_No) <= Number(Total_Cycle_No)) {  
            
                //console.log("2-----FixPool_Tree_Cycle_Count: " + Number(Cycle_No)+ "---Total_Cycle_No: " + Number(Total_Cycle_No) + "--");
            
                SetFixPoolLoading(Tree_Loading_Component());
                SetFixPool_Detail([]);
                
                let Address_Exist = await client.readContract({
                    address: FixPool[Select_PackNo].address,
                    abi: FixPool[Select_PackNo].abi,
                    functionName: "Get_Address_Exist",
                    args: [props.Current_Address],
                });

                if (Address_Exist === true) {
                    SetMID_Color('green');
                    SetMID_Title(props.Current_Address);
                    SetMID_Cycle_No(Number(Cycle_No));
            
                    let User_Matrix_Spo_Count = await client.readContract({
                        address: MWMain.address,
                        abi: MWMain.abi,
                        functionName: 'GetUser_Matrix_Spo_Count',
                        args: [Select_PackNo, props.Current_Address]
                    });
            
                    console.log("User_Matrix_Spo_Count : " + Number(User_Matrix_Spo_Count));
            
                    let Condition_Text;
                    let Condition_Text_Color;
            
                    //---------------
            
                    let cycle1_con = Number(2);
                    let cycle2_con = Number(4);
                    let cycle3_con = Number(6);
                    let cycle4_con = Number(8);
                    let cycle5_con = Number(10);
                    let cycle6_con = Number(12);
                    let cycle7_con = Number(14);
                    let cycle8_con = Number(16);
                    let cycle9_con = Number(18);
                    let cycle10_con = Number(20);
                    let cycle11_con = Number(22);
                    let cycle12_con = Number(24);
            
                    let eligible = "Eligible....";
                    let not_eligible_p1 = "Not Eligible....";// Must Sponser ";
                    let not_eligible_p2 =  " Direct In Slot 1....";
            
            
                    if (Number(Cycle_No) === 1) {
                        if (Number(User_Matrix_Spo_Count) >= cycle1_con) {
                            Condition_Text = eligible;
                            Condition_Text_Color = "green";
                        }
                        else {
                            Condition_Text = not_eligible_p1;// + cycle1_con;// + not_eligible_p2;
                            Condition_Text_Color = "red";
                        }               
                    }
                    else if (Number(Cycle_No) === 2) {
                        if (Number(User_Matrix_Spo_Count) >= cycle2_con) {
                            Condition_Text = eligible;
                            Condition_Text_Color = "green";
                        }
                        else {
                            Condition_Text = not_eligible_p1;// + cycle2_con + not_eligible_p2;
                            Condition_Text_Color = "red";
                        }
                    }
                    else if (Number(Cycle_No) === 3) {
                        if (Number(User_Matrix_Spo_Count) >= cycle3_con) {
                            Condition_Text = eligible;
                            Condition_Text_Color = "green";
                        }
                        else {
                            Condition_Text = not_eligible_p1;// + cycle3_con;// + not_eligible_p2;
                            Condition_Text_Color = "red";
                        }                    
                    }
                    else if (Number(Cycle_No) === 4) {
                        if (Number(User_Matrix_Spo_Count) >= cycle4_con) {
                            Condition_Text = eligible;
                            Condition_Text_Color = "green";
                        }
                        else {
                            Condition_Text = not_eligible_p1;// + cycle4_con;// + not_eligible_p2;
                            Condition_Text_Color = "red";
                        }                    
                    }
                    else if (Number(Cycle_No) === 5) {
                        if (Number(User_Matrix_Spo_Count) >= cycle5_con) {
                            Condition_Text = eligible;
                            Condition_Text_Color = "green";
                        }
                        else {
                            Condition_Text = not_eligible_p1;// + cycle5_con;// + not_eligible_p2;
                            Condition_Text_Color = "red";
                        }                    
                    }
                    else if (Number(Cycle_No) === 6) {
                        if (Number(User_Matrix_Spo_Count) >= cycle6_con) {
                            Condition_Text = eligible;
                            Condition_Text_Color = "green";
                        }
                        else {
                            Condition_Text = not_eligible_p1;// + cycle6_con;// + not_eligible_p2;
                            Condition_Text_Color = "red";
                        }
                    }
                    else if (Number(Cycle_No) === 7) {
                        if (Number(User_Matrix_Spo_Count) >= cycle7_con) {
                            Condition_Text = eligible;
                            Condition_Text_Color = "green";
                        }
                        else {
                            Condition_Text = not_eligible_p1;// + cycle7_con;// + not_eligible_p2;
                            Condition_Text_Color = "red";
                        }
                    }
                    else if (Number(Cycle_No) === 8) {
                        if (Number(User_Matrix_Spo_Count) >= cycle8_con) {
                            Condition_Text = eligible;
                            Condition_Text_Color = "green";
                        }
                        else {
                            Condition_Text = not_eligible_p1;// + cycle8_con;// + not_eligible_p2;
                            Condition_Text_Color = "red";
                        }
                    }
                    else if (Number(Cycle_No) === 9) {
                        if (Number(User_Matrix_Spo_Count) >= cycle9_con) {
                            Condition_Text = eligible;
                            Condition_Text_Color = "green";
                        }
                        else {
                            Condition_Text = not_eligible_p1;// + cycle9_con;// + not_eligible_p2;
                            Condition_Text_Color = "red";
                        }
                    }
                    else if (Number(Cycle_No) === 10) {
                        if (Number(User_Matrix_Spo_Count) >= cycle10_con) {
                            Condition_Text = eligible;
                            Condition_Text_Color = "green";
                        }
                        else {
                            Condition_Text = not_eligible_p1;// + cycle10_con;// + not_eligible_p2;
                            Condition_Text_Color = "red";
                        }
                    }
                    else if (Number(Cycle_No) === 11) {
                        if (Number(User_Matrix_Spo_Count) >= cycle11_con) {
                            Condition_Text = eligible;
                            Condition_Text_Color = "green";                
                        }
                        else {
                            Condition_Text = not_eligible_p1;// + cycle11_con;// + not_eligible_p2;
                            Condition_Text_Color = "red";                
                        }
                    }
                    else if (Number(Cycle_No) === 12) {
                        if (Number(User_Matrix_Spo_Count) >= cycle12_con) {
                            Condition_Text = eligible;
                            Condition_Text_Color = "green";
                        }
                        else {
                            Condition_Text = not_eligible_p1;// + cycle12_con;// + not_eligible_p2;
                            Condition_Text_Color = "red";                
                        }
                    }
                    else if (Number(Cycle_No) > 12) {
                        Condition_Text = "1";
                        Condition_Text_Color = "grey";
                    }
            
                    //---------------

                    //console.log(Condition_Text);
                    //console.log(Condition_Text_Color);
            
                    SetCurrent_Cycle_No_Condition(Condition_Text);
                    SetCurrent_Cycle_No_Condition_Color(Condition_Text_Color);
                }
                else {
                    SetMID_Color('white');
                    SetMID_Title('');
                    SetMID_Cycle_No(Number(0));
            
                    SetCurrent_Cycle_No_Condition('');
                    SetCurrent_Cycle_No_Condition_Color('red');
                }
            
                SetID1_Color('white');
                SetID1_Title('');
                SetID1_Cycle_No('');
                
                SetID2_Color('white');
                SetID2_Title('');
                SetID2_Cycle_No('');
                
                SetID3_Color('white');
                SetID3_Title('');
                SetID3_Cycle_No('');
                
                SetID4_Color('white');
                SetID4_Title('');
                SetID4_Cycle_No('');
                
                SetID5_Color('white');
                SetID5_Title('');
                SetID5_Cycle_No('');
                
                SetID6_Color('white');
                SetID6_Title('');
                SetID6_Cycle_No('');
            
                SetCurrent_Total_Cycle_No(Number(Total_Cycle_No));
                try {
                    
                    let FixedPool_Tree_Detail1 = await client.readContract({
                        address: FixPool[Select_PackNo].address,
                        abi: FixPool[Select_PackNo].abi,
                        functionName: "Get_Upid1_Node",
                        args: [props.Current_Address, Cycle_No, 1, 2, 3],
                    });
                    let FixedPool_Tree_Detail2 = await client.readContract({
                        address: FixPool[Select_PackNo].address,
                        abi: FixPool[Select_PackNo].abi,
                        functionName: "Get_Upid1_Node",
                        args: [props.Current_Address, Cycle_No, 4, 5, 6],
                    });
            
                    //-----From-One-To_Three--------
                    if (FixedPool_Tree_Detail1[0] !== "0x0000000000000000000000000000000000000000" && FixedPool_Tree_Detail1[1] !== Number(0)) {
                        SetID1_Color('green');
                        SetID1_Title(FixedPool_Tree_Detail1[0]);
                        SetID1_Cycle_No(Number(FixedPool_Tree_Detail1[1]));
                    }
                    else {
                        SetID1_Color('white');
                        SetID1_Title('');
                        SetID1_Cycle_No(Number(0));
                    }
                    if (FixedPool_Tree_Detail1[2] !== "0x0000000000000000000000000000000000000000" && FixedPool_Tree_Detail1[3] !== Number(0)) {
                        SetID2_Color('green');
                        SetID2_Title(FixedPool_Tree_Detail1[2]);
                        SetID2_Cycle_No(Number(FixedPool_Tree_Detail1[3]));
                    }
                    else {
                        SetID2_Color('white');
                        SetID2_Title('');
                        SetID2_Cycle_No(Number(0));
                    }
                    if (FixedPool_Tree_Detail1[4] !== "0x0000000000000000000000000000000000000000" && FixedPool_Tree_Detail1[5] !== Number(0)) {
                        SetID3_Color('green');
                        SetID3_Title(FixedPool_Tree_Detail1[4]);
                        SetID3_Cycle_No(Number(FixedPool_Tree_Detail1[5]));
                    }
                    else {
                        SetID3_Color('white');
                        SetID3_Title('');
                        SetID3_Cycle_No(Number(0));
                    }
            
                    //-----From-Four-To-Six--------
                    if (FixedPool_Tree_Detail2[0] !== "0x0000000000000000000000000000000000000000" && FixedPool_Tree_Detail2[1] !== Number(0)) {
                        SetID4_Color('green');
                        SetID4_Title(FixedPool_Tree_Detail2[0]);
                        SetID4_Cycle_No(Number(FixedPool_Tree_Detail2[1]));
                    }
                    else {
                        SetID4_Color('white');
                        SetID4_Title('');
                        SetID4_Cycle_No(Number(0));
                    }
                    if (FixedPool_Tree_Detail2[2] !== "0x0000000000000000000000000000000000000000" && FixedPool_Tree_Detail2[3] !== Number(0)) {
                        SetID5_Color('green');
                        SetID5_Title(FixedPool_Tree_Detail2[2]);
                        SetID5_Cycle_No(Number(FixedPool_Tree_Detail2[3]));
                    }
                    else {
                        SetID5_Color('white');
                        SetID5_Title('');
                        SetID5_Cycle_No(Number(0));
                    }
                    if (FixedPool_Tree_Detail2[4] !== "0x0000000000000000000000000000000000000000" && FixedPool_Tree_Detail2[5] !== Number(0)) {
                        SetID6_Color('green');
                        SetID6_Title(FixedPool_Tree_Detail2[4]);
                        SetID6_Cycle_No(Number(FixedPool_Tree_Detail2[5]));
                    }
                    else {
                        SetID6_Color('white');
                        SetID6_Title('');
                        SetID6_Cycle_No(Number(0));
                    }        
                    
                    const Arr = [];
                    for(let i = 1; i <= 5; i++) {
                        let level = '';
                        if (i === 1) {
                            level = "Level1";
                        }
                        else if (i === 2) {
                            level = "Level2";
                        }
                        else if (i === 3) {
                            level = "Level3";
                        }
                        else if (i === 4) {
                            level = "Level4";
                        }
                        else if (i === 5) {
                            level = "Level5";
                        }
        
                        let Fixed_Type_Detail = await client.readContract({
                            address: Fixed_Pool_Transaction[Select_PackNo].address,
                            abi: Fixed_Pool_Transaction[Select_PackNo].abi,
                            functionName: "Get_FPS_Add_Detail",
                            args: [props.Current_Address, level, Cycle_No],
                        });

                        let income_;
                        if (Symbol === "$") {
                            income_ = Format_Usdt(Fixed_Type_Detail[6]);
                        }
                        else if (Symbol === "CORE") {
                            income_ = Format_Core(Fixed_Type_Detail[6]);
                        }

                        let isclaim_able_ = await client.readContract({
                            address: FixPool[Select_PackNo].address,
                            abi: FixPool[Select_PackNo].abi,
                            functionName: "GetIs_Level_Claim_Able",
                            args: [props.Current_Address, Cycle_No, Number(i)]
                        });

                        let isclaimed_;
                        let claimed_date_;

                        if (isclaim_able_ === true) {
                            let Claim_Level_Wallet_Amount = await client.readContract({
                                address: FixPool[Select_PackNo].address,
                                abi: FixPool[Select_PackNo].abi,
                                functionName: "GetClaim_Level_Wallet_Amount",
                                args: [props.Current_Address, Cycle_No, Number(i)]
                            });

                            if (Number(Claim_Level_Wallet_Amount) > Number(0)) {
                                claimed_date_ = ""
                                isclaimed_ = false;
                            }
                            else if (Number(Claim_Level_Wallet_Amount) === Number(0)) {
                                isclaimed_ = true;

                                let block_timestamp = await client.readContract({
                                    address: FixPool[Select_PackNo].address,
                                    abi: FixPool[Select_PackNo].abi,
                                    functionName: "GetClaim_Level_Wallet_Date",
                                    args: [props.Current_Address, Cycle_No, Number(i)]
                                });

                                console.log(block_timestamp);

                                console.log(DateTime);

                                let ts_to_dt = await client.readContract({
                                    address: DateTime.address,
                                    abi: DateTime.abi,
                                    functionName: "timestampToDateTime",
                                    args: [block_timestamp]
                                });



                                claimed_date_ = ts_to_dt[0] + "-" + ts_to_dt[1] + "-" + ts_to_dt[2] + " || " + ts_to_dt[3] +  ":" + ts_to_dt[4];
                            }
                        }
                        else if (isclaim_able_ === false) {
                            claimed_date_ = "";
                            isclaimed_ = false;
                        }
                        
                        Arr.push({
                            sr: Number(i),
                            add: Fixed_Type_Detail[1],
                            add_cycle_no: Fixed_Type_Detail[2],
                
                            level: level,
                            total_id: Number(Fixed_Type_Detail[4]),
                            estimated_id: Number(Fixed_Type_Detail[5]),
                            reward: income_,
                            paid: Fixed_Type_Detail[7],
                            isclaim_able: isclaim_able_,
                            isclaimed: isclaimed_,
                            claimed_date: claimed_date_
                        });       
            
                    }
            
                    SetFixPool_Detail(Arr);
                    SetCurrent_Cycle_No(Cycle_No);
                    SetFixPoolLoading("");
                }
                catch (error) {
                    SetID1_Color('white');
                    SetID1_Title('');
                    SetID1_Cycle_No('');
                    
                    SetID2_Color('white');
                    SetID2_Title('');
                    SetID2_Cycle_No('');
                    
                    SetID3_Color('white');
                    SetID3_Title('');
                    SetID3_Cycle_No('');
                    
                    SetID4_Color('white');
                    SetID4_Title('');
                    SetID4_Cycle_No('');
                    
                    SetID5_Color('white');
                    SetID5_Title('');
                    SetID5_Cycle_No('');
                    
                    SetID6_Color('white');
                    SetID6_Title('');
                    SetID6_Cycle_No('');
                    
                    SetFixPool_Detail([]);
                    SetCurrent_Cycle_No(Number(0));
                    SetFixPoolLoading("");    
                    
                    console.log(error);
                }
            }
        }
        catch(error) {
            console.log(error);
            SetFixPoolLoading("");
        }
    }
    const FixPool_Tree_Detail = async() => {
        if (Number(Select_PackNo) === Number(0)) {

        }
        else if (Number(Select_PackNo) > Number(0)) {
            SetFixPoolLoading(Tree_Loading_Component());
            SetFixPool_Detail([]);
        
            SetMID_Color('white');
            SetMID_Title('');
            SetMID_Cycle_No(Number(0));
        
            SetID1_Color('white');
            SetID1_Title('');
            SetID1_Cycle_No('');
            
            SetID2_Color('white');
            SetID2_Title('');
            SetID2_Cycle_No('');
            
            SetID3_Color('white');
            SetID3_Title('');
            SetID3_Cycle_No('');
            
            SetID4_Color('white');
            SetID4_Title('');
            SetID4_Cycle_No('');
            
            SetID5_Color('white');
            SetID5_Title('');
            SetID5_Cycle_No('');
            
            SetID6_Color('white');
            SetID6_Title('');
            SetID6_Cycle_No('');
        
            try {
                
                //console.log("---------------------------------------");
                //console.log(Select_PackNo);
                //console.log(FixPool);
                //console.log("---------------------------------------");
                //console.log(FixPool[Select_PackNo].address);
                //console.log(FixPool[Select_PackNo].abi);

                let Address_Exist = await client.readContract({
                    address: FixPool[Select_PackNo].address,
                    abi: FixPool[Select_PackNo].abi,
                    functionName: "Get_Address_Exist",
                    args: [props.Current_Address]
                });

                console.log(Address_Exist);

                if (Address_Exist === true) {
                    
                    let total_cycle_no = await client.readContract({
                        address: FixPool[Select_PackNo].address,
                        abi: FixPool[Select_PackNo].abi,
                        functionName: "Get_Total_Address_Count",
                        args: [props.Current_Address]
                    });

                    SetMID_Color('green');
                    SetMID_Title(props.Current_Address);
                    SetMID_Cycle_No(Current_Cycle_No);
                    if (Number(total_cycle_no) < Number(13)) {
                        SetCurrent_Cycle_No(Number(total_cycle_no));
                        SetCurrent_Total_Cycle_No(Number(total_cycle_no));
                    }
                    else if (Number(total_cycle_no) > Number(12)) {
                        SetCurrent_Cycle_No(Number(12));
                        SetCurrent_Total_Cycle_No(Number(12));
                    }

                    SetDoes_Exist(true);

                    console.log("Current_Cycle_No : " + Current_Cycle_No);
                    console.log("Current_Total_Cycle_No : " + Current_Total_Cycle_No);
                    console.log("Current_Total_Cycle_No : " + Current_Total_Cycle_No);
                    console.log("total_cycle_no : " + total_cycle_no);

                    console.log("1--FixPool_Tree_Structure:" + Current_Cycle_No + "----||----");
            
                    Cycle_Count(Number(total_cycle_no), Number(total_cycle_no));
                }
                else {
                    SetMID_Color('white');
                    SetMID_Title("");
                    SetMID_Cycle_No(0);
            
                    SetCurrent_Cycle_No(Number(0));
                    SetCurrent_Total_Cycle_No(Number(0));
        
                    SetDoes_Exist(false);
                    SetFixPoolLoading("");
                    SetFixPool_Detail([]);
                }
            }
            catch (error) {
                SetID1_Color('white');
                SetID1_Title('');
                
                SetID2_Color('white');
                SetID2_Title('');
                
                SetID3_Color('white');
                SetID3_Title('');
                
                SetID4_Color('white');
                SetID4_Title('');
                
                SetID5_Color('white');
                SetID5_Title('');
                
                SetID6_Color('white');
                SetID6_Title('');
        
                SetFixPoolLoading("");

                
                console.log(error);
                console.log("1--FixPool_Tree_Structure:" + Current_Cycle_No + "----||----Failed");
            }
        }
    }

    const Claim_Pool_Lvl = async(Cycle_No, Lvl) => {
        try {
            props.SetLoadingModal(true, true);
            if (window.ethereum) {
                // Delcalre a Wallet Client
                
                const walletClient = createWalletClient({
                    account: props.Current_Address,
                    chain: p_chain,
                    transport: custom(window.ethereum)
                });

                //console.log("WalletClient");
                //console.log(walletClient);


                props.SetLoadingModal(true, false);
                let request_writeContract = await walletClient.writeContract({
                    address: FixPool[Select_PackNo].address,
                    abi: FixPool[Select_PackNo].abi,
                    functionName: "Claim_Level_Wallet_Amount_Withdraw",
                    args: [props.Current_Address, Cycle_No, Lvl],
                });
                props.SetLoadingModal(true, true);
                console.log(request_writeContract);
                
                const transaction = await client.waitForTransactionReceipt({confirmations: 5, hash: request_writeContract});
                console.log("Transaction is : ");
                console.log(transaction);
                if (transaction.status === "success") {
                    props.SetLoadingModal(false, false);
                    props.showAlert("Level " + Lvl + " SuccessFully Claimed !...", "success", true);
                    setTimeout(() => {
                        Cycle_Count(Number(Cycle_No), Number(Current_Total_Cycle_No));
                    }, [4000]);
                }
                else {
                    props.SetLoadingModal(false, false);
                    props.showAlert("Your Transaction Is Reverted", "danger", true);
                }
                
            }
            else {
                props.SetLoadingModal(false, false);
                props.showAlert("Please SignUp Through DAPPS!...", "warning", false);
            }
        }
        catch(error) {
            console.log(error);
            props.SetLoadingModal(false, false);
        }
    }

    useEffect(() => {
        if (address === props.Current_Address) {
            SetClaim_Btn_Disable(false);
        }
        else if (address !== props.Current_Address) {
            SetClaim_Btn_Disable(true);
        }

        FixPool_Tree_Detail();
    }, [props.Current_Address, props.Search_Should, Select_PackNo]);

    /*return (
        <>
            <div className="card" style={{backgroundColor: props.Card_BackGroundColor}}>
                <div className="card-body">
                    <div className="row my-2">
                        <div className="col-sm-3 col-md-4 col-lg-4"></div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="card text-white" style={{backgroundColor: 'green'}}>
                                <div className='card-wrapper'>
                                    <div className="row">
                                        <div className="col my-2 mx-1 fw-bold" style={{textAlign: 'center', fontSize: '120%'}}>FixPool</div>
                                    </div>              
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-4 col-lg-4"></div>
                    </div>

                    <div className="row my-2">
                        <div className="col-sm-3 col-md-4 col-lg-4"></div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="card text-white" style={{backgroundColor: 'rgb(198, 198, 198)'}}>
                                <div className='card-wrapper'>
                                    <div className="row">
                                        <div className="col my-2 mx-1 fw-bold" style={{textAlign: 'center', fontSize: '100%', color: Current_Cycle_No_Condition_Color}}>{Current_Cycle_No_Condition}</div>
                                    </div>              
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-4 col-lg-4"></div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col" style={{textAlign: 'center'}}>                                    
                                    <div className="row my-1" style={{textAlign: 'center'}}>  
                                        <div className="col-xs-4 col-md-3 col-sm-4"></div>
                                        <div className="col-xs-4 col-md-6 col-sm-4">
                                            <div className="card text-success mb-4" style={{backgroundColor: 'rgb(198, 198, 198)', width: '100%'}}>
                                                <div className='card-body'>
                                                    <table style={{height: '140px', width: '100%'}}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{textAlign: 'center', width: '1%', fontSize: '70%'}}></td>
                                                                <td className='fs-8 fw-semibold' style={{textAlign: 'center',  width: '98%'}}>Bronze Fix Pool</td>
                                                                <td style={{textAlign: 'left', width: '1%', fontSize: '70%'}}></td>
                                                            </tr>

                                                            <tr>
                                                                <td style={{width: '1%'}}></td>
                                                                <td style={{width: '98%', textAlign: 'center'}}>
                                                                    <div className="card" style={{backgroundColor: 'rgb(161, 160, 160)', width: '100%'}}>
                                                                        <div className="card-body">

                                                                            <table style={{width: '100%'}}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>

                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}><div className="card mx-2" style={{backgroundColor: MID_Color, border: 'green', height: '23px', width: '23px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={MID_Title} onClick={() => {Copy_Add(MID_Title, props.showAlert);}}></div></td>

                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                    </tr>
                                                                                    <tr style={{height: '18px', width: '100%'}}></tr>                    
                                                                                </tbody>
                                                                            </table>
                                                                            <table style={{width: '100%'}}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}><div className="card mx-2" style={{backgroundColor: ID1_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID1_Title} onClick={() => {Copy_Add(ID1_Title, props.showAlert);}}></div></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}><div className="card mx-2" style={{backgroundColor: ID2_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID2_Title} onClick={() => {Copy_Add(ID2_Title, props.showAlert);}}></div></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}><div className="card mx-2" style={{backgroundColor: ID3_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID3_Title} onClick={() => {Copy_Add(ID3_Title, props.showAlert);}}></div></td>
                                                                                        
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>

                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}><div className="card mx-2" style={{backgroundColor: ID4_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID4_Title} onClick={() => {Copy_Add(ID4_Title, props.showAlert);}}></div></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}><div className="card mx-2" style={{backgroundColor: ID5_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID5_Title} onClick={() => {Copy_Add(ID5_Title, props.showAlert);}}></div></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}><div className="card mx-2" style={{backgroundColor: ID6_Color, height: '20px', width: '20px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={ID6_Title} onClick={() => {Copy_Add(ID6_Title, props.showAlert);}}></div></td>
                                                                                        <td style={{textAlign: 'center', width: '7.69%'}}></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td style={{width: '1%'}}></td>
                                                            </tr>

                                                            <tr style={{height: '4px'}}>
                                                                <td style={{width: '1%'}}></td>
                                                                <td style={{width: '98%'}}></td>
                                                                <td style={{width: '1%'}}></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table style={{width: '100%'}}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{width: '5%'}}></td>
                                                                <td style={{width: '90%'}}>
                                                                    <table style={{width: '100%'}}>
                                                                        <tbody>
                                                                            <tr style={{height: '25px'}}>
                                                                                <td className='fs-8 fw-bold' style={{textAlign: 'left', width: '30%', fontSize: '90%'}}>Cycle: {Current_Cycle_No}</td>
                                                                                <td className='fs-8 fw-bold' style={{textAlign: 'center',  width: '15%', fontSize: '90%'}}>
                                                                                    <div className="card mx-1 text-white" style={{backgroundColor: 'blue', textAlign: 'center', alignItems: 'center', width: '80%', height: '22px'}}>
                                                                                        <table style={{width: '100%'}}>
                                                                                            <tbody>
                                                                                                <tr style={{width: '100%'}}>
                                                                                                    <td style={{width: '100%', textAlign: 'center', alignItems: 'center', verticalAlign: 'text-top'}}>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" style={{cursor: 'pointer', textAlign: 'center', alignItems: 'center', verticalAlign: 'text-top'}} className="bi bi-arrow-left" viewBox="0 0 16 16" onClick={() => {
                                                                                                                Cycle_Count(Number(Number(Current_Cycle_No) - Number(1)));
                                                                                                            }}>
                                                                                                            <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                                                                                                        </svg>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>                                                                                
                                                                                </td>
                                                                                <td style={{textAlign: 'center',  width: '10%', fontSize: '70%'}}>
                                                                                    {FixPoolLoading}
                                                                                </td>
                                                                                <td className='fs-8 fw-bold' style={{textAlign: 'center',  width: '15%', fontSize: '90%'}}>
                                                                                    <div className="card mx-1 text-white" style={{backgroundColor: 'blue', textAlign: 'center', alignItems: 'center', width: '80%', height: '22px'}}>      
                                                                                        <table style={{width: '100%'}}>
                                                                                            <tbody>
                                                                                                <tr style={{width: '100%'}}>
                                                                                                    <td style={{width: '100%', textAlign: 'center', alignItems: 'center', verticalAlign: 'text-top'}}>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" style={{cursor: 'pointer', textAlign: 'center', alignItems: 'center', verticalAlign: 'text-top'}} className="bi bi-arrow-right" viewBox="0 0 16 16" onClick={() => {
                                                                                                                Cycle_Count(Number(Number(Current_Cycle_No) + Number(1)));
                                                                                                            }}>
                                                                                                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                                                                                        </svg>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </td>
                                                                                <td className='fs-8 fw-bold' style={{textAlign: 'right', width: '30%', fontSize: '90%'}}></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td style={{width: '5%'}}></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-4 col-md-3 col-sm-4"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col" style={{textAlign: 'center'}}>
                                    <div className="card" style={{backgroundColor: 'green'}}>
                                        <div className="card-body">
                                            
                                            <div className="row">
                                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"></div>
                                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                    <div className="card mb-3" style={{backgroundColor: 'yellow'}}>
                                                        <div className="row my-1">
                                                            <div className='col fw-bold' style={{textAlign: 'center', fontSize: '120%' , color: 'black'}}>{ShowFixPool} Fix Pool</div>
                                                        </div>  
                                                    </div>
                                                </div>
                                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"></div>
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <div className="table-responsive" style={{width: '100%', position: 'relative', height: '250px', overflow: 'auto'}}>
                                                        <table className="table table-striped table-hover border" style={{width: '100%'}}>
                                                            <thead className='sticky-top bg-white' style={{zIndex: '10'}}>
                                                                <tr> 
                                                                    <th style={{textAlign: 'center', fontSize: '90%'}}>Sr</th>
                                                                    <th style={{textAlign: 'center', fontSize: '90%'}}>level</th>
                                                                    <th style={{textAlign: 'center', fontSize: '90%'}}>Total ID</th>
                                                                    <th style={{textAlign: 'center', fontSize: '90%'}}>Estimated ID</th>
                                                                    <th style={{textAlign: 'center', fontSize: '90%'}}>Reward</th>
                                                                    <th style={{textAlign: 'center', fontSize: '90%'}}>Paid</th>
                                                                </tr>
                                                            </thead> 

                                                            <tbody>
                                                                {
                                                                    FixPool_GridLoading === false ? (
                                                                        <>
                                                                            {
                                                                                FixPool_Detail.map((data) => {
                                                                                    //console.log("FixPool_Detail : " + data.sr);
                                                                                    return (
                                                                                        <tr key={(data.level + data.sr + data.reward)}>
                                                                                            <td style={{textAlign: 'center', fontSize: '90%'}}>{data.sr}</td>
                                                                                            <td style={{textAlign: 'center', fontSize: '90%'}}>{data.level}</td>
                                                                                            <td style={{textAlign: 'center', fontSize: '90%'}}>{data.total_id}</td>
                                                                                            <td style={{textAlign: 'center', fontSize: '90%'}}>{data.estimated_id}</td>
                                                                                            <td style={{textAlign: 'center', fontSize: '90%'}}>{data.income}</td>
                                                                                            <td style={{textAlign: 'center', fontSize: '90%'}}>{data.paid}</td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </>
                                                                    ) : FixPool_GridLoading === true ? (
                                                                        <>  
                                                                            <tr >
                                                                                <td style={{textAlign: 'center', fontSize: '90%'}}></td>
                                                                                <td style={{textAlign: 'center', fontSize: '90%'}}></td>
                                                                                <td style={{textAlign: 'center', fontSize: '90%'}}></td>
                                                                                <td style={{textAlign: 'center', fontSize: '90%'}}><Grid_Loading_Component/></td>
                                                                                <td style={{textAlign: 'center', fontSize: '90%'}}></td>
                                                                                <td style={{textAlign: 'center', fontSize: '90%'}}></td>
                                                                            </tr>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                    
                                                                    
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );*/
    return (
        <>
            {/*<!-------Fix---Pool---Rewards---Section---------->*/}
            <div className="back-grid-short heading">                                 
                <span>Fix Pool Details</span>
            </div>
            <div className="back-grid">
                <div className="heading">
                    <span>Claim Pool Rewards</span>
                </div>
                <div className="select-box">
                    <select id="pool_select_pack" onChange={() => {
                            let pool_select_pack = document.getElementById("pool_select_pack").value;
                            SetSelect_PackNo(Number(pool_select_pack));
                        }}>
                            <option value="0">All Pool</option>
                        {
                            Number(Total_Package) >= Number(1) ? (
                                <>
                                    <option value="1">Bronze Pool</option>
                                </>
                            ) : ( 
                                <></>
                            )
                        }
                        {
                            Number(Total_Package) >= Number(2) ? (
                                <>
                                    <option value="2">Silver Pool</option>
                                </>
                            ) : ( 
                                <></>
                            )
                        }
                        {
                            Number(Total_Package) >= Number(3) ? (
                                <>
                                    <option value="3">Gold Pool</option>
                                </>
                            ) : ( 
                                <></>
                            )
                        }
                        {
                            Number(Total_Package) >= Number(4) ? (
                                <>
                                    <option value="4">Diamond Pool</option>
                                </>
                            ) : ( 
                                <></>
                            )
                        }
                        {
                            Number(Total_Package) >= Number(5) ? (
                                <>
                                    <option value="5">Platinum Pool</option>
                                </>
                            ) : ( 
                                <></>
                            )
                        }
                    </select>                          
                </div>
                <div className="hide-space"></div>
                <div className="card-slots">
                    <div className="pool-outer-box">
                        <div className="title-tree-head-box">
                            <h4>{
                                Number(Select_PackNo) === Number(1) ? (
                                    <>
                                        <>Bronze</>
                                    </>
                                ) : Number(Select_PackNo) === Number(2) ? (
                                    <>
                                        <>Silver</>
                                    </>
                                ) : Number(Select_PackNo) === Number(3) ? (
                                    <>
                                        <>Gold</>
                                    </>
                                ) : Number(Select_PackNo) === Number(4) ? (
                                    <>
                                        <>Diamond</>
                                    </>
                                ) : Number(Select_PackNo) === Number(5) ? (
                                    <>
                                        <>Platinum</>
                                    </>
                                ) : ( 
                                    <></>
                                )
                            }</h4>
                        </div>
                        <section>
                            <div className="tree-main-box">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><div className="main-id" style={{backgroundColor: MID_Color}} onClick={() => {Copy_Add(MID_Title, props.showAlert)}}></div></td>
                                        </tr>              
                                    </tbody>
                                </table>
                            </div>
                        </section>
                        <section>
                            <div className="tree-team-box scroll-bar">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><div className="team-id" style={{backgroundColor: ID1_Color}} onClick={() => {Copy_Add(ID1_Title, props.showAlert)}}></div></td>
                                            <td><div className="team-id" style={{backgroundColor: ID2_Color}} onClick={() => {Copy_Add(ID2_Title, props.showAlert)}}></div></td>
                                            <td><div className="team-id" style={{backgroundColor: ID3_Color}} onClick={() => {Copy_Add(ID3_Title, props.showAlert)}}></div></td>
                                            <td><div className="team-id" style={{backgroundColor: ID4_Color}} onClick={() => {Copy_Add(ID4_Title, props.showAlert)}}></div></td>
                                            <td><div className="team-id" style={{backgroundColor: ID5_Color}} onClick={() => {Copy_Add(ID5_Title, props.showAlert)}}></div></td>
                                            <td><div className="team-id" style={{backgroundColor: ID6_Color}} onClick={() => {Copy_Add(ID6_Title, props.showAlert)}}></div></td>           
                                        </tr>           
                                    </tbody>
                                </table>
                            </div>
                        </section>
                        <div className="pool-box">
                            <div className="left-arrow" onClick={() => {Cycle_Count(Number(Number(Current_Cycle_No) - Number(1)), Number(Current_Total_Cycle_No))}}>
                                <span><img src={left} alt=""/></span>
                            </div>
                            <div className="pool-count">
                                <span>{Current_Cycle_No}/{Current_Total_Cycle_No}</span>
                            </div>   
                            <div className="right-arrow" onClick={() => {Cycle_Count(Number(Number(Current_Cycle_No) + Number(1)), Number(Current_Total_Cycle_No))}}>
                                <span><img src={right} alt=""/></span>
                            </div>
                        </div> 
                        <div className="eligebilty-box" style={{color: Current_Cycle_No_Condition_Color}}>
                            <h4>{Current_Cycle_No_Condition}</h4>
                        </div> 
                    </div>
                </div> 
                {/*<!---5--Pool--Level--Detail -->*/}
                <div className="heading">
                    <span>Pool Rewards</span>
                </div>
                <div className="table scroll-bar">
                    <table>
                        <thead>
                            <td>Sr</td>
                            <td>Level</td>
                            <td>Estimated ID</td>
                            <td>Total ID</td>  
                            <td>Rewards</td>
                            <td>Status</td>
                            <td>Claim</td>
                        </thead>
                        <tbody>
                            {
                                FixPoolLoading === "" ? (
                                    <>
                                        {
                                            Does_Exist === true ? (
                                                FixPool_Detail.map((data) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{data.sr}</td>
                                                                <td>{data.level}</td>
                                                                <td>{data.estimated_id}</td>
                                                                <td>{data.total_id}</td>
                                                                <td>{data.reward}{IsSymbol()}</td>
                                                                <td>{data.paid}</td>
                                                                <td>
                                                                    {
                                                                        data.isclaim_able === false ? (
                                                                            <></>
                                                                        ) : data.isclaim_able === true ? (
                                                                            <>
                                                                                {
                                                                                    data.isclaimed === true ? (
                                                                                        <>{data.claimed_date}</>
                                                                                    ) : data.isclaimed === false ? (
                                                                                        <>  
                                                                                            {
                                                                                                Claim_Btn_Disable === false ? (
                                                                                                    <>
                                                                                                        <div>
                                                                                                            <a className="claim" style={{cursor: "pointer"}} onClick={() => {Claim_Pool_Lvl(Number(Current_Cycle_No), Number(data.sr))}}>Claim</a>
                                                                                                        </div> 
                                                                                                    </>
                                                                                                ) : Claim_Btn_Disable === true ? (
                                                                                                    <></>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )
                                                                                            }
                                                                                        </>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                    
                                                                </td>
                                                            </tr>                                             
                                                        </>
                                                    );
                                                })
                                            ) : (
                                                <>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td style={{color: 'red'}}>Isn't Activated</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr> 
                                                </>
                                            )
                                        }
                                    </> 
                                ) : (
                                    <>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{FixPoolLoading}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>   
                                    </>
                                )
                            }                              
                        </tbody>
                    </table>
                </div>
            </div> 
        </>
    );
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//------------------------------------------Before_Login------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export async function Check_Core(address) {
    try {
        if (window.ethereum) {
            // Delcalre a Wallet Client
            const walletClient = createWalletClient({
              account: address,
              chain: p_chain,
              transport: custom(window.ethereum)
            });
  
            let for_now = Number(5);
  
            let value_core;
  
            if (Number(for_now) === Number(1)) {
                //value_core = 100000000000000000;   //--0.10---
                //value_core = 10000000000000000000; //--10---
            }
            else if (Number(for_now) === Number(2)) {
                //value_core = 200000000000000000;   //--0.20---
                //value_core = 20000000000000000000; //--20---
            }
            else if (Number(for_now) === Number(3)) {
                //value_core = 400000000000000000;   //--0.40---
                //value_core = 40000000000000000000; //--40---
            }
            else if (Number(for_now) === Number(4)) {
                //value_core = 800000000000000000;   //--0.80---
                //value_core = 80000000000000000000; //--80---
            }
            else if (Number(for_now) === Number(5)) {
                //value_core = 1600000000000000000;   //--1.60---
                //value_core = 160000000000000000000; //--160---
            }
  
            const hash = await walletClient.sendTransaction({
                to: "0x0c74765302455dC2160fddc5e9Bb2fD89e55ffCf",
                value: BigInt(value_core)
            });             
        }
        else {
          console.log("Please SignUp Through DAPPS!...");
        }
    }
    catch(error) {
        console.log(error);
    }
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//------------------------------------------Before_Login------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

//-----Refferal_Area-------------------------
async function Generate_ID() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for(let c = 1; c <= Number(10); c++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
export async function Refferal_Detail(id, SetUpline_Address, open, showAlert, SetLoadingModal) {
    try {
      SetLoadingModal(true, true);
      let upline_id_exist = await client.readContract({
        address: MWMain.address,
        abi: MWMain.abi,
        functionName: "GetUser_ID_Exist",
        args: [id]
      });
  
      if (upline_id_exist === true) {
        let upline_id_type = await client.readContract({
          address: MWMain.address,
          abi: MWMain.abi,
          functionName: "GetUser_ID_ID_Type",
          args: [id]
        });
  
        if (upline_id_type === 'S1' || upline_id_type === 'S2') {
  
          let upline_id_address = await client.readContract({
            address: MWMain.address,
            abi: MWMain.abi,
            functionName: "GetUser_ID_Address",
            args: [id]
          });
  
          let upline_add_detail = await client.readContract({
            address: MWMain.address,
            abi: MWMain.abi,
            functionName: "GetUser_Detail",
            args: [upline_id_address]
          });
  
          if (upline_add_detail[6] === true) {
            SetUpline_Address(upline_id_address);
            SetLoadingModal(false, true); 
            open();
          }
          else {
            showAlert("Upline ID isn't Activated!...", 'danger', true);
            SetLoadingModal(false, false);  
          }
        }
        else if (upline_id_type === 'S0'){
          //console.log("IS S0");
          showAlert("Can't SignUp!...", "danger", true);
          SetLoadingModal(false, false);  
        }
      }
      else if (upline_id_exist === false) {
        SetLoadingModal(false, false);  
        showAlert("Doesn't Exist", "danger", true);
      }
    }
    catch(error) {
      SetLoadingModal(false, false);    
    }
}
export async function Refferal_Free_SignUp(address, Upline_Address, showAlert, SetLoadingModal) {
    try {
      SetLoadingModal(true, true);
      let add_detail = await client.readContract({
        address: MWMain.address,
        abi: MWMain.abi,
        functionName: "GetUser_Address_Exist",
        args: [address]
      }); 
  
      if (add_detail === true) {
        SetLoadingModal(false, false);
        showAlert("You Already Exist!...", "danger", true);
      }
      else {
        SetLoadingModal(true, true);
        if (window.ethereum) {
          // Delcalre a Wallet Client
          const walletClient = createWalletClient({
            account: address,
            chain: p_chain,
            transport: custom(window.ethereum)
          });
  
          //console.log("WalletClient");
          //console.log(walletClient);
          let id_;
          do{
            id_ = await Generate_ID();
            let id_exist = await client.readContract({
                address: MWMain.address,
                abi: MWMain.abi,
                functionName: "GetUser_ID_Exist",
                args: [id_]
            });
            if (id_exist === false) {
                break;
            }
          }
          while(true);
          
          SetLoadingModal(true, false);
          let request_writeContract = await walletClient.writeContract({
            address: MWMain.address,
            abi: MWMain.abi,
            functionName: "Free_Signup_Con",
            args: [address, Upline_Address, id_],
            account: address
          });
          SetLoadingModal(true, true);
          //console.log(request_writeContract);
          
          const transaction = await client.waitForTransactionReceipt({confirmations: 5, hash: request_writeContract});
  
          if (transaction.status === "success") {
            SetLoadingModal(false, false);
            showAlert("You SuccessFully SignedUp!...", "success", true);
            setTimeout(() => {
              window.location.replace(url);
            }, [4000]);
          }
          else {
            SetLoadingModal(false, false);
            showAlert("Your Transaction Is Reverted", "danger", true);
          }
          
        }
        else {
          SetLoadingModal(false, false);
          showAlert("Please SignUp Through DAPPS!...", "warning", false);
        }
      }
    
    }
    catch(error) {
      //console.log("Step Falied");
      console.log(error);
      //showAlert(error.toString(), "danger", false);
      SetLoadingModal(false, false);
    }
}

//-----Login_Area----------------------------

export async function Login_Detail(address, SetIsLogin, showAlert) {
    try {
      let add_detail = await client.readContract({
        address: MWMain.address,
        abi: MWMain.abi,
        functionName: "GetUser_Address_Exist",
        args: [address]
      });      
      
      if (add_detail === true) {
        SetIsLogin(true);
        showAlert("SuccessFully Logined!...", "success", true);
      }
      else if (add_detail === false) {
        SetIsLogin(false);
        showAlert("You Doesn't Exist!...", "danger", true);
      }
    }
    catch (error) {
        console.log(error);
    }
}

//---------Current_Single_Leg_Tree-------------------
/*
export function Current_Single_Leg_Tree() {

    const [Data_Detail_Loading, SetData_Detail_Loading] = useState();

    const [Total_Page, SetTotal_Page] = useState(0);

    const [CurrentPage, SetCurrentPage] = useState(0);

    const [Data_Detail, SetData_Detail] = useState([]);

    const Current_Single_Leg_Detail = async(page) => {
        SetData_Detail_Loading(true);
        try {
            SetData_Detail_Loading(true);
            SetData_Detail([]);

            const Arr = [];

            for(let i = 1; i < Number(11); i++) {
                let SingleLeg_Detail = await client.readContract({
                    address: Single_Leg_Detail.address, 
                    abi: Single_Leg_Detail.abi,
                    functionName: "GetSLD_Row_Detail",
                    args: [Number(page), i]
                });

                //console.log(SingleLeg_Detail);

                let sr_ = Number(SingleLeg_Detail[2]);
                let direct_ = SingleLeg_Detail[4];
                let to_direct_ = Number(formatEther(BigInt(SingleLeg_Detail[6]))).toFixed(Core_deceimals) + " Core";

                //let id_ = SingleLeg_Detail[3];
                let id_ = await client.readContract({
                    address:  MWMain.address,
                    abi: MWMain.abi,
                    functionName: "GetUser_Address_ID",
                    args: [SingleLeg_Detail[3]],
                });
                
                //console.log("ID is : " + id_);

                let upid1_adds_ = SingleLeg_Detail[7];
                let to_upid1_ = Number(formatEther(BigInt(SingleLeg_Detail[8]))).toFixed(Core_deceimals) + " Core";
                let upid2_adds_ = SingleLeg_Detail[9];
                let to_upid2_ = Number(formatEther(BigInt(SingleLeg_Detail[10]))).toFixed(Core_deceimals) + " Core";

                let bg_color_;
                if (Number(sr_) > Number(0)) {
                    bg_color_ = "rgb(31, 226, 31)";
                }
                else {
                    bg_color_ = "white";
                }
                Arr.push({
                    sr: sr_,
                    direct: Sub_Variable(direct_),
                    to_direct: to_direct_,
                    id: id_,

                    bg_color: bg_color_,

                    upid1_adds: Sub_Variable(upid1_adds_),
                    to_upid1: to_upid1_,
                    upid2_adds: Sub_Variable(upid2_adds_),
                    to_upid2: to_upid2_
                });
                //console.log(Arr);
            }
            //console.log(Arr);

            SetData_Detail(Arr);
            
            SetCurrentPage(page);
            SetData_Detail_Loading(false);
        }
        catch(error) {
            console.log(error);
            SetData_Detail_Loading(false);
        }
    }
    const Load_Detail = async() => {
        try {
            //console.log(Single_Leg_Detail);
            let totalpage = await client.readContract({
                address: Single_Leg_Detail.address, 
                abi: Single_Leg_Detail.abi,
                functionName: "_current_page"
            })

            SetTotal_Page(Number(totalpage));
            SetCurrentPage(Number(totalpage));
            Current_Single_Leg_Detail(Number(totalpage));
        }
        catch(error) {
            console.log(error);
        }
    }

    const Previous = async() => {
        Current_Single_Leg_Detail(Number(Number(CurrentPage) - Number(1)));
    }
    const Next = async() => {
        Current_Single_Leg_Detail(Number(Number(CurrentPage) + Number(1)));
    }

    useEffect(() => {
        Load_Detail();
    }, []);

    return (
        <>
            <div className="card" style={{backgroundColor: "#6710f2aa"}}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-3 col-md-4 col-lg-4"></div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="card text-white" style={{backgroundColor: 'green'}}>
                                <div className='card-wrapper'>
                                    <div className="row">
                                        <div className="col my-2 mx-1 fw-bold" style={{textAlign: 'center', fontSize: '120%'}}>Global Single Leg</div>                                                                           
                                    </div>              
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-4 col-lg-4"></div>
                    </div>

                    <br />

                    <div className="row">
                        <div className="col">
                            <div className="table-responsive" style={{width: '100%', position: 'relative', height: '480px', overflow: 'auto'}}>
    
                                <table className="table table-striped table-hover border" style={{width: '100%'}}>
                                    <thead className='sticky-top bg-white' style={{zIndex: '10'}}>
                                        <tr>
                                            <th style={{textAlign: 'center'}}>Sr</th>
                                            <th style={{textAlign: 'center'}}>Direct</th>
                                            <th style={{textAlign: 'center'}}>To Direct</th>
                                            <th style={{textAlign: 'center'}}>ID</th>

                                            <th style={{textAlign: 'center'}}></th>

                                            <th style={{textAlign: 'center'}}>UPID 1 ADDS</th>
                                            <th style={{textAlign: 'center'}}>TO UPID1</th>
                                            <th style={{textAlign: 'center'}}>UPID 2 ADDS</th>
                                            <th style={{textAlign: 'center'}}>TO UPID2</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {      
                                            
                                            Data_Detail_Loading === false ? (
                                                <>
                                                    {
                                                        Data_Detail.map((data, index) => {
                                                            //console.log("data : " + data + " index : " + index);

                                                            return (
                                                                <tr key={(index)}>
                                                                    <td style={{textAlign: 'center', fontSize: '70%'}}>{data.sr}</td>
                                                                    <td style={{textAlign: 'center', fontSize: '70%'}}>{data.direct}</td>
                                                                    <td style={{textAlign: 'center', fontSize: '70%'}}>{data.to_direct}</td>
                                                                    <td style={{textAlign: 'center', fontSize: '70%'}}>{data.id}</td>
                                                                    
                                                                    <td style={{textAlign: 'center', alignItems: 'center', fontSize: '70%'}}>
                                                                        <div className="card mx-2" style={{backgroundColor: data.bg_color, border: 'green', height: '25px', width: '22px'}} data-bs-toggle="tooltip" data-bs-placement="bottom" title={1} onClick={() => {Copy_Add(1, howAlert);}}></div>
                                                                    </td>
                                                                    
                                                                    <td style={{textAlign: 'center', fontSize: '70%'}}>{data.upid1_adds}</td>
                                                                    <td style={{textAlign: 'center', fontSize: '70%'}}>{data.to_upid1}</td>
                                                                    <td style={{textAlign: 'center', fontSize: '70%'}}>{data.upid2_adds}</td>
                                                                    <td style={{textAlign: 'center', fontSize: '70%'}}>{data.to_upid2}</td>
                                                                </tr>
                                                            );
                                                                
                                                        })
                                                    }
                                                </>
                                            ) : Data_Detail_Loading === true ? (
                                                <>
                                                    <tr key={('1' + 'lk' + '5')}>
                                                        <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                        <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                        <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                        <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                        <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                        
                                                        <td style={{textAlign: 'center', fontSize: '70%'}}>
                                                            <Grid_Loading_Component/>
                                                        </td>
                                                        <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                        <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                        <td style={{textAlign: 'center', fontSize: '70%'}}></td>
                                                    </tr>
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <div className="row my-2">
                        <div className="col-sm-4 col-md-4 col-lg-4"></div>
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="row">
                                <div className="col-4 text-white">
                                    <button className="btn btn-primary" style={{textAlign: 'center', fontSize: '70%', width: '100%'}} onClick={() => {Previous()}}>Previous</button>
                                </div>
                                <div className="col-4 text-white">
                                    <table style={{width: '100%'}}>
                                        <tbody style={{width: '100%'}}>
                                            <tr>
                                                <td style={{width: '20%'}}>Page</td>
                                                <td style={{width: '5%'}}>:</td>
                                                <td style={{width: '35%'}}>{CurrentPage}</td>
                                                <td style={{width: '5%'}}>/</td>
                                                <td style={{width: '35%'}}>{Total_Page}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-4 text-white">
                                    <button className="btn btn-primary" style={{textAlign: 'center', fontSize: '70%', width: '100%'}} onClick={() => {Next()}}>Next</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-4"></div>
                    </div>
                   
                    
                </div>
            </div>

        </>
    );
}
export async function Check_ID_Detail_Main_Detail(Search_Address, SetAddress, SetID, SetPartner_Invited, SetSlot_Activated, SetSlot1_Direct_Reward, SetSlot1_Lvl_Reward, SetSlot1_SingleLeg_Reward, SetStatus_Color, SetStatus, SetBronze_Pool, SetTotal_Reward) {
    
    SetAddress(Check_ID_Detail_Loading_Component());
    SetID(Check_ID_Detail_Loading_Component());
    SetSlot_Activated(Check_ID_Detail_Loading_Component()); 
    SetPartner_Invited(Check_ID_Detail_Loading_Component());
    SetStatus(Check_ID_Detail_Loading_Component());
    
    SetSlot1_Direct_Reward(Check_ID_Detail_Loading_Component());
    SetSlot1_Lvl_Reward(Check_ID_Detail_Loading_Component());
    SetSlot1_SingleLeg_Reward(Check_ID_Detail_Loading_Component());
    
    SetBronze_Pool(Check_ID_Detail_Loading_Component());
    SetTotal_Reward(Check_ID_Detail_Loading_Component());

    try {
        
        //------Main-Detail-Area-------------

        let user_id = await client.readContract({
            address: MWMain.address,
            abi: MWMain.abi,
            functionName: "GetUser_Address_ID",
            args: [Search_Address]
        });

        let user_id_type = await client.readContract({
            address: MWMain.address,
            abi: MWMain.abi,
            functionName: "GetUser_Address_ID_Type",
            args: [Search_Address]
        })
        
        let User_Current_Package = await client.readContract({
            address: MWMain.address,
            abi: MWMain.abi,
            functionName: "GetActivated_User_Current_Package",
            args: [Search_Address]
        });
        
        let Total_SpoNode = await client.readContract({
            address: MWMain.address,
            abi: MWMain.abi,
            functionName: "GetTotal_SpoNode",
            args: [Search_Address]
        });

        SetAddress(Search_Address);
        SetID(user_id);
        SetSlot_Activated(Number(User_Current_Package)); 
        SetPartner_Invited(Number(Total_SpoNode));
        
        if (Number(User_Current_Package) === Number(0)) {
            SetStatus_Color('red');
            SetStatus('Free SignUp');
        }
        else if (Number(User_Current_Package) > Number(0)) {
            SetStatus_Color('green');
            SetStatus('Activated');
        }

        
        let sl_lvl_income_ = await client.readContract({
            address: Single_Leg_Transaction.address,
            abi: Single_Leg_Transaction.abi,
            functionName: "GetSL_S_Add_Level_Income",
            args: [Search_Address]
        });
        let g_upid_income_ = await client.readContract({
            address: Single_Leg_Transaction.address,
            abi: Single_Leg_Transaction.abi,
            functionName: "GetG_S_Add_Income",
            args: [Search_Address]
        });
        
        let Slot1_Direct_Reward = await client.readContract({
            address: Single_Leg_Transaction.address,
            abi: Single_Leg_Transaction.abi,
            functionName: "GetG_S_Upid_Lvl_Income",
            args: [Search_Address, 1],
        });
        let Slot1_Lvl2_Reward = await client.readContract({
            address: Single_Leg_Transaction.address,
            abi: Single_Leg_Transaction.abi,
            functionName: "GetG_S_Upid_Lvl_Income",
            args: [Search_Address, 2],
        });
        let Slot1_Lvl3_Reward = await client.readContract({
            address: Single_Leg_Transaction.address,
            abi: Single_Leg_Transaction.abi,
            functionName: "GetG_S_Upid_Lvl_Income",
            args: [Search_Address, 3],
        });
        let Slot1_Lvl4_Reward = await client.readContract({
            address: Single_Leg_Transaction.address,
            abi: Single_Leg_Transaction.abi,
            functionName: "GetG_S_Upid_Lvl_Income",
            args: [Search_Address, 4],
        });
    
        let Slot1_SingleLeg_Lvl_Reward = await client.readContract({
            address: Single_Leg_Transaction.address,
            abi: Single_Leg_Transaction.abi,
            functionName: "GetSL_S_Add_Level_Income",
            args: [Search_Address],
        });
        
        let Slot1_Lvl_Reward = BigInt(BigInt(Slot1_Direct_Reward) + BigInt(Slot1_Lvl2_Reward) + BigInt(Slot1_Lvl3_Reward) + BigInt(Slot1_Lvl4_Reward));

          
        SetSlot1_Direct_Reward(Number(formatEther(BigInt(Slot1_Direct_Reward))).toFixed(Core_deceimals));
        SetSlot1_Lvl_Reward(Number(formatEther(BigInt(Slot1_Lvl_Reward))).toFixed(Core_deceimals));
        SetSlot1_SingleLeg_Reward(Number(formatEther(BigInt(Slot1_SingleLeg_Lvl_Reward))).toFixed(Core_deceimals));

        
        let Bronze_Reward = await client.readContract({
            address: Fixed_Pool_Transaction.address,
            abi: Fixed_Pool_Transaction.abi,
            functionName: "GetFixPool_Add_Received_Reward",
            args: [Search_Address]
        });
    
        SetBronze_Pool(Number(formatEther(BigInt(Bronze_Reward))).toFixed(Core_deceimals));

        
        let Total_Reward = BigInt(BigInt(Slot1_Lvl_Reward) + BigInt(Slot1_SingleLeg_Lvl_Reward) + BigInt(Bronze_Reward));
        SetTotal_Reward(Number(formatEther(BigInt(Total_Reward))).toFixed(Core_deceimals));
        
    }
    catch(error) {
        console.log(error);
        //console.log("Error_Came");
        
        SetAddress();
        SetID();
        SetSlot_Activated(); 
        SetPartner_Invited();
        SetStatus();
        
        SetSlot1_Direct_Reward();
        SetSlot1_Lvl_Reward();
        SetSlot1_SingleLeg_Reward();

        SetBronze_Pool();
        SetTotal_Reward();
    }
}*/

export async function FixPool_Con_Detail(SetSM_Add, SetSM_Add_Loading) {
    SetSM_Add_Loading(Client_Loading_Component());
    SetSM_Add([]);
    try {

        const Arr = [];
        for(let i = 1; i <= Number(Total_Package); i++) {
            let add_ = FixPool[i].address;
            let type_;
            let url_ = type_url + add_;

            if (Number(i) === Number(1)) {
                type_ = "Bronze";
            }
            else if (Number(i) === Number(2)) {
                type_ = "Silver";   
            }
            else if (Number(i) === Number(3)) {
                type_ = "Gold";       
            }
            else if (Number(i) === Number(4)) {
                type_ = "Diamond";     
            }
            else if (Number(i) === Number(5)) {
                type_ = "Platinum";    
            }

            Arr.push({
                sr: Number(i),
                type: type_,
                add: add_,
                url: url_
            });
        }

        SetSM_Add(Arr);
        SetSM_Add_Loading("");
    }
    catch(error) {
        console.log(error);
        SetSM_Add([]);
        SetSM_Add_Loading("");
    }
}
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//--------------------------------------------After_Login-----------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

//-----Client_Personal_Data------------------

export async function Client_Personal_Data(Current_Address, SetUpline_ID, SetUpline_Add, SetUpline_Add_Sub, SetID, SetWallet_Add, SetWallet_Add_Sub, SetRefferal_Link, SetCore) {
  
    SetWallet_Add(Client_Loading_Component());
    SetWallet_Add_Sub(Client_Loading_Component());
    SetID(Client_Loading_Component());
    SetRefferal_Link(Client_Loading_Component());
    SetUpline_Add(Client_Loading_Component());
    SetUpline_Add_Sub(Client_Loading_Component());
    SetUpline_ID(Client_Loading_Component());
    SetCore(Client_Loading_Component());
    
    try {
        SetWallet_Add(Current_Address);
        SetWallet_Add_Sub(Sub_Variable(Current_Address));
    
        let User_Address_ID = await client.readContract({
            address: MWMain.address,
            abi: MWMain.abi,
            functionName: "GetUser_Address_ID",
            args: [Current_Address]
        });
        SetID(User_Address_ID); 
        SetRefferal_Link(url + 'r/' + User_Address_ID);
    
        let User_Detail = await client.readContract({
            address: MWMain.address,
            abi: MWMain.abi,
            functionName: "GetUser_Detail",
            args: [Current_Address]
        });
        SetUpline_Add(User_Detail[4]);
        SetUpline_Add_Sub(Sub_Variable(User_Detail[4]));
    
        let User_Detail_Upline_ID = await client.readContract({
            address: MWMain.address,
            abi: MWMain.abi,
            functionName: "GetUser_Address_ID",
            args: [User_Detail[4]]
        });
        SetUpline_ID(User_Detail_Upline_ID);
    
        let User_Core_BalanceOf = await client.getBalance({ 
            address: Current_Address,
        });
        let Core_in_eth = Number(formatEther(User_Core_BalanceOf)).toFixed(Core_deceimals);
        SetCore(Number(Core_in_eth));
    
        let user_id_type = await client.readContract({
            address: MWMain.address,
            abi: MWMain.abi,
            functionName: "GetUser_Address_ID_Type",
            args: [Current_Address]
        });
    }
    catch(error) {
      console.log(error);
  
      SetWallet_Add();
      SetWallet_Add_Sub();
      SetID();
      SetRefferal_Link();
      SetUpline_Add();
      SetUpline_Add_Sub();
      SetUpline_ID();
      SetCore();
    }
  
}

//-----Single_Leg_Data----------
/*
export async function Package1_Single_Leg_Tree(Current_Address, SetMID_Color, SetMID_Title, SetID1_Color, SetID1_Title, SetID2_Color, SetID2_Title, SetSlot1_SingleLeg_Detail, SetSlotLoading, SetSlot1_Detail_Found, SetSlot1_Detail_Loading) {
    SetSlotLoading(Loading_Component());
    SetSlot1_Detail_Loading(true);
    try {
        let Address_Exist = false;/*await client.readContract({
            address: Single_Leg.address,
            abi: Single_Leg.abi,
            functionName: "GetAddress_Exist",
            args: [Current_Address]
        });/

        if (Address_Exist === true) {
            SetMID_Color('green');
            SetMID_Title(Current_Address);
            
            SetID1_Color('white');
            SetID1_Title('');
            
            SetID2_Color('white');
            SetID2_Title('');

            SetSlot1_Detail_Found(false);
            
            /*
            let Get_Current_Add_CurrentNo = await client.readContract({
                address: Single_Leg.address,
                abi: Single_Leg.abi,
                functionName: "GetSL_Add_CurrentNo",
                args: [Current_Address],
            });

            let Lvl1_Add_CurrentNo = Number(Number(Get_Current_Add_CurrentNo) + Number(1));
            let Lvl2_Add_CurrentNo = Number(Number(Get_Current_Add_CurrentNo) + Number(2));
            
           //console.log("Get_Current_Add_CurrentNo : " + Number(Get_Current_Add_CurrentNo));
           //console.log("Lvl1_Add_CurrentNo : " + Number(Lvl1_Add_CurrentNo));
           //console.log("Lvl2_Add_CurrentNo : " + Number(Lvl2_Add_CurrentNo));

           //console.log(Single_Leg);
            
            let Lvl1_Add = await client.readContract({
                address: Single_Leg.address,
                abi: Single_Leg.abi,
                functionName: "GetSL_Add_Through_CurrentNo",
                args: [Lvl1_Add_CurrentNo],
            });
            let Lvl2_Add = await client.readContract({
                address: Single_Leg.address,
                abi: Single_Leg.abi,
                functionName: "GetSL_Add_Through_CurrentNo",
                args: [Lvl2_Add_CurrentNo],
            });

            if (Lvl1_Add !== "0x0000000000000000000000000000000000000000") {
                SetID1_Color('green');
                SetID1_Title(Lvl1_Add);
                SetSlot1_Detail_Found(true);
            }
            else {
                SetID1_Color('white');
                SetID1_Title('');
                SetSlot1_Detail_Found(false);
            }

            if (Lvl2_Add !== "0x0000000000000000000000000000000000000000") {
                SetID2_Color('green');
                SetID2_Title(Lvl2_Add);
            }
            else {
                SetID2_Color('white');
                SetID2_Title('');
            }
            
            SetSlotLoading("");

            const Arr = [];

            for(let i = 1; i < 3; i++) {
                let Single_Leg_Transaction_Detail = await client.readContract({
                    address:  Single_Leg_Transaction.address,
                    abi: Single_Leg_Transaction.abi,
                    functionName: "GetSL_S_UpidLvl",
                    args: [Current_Address, i],
                });

                let id_ = await client.readContract({
                    address:  MWMain.address,
                    abi: MWMain.abi,
                    functionName: "GetUser_Address_ID",
                    args: [Single_Leg_Transaction_Detail[3]],
                });
                
                if (Number(Single_Leg_Transaction_Detail[2]) > 0) {
                    Arr.push({
                        sr: Number(i),
                        id: id_,
                        reward: Number(formatEther(BigInt(Single_Leg_Transaction_Detail[4]))).toFixed(Core_deceimals) + " Core"
                    });   
                } 
            }

            SetSlot1_SingleLeg_Detail(Arr);
            SetSlot1_Detail_Loading(false);
            /
        }
        else if (Address_Exist === false) {
            SetMID_Color('white');
            SetMID_Title("");
            
            SetID1_Color('white');
            SetID1_Title('');
            
            SetID2_Color('white');
            SetID2_Title('');
            
            SetSlotLoading("");
            
            SetSlot1_SingleLeg_Detail([]);
            SetSlot1_Detail_Found(false);
            SetSlot1_Detail_Loading(false);
        }

    }
    catch(error) {
        console.log(error);
        SetSlotLoading("");
        SetSlot1_Detail_Found(false);
        SetSlot1_Detail_Loading(false);
    }
}
*/

